import * as React from 'react'
import * as Sentry from '@sentry/browser'
import styles from './styles/class-editor.module.scss'
import { Typography, Tag, Tooltip, Popconfirm, notification, Dropdown, Modal } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import AddIcon from '@material-design-icons/svg/filled/add.svg'
import LayersClear from '@material-design-icons/svg/filled/layers_clear.svg'
import CheckCircle from '@material-design-icons/svg/filled/check_circle.svg'
import { E2E_REQUIRED_CLASSES, getColor, TOOLTIP_MOUSE_ENTER_DELAY } from '@app/constants'
import { notifyProjectClassCreationError, notifyProjectClassDeletionError, notifyProjectClassEditionError } from '@app/helpers/project-notification-error'
import { LoadingIcon } from '@components/loading-icon'
import { EnterOutlined } from '@ant-design/icons'
import MoreIcon from '@material-design-icons/svg/filled/more_vert.svg'
import AnnotationShowIcon from '@material-design-icons/svg/filled/gesture.svg'
import AnnotationHideIcon from '@components/icons/gesture_off.svg'
import PredictionShowIcon from '@material-design-icons/svg/filled/tonality.svg'
import PredictionHideIcon from '@material-design-icons/svg/filled/hide_source.svg'

const { Title, Text } = Typography
export interface AnnotationClass {
  colorIndex: number
  name: string
  visible: boolean
  predictionVisible: boolean
}

interface ContextualMenuProps {
  name: string
  canDelete: boolean
  canEdit: boolean
  trigger?: Array<('contextMenu' | 'click' | 'hover')>
  onDeleteClass: () => Promise<void>
  onEditClassName: () => void
  onClearAnnotations: () => Promise<void>
  children: React.ReactElement
}

interface MenuInfoOnClick {
  domEvent: React.MouseEvent | React.KeyboardEvent
}

export const ClassDetailContextualMenu: React.FC<ContextualMenuProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { name, canDelete, canEdit, trigger = ['contextMenu'], onDeleteClass, onClearAnnotations, onEditClassName } = props
  const intl = useIntl()
  const [menuClassDetailModal, menuClassDetailModalContextHolder] = Modal.useModal()

  const items = [
    {
      key: 'EDITOR_CLASS_EDIT_NAME',
      disabled: !canEdit,
      danger: false,
      label: <FormattedMessage id={'editor.classes.item.edit.name'} defaultMessage={'Edit class name'} />,
      onClick: onEditClassName,
    },
    {
      key: 'EDITOR_CLASS_CLEAR_ANNOTATIONS',
      disabled: !canEdit,
      danger: false,
      label: <FormattedMessage id={'editor.classes.item.items.clear.annotations'} defaultMessage={'Clear annotations'} />,
      onClick: (event: MenuInfoOnClick) => {
        void menuClassDetailModal.confirm({
          title: intl.formatMessage(
            { id: 'editor.classes.item.clear.annotations.confirm.title', defaultMessage: 'Clear "{className}" annotations on all images?' },
            { className: name },
          ),
          onOk: onClearAnnotations,
          okText: intl.formatMessage({ id: 'editor.classes.item.clear.annotations.confirm.content', defaultMessage: 'Yes, I am sure' }),
          centered: true,
          transitionName: '',
          style: {
            left: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageX - 200),
            top: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageY - 150),
            position: 'absolute',
          },
        })
      },
    },
    {
      key: 'EDITOR_CLASS_DELETE',
      disabled: !canDelete,
      danger: true,
      label: <FormattedMessage id={'editor.classes.item.delete'} defaultMessage={'Delete class'} />,
      onClick: (event: MenuInfoOnClick) => {
        void menuClassDetailModal.confirm({
          title: intl.formatMessage(
            { id: 'editor.classes.item.delete.confirm.title', defaultMessage: 'Delete "{className}" class?' },
            { className: name },
          ),
          onOk: onDeleteClass,
          okText: intl.formatMessage({ id: 'editor.classes.item.delete.confirm.content', defaultMessage: 'Yes, I am sure' }),
          centered: true,
          transitionName: '',
          style: {
            left: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageX - 200),
            top: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageY - 150),
            position: 'absolute',
          },
        })
      },
    },
  ]

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={trigger}>
        {props.children}
      </Dropdown>
      {menuClassDetailModalContextHolder}
    </>
  )
}

interface ClassDetailProps {
  selectedClassColorIndex: number
  classIterate: AnnotationClass
  canDelete?: boolean
  canEdit: boolean
  onSetBrushColor: () => void
  onChangeName: (value: string) => Promise<void>
  onToggleClassVisibility: () => void
  globalAnnotationVisibility: boolean
  onTogglePredictionClassVisibility: () => void
  globalPredictionVisibility: boolean
  onClearAnnotations: () => Promise<void>
  onDeleteClass: () => Promise<void>
  startEditing: () => void
  stopEditing: () => void
}

const ClassDetail: React.FC<ClassDetailProps> = ({ selectedClassColorIndex, classIterate, canDelete = true, onSetBrushColor, onChangeName, onToggleClassVisibility, onTogglePredictionClassVisibility, onClearAnnotations, canEdit, onDeleteClass, startEditing, stopEditing, globalAnnotationVisibility, globalPredictionVisibility }) => {
  const [isEditingState, setIsEditingState] = React.useState(false)
  const [isWaitingEdit, setIsWaitingEdit] = React.useState(false)
  const [isWaitingDelete, setIsWaitingDelete] = React.useState(false)
  const intl = useIntl()

  const [errorNotificationApi, errorNotificationContextHolder] = notification.useNotification()

  const onEditClassName = React.useCallback(
    () => {
      if (canEdit) {
        startEditing()
        setIsEditingState(true)
      }
    },
    [canEdit, startEditing],
  )

  const _onDeleteClass = async (): Promise<void> => {
    setIsWaitingDelete(true)
    onDeleteClass().catch((error) => {
      notifyProjectClassDeletionError(intl, errorNotificationApi)
      Sentry.captureException(error)
      setIsWaitingDelete(false)
    })
  }

  const onOpenContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const contextMenuEvent = new MouseEvent('contextmenu', {
      bubbles: true,
      cancelable: true,
      clientX: event.nativeEvent.clientX,
      clientY: event.nativeEvent.clientY,
    })
    event.nativeEvent.target?.dispatchEvent(contextMenuEvent)
  }

  return <ClassDetailContextualMenu
    canDelete={canDelete}
    canEdit={canEdit}
    name={classIterate.name}
    onDeleteClass={_onDeleteClass}
    onEditClassName={onEditClassName}
    onClearAnnotations={onClearAnnotations}
    trigger={isEditingState ? [] : ['contextMenu']}
  >
    <div
      className={clsx(styles.classDetail, { [styles.selected]: selectedClassColorIndex === classIterate.colorIndex, [styles.isEditing]: isEditingState, [styles.isDeleting]: isWaitingDelete })}
      onClick={() => {
        onSetBrushColor()
      }}
      onDoubleClick={onEditClassName}
    >
      {errorNotificationContextHolder}
      <div className={styles.classDetailTag}>
        <Tag
          icon={selectedClassColorIndex === classIterate.colorIndex && <CheckCircle className={styles.checkIcon} />}
          color={getColor(classIterate.colorIndex)}
          className={clsx(E2E_REQUIRED_CLASSES.CLASS_DETAIL_TAG, styles.classDetailTag, styles.classDetailColor, { [styles.selected]: selectedClassColorIndex === classIterate.colorIndex })}
        />
      </div>
      <div className={styles.classDetailName}>
        <Text
          className={clsx(styles.classDetailNameTextEdition, { [styles.waitingForEdit]: isWaitingEdit })}
          editable={(isEditingState)
            ? {
                autoSize: { maxRows: 1 },
                maxLength: 32,
                onChange: (value: string) => {
                  const notificationKey = 'ClassName' + value
                  const element = document.activeElement as HTMLElement
                  element.blur()
                  setIsWaitingEdit(true)
                  onChangeName(value).then(() => {
                    stopEditing()
                    setIsEditingState(false)
                    setIsWaitingEdit(false)
                  }).catch((error) => {
                    notifyProjectClassEditionError(intl, errorNotificationApi, notificationKey)
                    Sentry.captureException(error)
                    setIsWaitingEdit(false)
                    element.focus()
                  },
                  )
                },
                editing: isEditingState,
                onStart: onEditClassName,
                onCancel: () => {
                  stopEditing()
                  setIsEditingState(false)
                },
                enterIcon: isWaitingEdit ? <LoadingIcon isLoading={true} /> : <EnterOutlined />,
              }
            : false}
          ellipsis
          title={classIterate.name}
        >
          {classIterate.name}
        </Text>
      </div>
      <div className={styles.classDetailActions}>
        <Tooltip
          placement="topRight"
          title={
            <FormattedMessage id="class-editor.class.tooltip.more-options" defaultMessage="More options" />
          }
          mouseLeaveDelay={0}
          mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
        >
          <div className={clsx(styles.classDetailAction, { [styles.buttonHoverClassName]: !isWaitingDelete })} onClick={onOpenContextMenu}>
            <MoreIcon />
          </div>
        </Tooltip>
        <Tooltip
          placement="topRight"
          title={
            classIterate.visible && globalAnnotationVisibility
              ? <FormattedMessage id="class-editor.class.tooltip.hide-class" defaultMessage="Hide the annotation for this class" />
              : <FormattedMessage id="class-editor.class.tooltip.show-class" defaultMessage="Show the annotation for this class" />
          }
          mouseLeaveDelay={0}
          mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
        >
          <button
            className={styles.classDetailAction}
            onClick={(e) => {
              // Remove focus on the button
              e.currentTarget.blur()
              e.stopPropagation()
              onToggleClassVisibility()
            }}>
            {classIterate.visible && globalAnnotationVisibility ? <AnnotationShowIcon /> : <AnnotationHideIcon />}
          </button>
        </Tooltip>
        <Tooltip
          placement="topRight"
          title={
            classIterate.predictionVisible && globalPredictionVisibility
              ? <FormattedMessage id="class-editor.class.tooltip.hide-prediction-class" defaultMessage="Hide the prediction mask of this class on the image" />
              : <FormattedMessage id="class-editor.class.tooltip.show-prediction-class" defaultMessage="Show the prediction mask of this class on the image" />
          }
          mouseLeaveDelay={0}
          mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
        >
          <button
            className={styles.classDetailAction}
            onClick={(e) => {
              // Remove focus on the button
              e.currentTarget.blur()
              e.stopPropagation()
              onTogglePredictionClassVisibility()
            }}>
            {classIterate.predictionVisible && globalPredictionVisibility ? <PredictionShowIcon /> : <PredictionHideIcon />}
          </button>
        </Tooltip>
      </div>
    </div>
  </ClassDetailContextualMenu>
}

interface ClassEditorProps {
  classes: AnnotationClass[]
  onChangeClassName: (colorIndex: number, name: string) => Promise<void>
  onTogglePredictionClassVisibility: (colorIndex: number) => void
  onToggleAnnotationClassVisibility: (colorIndex: number) => void
  globalAnnotationVisibility: boolean
  onToggleGlobalAnnotationVisibility: () => void
  globalPredictionVisibility: boolean
  onToggleGlobalPredictionVisibility: () => void
  onSetBrushColorIndex: (colorIndex: number) => void
  selectedClassColorIndex: number
  onAddClass: () => Promise<void>
  onDeleteClass: (colorIndex: number) => Promise<void>
  onClearAnnotations: (colorIndex: number) => Promise<void>
  onClearAllAnnotations: () => Promise<void>
  canAddMoreClass: boolean
}
export const ClassEditor: React.FC<ClassEditorProps> = (props) => {
  const { classes, globalAnnotationVisibility, onChangeClassName, onToggleAnnotationClassVisibility, onTogglePredictionClassVisibility, onToggleGlobalAnnotationVisibility, globalPredictionVisibility, onToggleGlobalPredictionVisibility, onSetBrushColorIndex, selectedClassColorIndex, onAddClass, onDeleteClass, canAddMoreClass, onClearAnnotations, onClearAllAnnotations } = props
  const [canShowTooltip, setCanShowTooltip] = React.useState<false | undefined>(undefined)
  const [isWaitingAdd, setIsWaitingAdd] = React.useState(false)
  const [isChildEditing, setIsChildEditing] = React.useState(false)
  const intl = useIntl()

  const setCanShowTooltipWithDelay: VoidFunction = () => {
    // use setTimeout to give a delay to let tooltip go away
    setTimeout(() => {
      setCanShowTooltip(undefined)
    }, 600)
  }

  const onStartEditing = (): void => {
    setIsChildEditing(true)
  }
  const onEndEditing = (): void => {
    setIsChildEditing(false)
  }

  const [errorNotificationApi, errorNotificationContextHolder] = notification.useNotification()

  return (
    <div className={styles.classEditor} id='class-editor'>
      <div className={styles.headerContainer}>
        <Title level={5} className={styles.title}>
          <FormattedMessage id="class-editor.title" defaultMessage={'Classes'} />
        </Title>
        <div className={styles.actions}>
          <Tooltip
            placement="topRight"
            title={
              <FormattedMessage id="class-editor.tooltip.add-class" defaultMessage="Add annotation class" />
            }
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}>
            {errorNotificationContextHolder}
            <button
              className={clsx(styles.action, E2E_REQUIRED_CLASSES.ADD_CLASS_BUTTON)}
              onClick={(e) => {
                // Remove focus on the button
                e.currentTarget.blur()
                setIsWaitingAdd(true)
                onAddClass().then(() => {
                  setIsWaitingAdd(false)
                }).catch((error) => {
                  notifyProjectClassCreationError(intl, errorNotificationApi)
                  Sentry.captureException(error)
                  setIsWaitingAdd(false)
                })
              }}
              disabled={!canAddMoreClass || isWaitingAdd}
            >
              {isWaitingAdd ? <LoadingIcon isLoading={true} /> : <AddIcon />}
            </button>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title={
              <FormattedMessage id="class-editor.tooltip.clear-all-annotations" defaultMessage="Clear all annotations" />
            }
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
            open={canShowTooltip}
          >
            <Popconfirm
              onConfirm={async () => {
                await onClearAllAnnotations()
                setCanShowTooltipWithDelay()
              }}
              onCancel={setCanShowTooltipWithDelay}
              title={
                <FormattedMessage
                  id='class-editor.tooltip.clear-all-annotations.modal.confirm.content'
                  defaultMessage='Clear all annotations on all images?'
                />
              }
              okText={
                <FormattedMessage
                  id='class-editor.tooltip.clear-all-annotations.modal.confirm.ok.text'
                  defaultMessage='Yes, I am sure'
                />
              }
            >
              <button
                className={styles.action}
                onClick={(e) => {
                  // Remove focus on the button
                  e.currentTarget.blur()
                  setCanShowTooltip(false)
                }}
              >
                <LayersClear />
              </button>
            </Popconfirm>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title={
              globalAnnotationVisibility
                ? <FormattedMessage id="class-editor.tooltip.hide-annotations" defaultMessage="Hide all annotations in the viewer" />
                : <FormattedMessage id="class-editor.tooltip.show-annotations" defaultMessage="Show all annotations in the viewer" />
            }
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
          >
            <button
              className={styles.action}
              onClick={(e) => {
                // Remove focus on the button
                e.currentTarget.blur()
                onToggleGlobalAnnotationVisibility()
              }}
            >
              {globalAnnotationVisibility ? <AnnotationShowIcon /> : <AnnotationHideIcon />}
            </button>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title={
              globalPredictionVisibility
                ? <FormattedMessage id="class-editor.tooltip.hide-predictions" defaultMessage="Hide all predictions in the viewer" />
                : <FormattedMessage id="class-editor.tooltip.show-predictions" defaultMessage="Show all predictions in the viewer" />
            }
            mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
          >
            <button
              className={styles.action}
              onClick={(e) => {
                // Remove focus on the button
                e.currentTarget.blur()
                onToggleGlobalPredictionVisibility()
              }}
            >
              {globalPredictionVisibility ? <PredictionShowIcon /> : <PredictionHideIcon />}
            </button>
          </Tooltip>
        </div>
      </div>
      <div className={clsx(E2E_REQUIRED_CLASSES.CLASS_DETAILS, styles.content)}>
        {
          classes.map((classIterate) => {
            const onChangeName = async (name: string): Promise<void> => {
              await onChangeClassName(classIterate.colorIndex, name)
            }
            const _onSetBrushColor = (): void => {
              onSetBrushColorIndex(classIterate.colorIndex)
            }
            const _onDeleteClass = async (): Promise<void> => { await onDeleteClass(classIterate.colorIndex) }
            const _onToggleClassVisibility = (): void => { onToggleAnnotationClassVisibility(classIterate.colorIndex) }
            const _onTogglePredictionClassVisibility = (): void => { onTogglePredictionClassVisibility(classIterate.colorIndex) }
            const _onClearAnnotations = async (): Promise<void> => { await onClearAnnotations(classIterate.colorIndex) }

            return <ClassDetail
              key={`${classIterate.colorIndex}_${classIterate.colorIndex}`}
              classIterate={classIterate}
              selectedClassColorIndex={selectedClassColorIndex}
              canDelete={classes.length > 1}
              onChangeName={onChangeName}
              onDeleteClass={_onDeleteClass}
              onSetBrushColor={_onSetBrushColor}
              onToggleClassVisibility={_onToggleClassVisibility}
              globalAnnotationVisibility={globalAnnotationVisibility}
              onTogglePredictionClassVisibility={_onTogglePredictionClassVisibility}
              globalPredictionVisibility={globalPredictionVisibility}
              onClearAnnotations={_onClearAnnotations}
              startEditing={onStartEditing}
              stopEditing={onEndEditing}
              canEdit={!isChildEditing}
            />
          })
        }
      </div>
    </div>
  )
}
