import type * as React from 'react'
import { Button, Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/training-buttons.module.scss'
import ModelTraining from '@material-design-icons/svg/filled/model_training.svg'
import clsx from 'clsx'
import { LoadingOutlined } from '@ant-design/icons'
import { JumpingDots } from '@components/common/jumping-dots'
import { E2E_REQUIRED_CLASSES } from '@app/constants'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'

interface ButtonWrapper {
  children?: React.ReactNode
}

interface TrainButtonProps {
  onClick: () => void
  isDisabled: boolean
  isLoading: boolean
  isTraining: boolean
  isDash: boolean
  shouldBeTrain: boolean
  isTrainAvailablePerLicence: boolean
  children?: React.ReactNode
}
export const TrainButton: React.FC<TrainButtonProps> = (props) => {
  const { onClick, isDisabled, isLoading, isTraining, isDash, shouldBeTrain, isTrainAvailablePerLicence } = props
  const trainButtonIsDisabled = isDisabled || isTraining || !shouldBeTrain || !isTrainAvailablePerLicence

  const ButtonWrapper: React.FC<ButtonWrapper> = ({ children }) => {
    const getTooltipTitle = (props: TrainButtonProps): React.ReactNode => {
      const { isDisabled, isTraining, isTrainAvailablePerLicence, shouldBeTrain } = props
      if (isDisabled && !isTraining) {
        return <FormattedMessage id="editor.algorithm.button.train.tooltip.disable" defaultMessage={'You need at least 2 annotations of different class to start training.'} />
      } else if (!isTrainAvailablePerLicence) {
        return <RequestLicenceFeature featureText={'Quick Clemexnet'} licenceFeatureRequest={'algo_quick_clemexnet_v1'} className={styles.requestLicenceFeature} />
      } else if (!shouldBeTrain) {
        return <FormattedMessage id="editor.algorithm.button.train.tooltip.isProjectTrainingSnapshotPristine" defaultMessage={"You're all set! The system is already trained with your latest annotations. To train again, add or remove annotation and click on the Train button."} />
      }
      return null
    }

    const tooltipTitle = getTooltipTitle(props)
    return (tooltipTitle != null) ? <Tooltip placement="topLeft" title={tooltipTitle}>{children}</Tooltip> : <>{children}</>
  }

  return <ButtonWrapper >
    <Button
      type={isDash ? 'dashed' : 'primary'}
      block
      size={'large'}
      onClick={onClick}
      disabled={trainButtonIsDisabled}
      className={clsx(styles.button, E2E_REQUIRED_CLASSES.TRAIN_BUTTON, { [E2E_REQUIRED_CLASSES.TRAIN_BUTTON_DISABLED]: trainButtonIsDisabled })}
    >
      <div className={clsx(styles.buttonContent, { [styles.disabled]: trainButtonIsDisabled, [styles.dashed]: isDash })}>
        <div className={styles.buttonIcon}>
          {
            isLoading
              ? <LoadingOutlined />
              : <ModelTraining />
          }
        </div>
        <div className={clsx(styles.buttonText, { [E2E_REQUIRED_CLASSES.IS_TRAINING]: isTraining })}>
          {!isTraining && <FormattedMessage id="editor.algorithm.button.train" defaultMessage={'Train'} />}
          {isTraining && <>
            <FormattedMessage id="editor.algorithm.button.training" defaultMessage={'Training'} />
            &nbsp;
            <JumpingDots />
          </>}
        </div>
      </div>
    </Button>
  </ButtonWrapper>
}
