export class Queue<D> {
  private _tasksDataLifo: D[]
  private readonly _consumer: (data: D) => Promise<void>

  private _isRunning: boolean

  constructor (
    consumer: (data: D) => Promise<void>,
  ) {
    this._tasksDataLifo = []
    this._isRunning = false
    this._consumer = consumer
  }

  private _isEmpty (): boolean {
    return this._tasksDataLifo.length === 0
  }

  private async _execute (): Promise<void> {
    while (this._isRunning && !this._isEmpty()) {
      const taskData = this._tasksDataLifo.pop()
      if (taskData !== undefined) {
        await this._consumer(taskData)
      }
    }
    this._isRunning = false
  }

  public pause (): void {
    this._isRunning = false
  }

  public resume (): void {
    if (!this._isRunning) {
      this._isRunning = true
      void this._execute() // not awaited to run it in background
    }
  }

  public addTask (task: D, { startQueue, executeNext }: { startQueue?: boolean, executeNext?: boolean } = {}): void {
    if (executeNext === true) {
      this._tasksDataLifo.push(task)
    } else {
      this._tasksDataLifo.unshift(task)
    }
    if (startQueue === true) {
      this.resume()
    }
  }

  public flush (): void {
    this._tasksDataLifo = []
  }

  public getTasks (): readonly D[] {
    return this._tasksDataLifo
  }
}
