import * as React from 'react'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-tools.module.scss'
import { IconMenu } from '@components/common/icon-menu'
import { EVENTS_ID, Tool, ctrlModifier } from '@app/constants'
import { useStageZoom } from '@app/hooks/stage'
import { useSelectedImage } from '@app/api/hooks'
import { ProjectDatasetContext } from '@app/api/openapi'
import { faEraser as EraseIcon } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconName } from '@fortawesome/fontawesome-svg-core'
import DrawIcon from '@material-design-icons/svg/filled/brush.svg'
import PanIcon from '@material-design-icons/svg/filled/pan_tool.svg'
import RedoIcon from '@material-design-icons/svg/filled/redo.svg'
import UndoIcon from '@material-design-icons/svg/filled/undo.svg'
import ZoomInIcon from '@material-design-icons/svg/filled/zoom_in.svg'
import ZoomOutIcon from '@material-design-icons/svg/filled/zoom_out.svg'
import ZoomResetIcon from '@material-design-icons/svg/filled/crop_free.svg'
import { useEditorStore } from '@app/stores/editor'

const { Title } = Typography

interface EditorToolProps {
  projectId: string
  context: ProjectDatasetContext
}

export const EditorTools: React.FC<EditorToolProps> = ({ projectId, context }) => {
  const stageZoom = useStageZoom()
  const { data: selectedImage } = useSelectedImage(projectId)
  const selectedTool = useEditorStore((store) => store.selectedTool)
  const disabledTools = useEditorStore((store) => store.disabledTools)

  const controlZoomCanvasToolItems = React.useMemo(() => {
    const zoomIcons = [
      {
        icon: <ZoomInIcon />,
        selected: false,
        disabled: !(selectedImage !== undefined && stageZoom.canZoomIn()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_IN)),
        helpTitle: <FormattedMessage id="tools.view.ZoomIn" defaultMessage='Zoom in ({ctrlModifier}+=)' values={{ ctrlModifier }} />,
      },
      {
        icon: <ZoomResetIcon />,
        selected: false,
        disabled: !(stageZoom.canZoomReset()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_TO_FIT)),
        helpTitle: <FormattedMessage id="tools.view.zoomReset" defaultMessage='Zoom reset ({ctrlModifier}+0)' values={{ ctrlModifier }} />,
      },
      {
        icon: <ZoomOutIcon />,
        selected: false,
        disabled: !(selectedImage !== undefined && stageZoom.canZoomOut()),
        action: () => window.dispatchEvent(new CustomEvent(EVENTS_ID.ZOOM_OUT)),
        helpTitle: <FormattedMessage id="tools.view.zoomOut" defaultMessage='Zoom out ({ctrlModifier}+-)' values={{ ctrlModifier }} />,
      },
    ]

    return zoomIcons
  }, [selectedImage, stageZoom])

  const controlPanCanvasToolItems = React.useMemo(() => {
    const panTool = [{
      icon: <PanIcon />,
      selected: selectedTool === Tool.PAN,
      disabled: disabledTools.includes(Tool.PAN),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_PAN_TOOL)) },
      helpTitle: <FormattedMessage id="tools.view.pan" defaultMessage={'Pan (Hold space and drag) or (P)'} />,
    }]

    return panTool
  }, [selectedTool, disabledTools])

  const annotationsToolItems = React.useMemo(() => {
    const annotationOnlyTools = [{
      icon: <DrawIcon />,
      selected: selectedTool === Tool.BRUSH,
      disabled: disabledTools.includes(Tool.BRUSH),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_BRUSH_TOOL)) },
      helpTitle: <FormattedMessage id="tools.annotations.brush" defaultMessage={'Brush (B)'} />,
    },
    {
      icon: <FontAwesomeIcon icon={EraseIcon as unknown as IconName} size="lg" />,
      selected: selectedTool === Tool.ERASER,
      disabled: disabledTools.includes(Tool.ERASER),
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_ERASER_TOOL)) },
      helpTitle: <FormattedMessage id="tools.annotations.eraser" defaultMessage={'Eraser (E)'} />,
    }]

    return annotationOnlyTools
  }, [selectedTool, disabledTools])

  const undoRedoToolItems = React.useMemo(() => {
    const undoRedoIcons = [{
      icon: <UndoIcon />,
      selected: false,
      disabled: false,
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UNDO)) },
      helpTitle: <FormattedMessage id="tools.annotations.undo" defaultMessage='Undo ({ctrlModifier}+Z)' values={{ ctrlModifier }} />,
    },
    {
      icon: <RedoIcon />,
      selected: false,
      disabled: false,
      action: () => { window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_REDO)) },
      helpTitle: <FormattedMessage id="tools.annotations.redo" defaultMessage='Redo ({ctrlModifier}+Shift+Z)' values={{ ctrlModifier }} />,
    }]

    return undoRedoIcons
  }, [])

  return <div id='tools-editor' className={styles.editorTools}>
    <Title level={5} className={styles.titleSection}>
      { context === ProjectDatasetContext.Training &&
        <div>
          <FormattedMessage id="tools.annotation.title" defaultMessage={'Training Annotation'} />
        </div>
      }
      <div>
        <FormattedMessage id="tools.annotation.control.title" defaultMessage={'Control'} />
      </div>
    </Title>
    <div className={styles.container}>
      { context === ProjectDatasetContext.Training &&
        <div className={styles.groupIcons}>
          <IconMenu
            items={annotationsToolItems}
          />
          <IconMenu
            items={undoRedoToolItems}
            vertical={false}
          />
        </div>
      }
      <div className={styles.groupIcons}>
        <IconMenu
          items={controlZoomCanvasToolItems}
        />
        <IconMenu
          items={controlPanCanvasToolItems}
        />
      </div>
    </div>
  </div >
}

export default EditorTools
