import type * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown, Modal } from 'antd'
import { EVENTS_ID } from '@app/constants'

interface ContextualMenuProps {
  trigger?: Array<('contextMenu' | 'click' | 'hover')>
  menuItems: {
    [EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION]?: { imageId: string }
    [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING]?: { imageId: string }
    [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE]?: { imageId: string, imageSlug: string }
    [EVENTS_ID.PROJECT_DELETE_IMAGE]?: { imageId: string }
    [EVENTS_ID.DUPLICATE_PROJECT]?: { projectId: string }
    [EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE]?: { projectId: string }
  }
}

type AvailableContextMenuEvent = keyof ContextualMenuProps['menuItems']

const dangerKeys = { [EVENTS_ID.PROJECT_DELETE_IMAGE]: true }

const EVENTS_CONTEXT_MENU = {
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION]: <FormattedMessage id={'project.menu.item.image.send.validation'} defaultMessage={'Send to validation'}/>,
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING]: <FormattedMessage id={'project.menu.item.image.send.training'} defaultMessage={'Send to training'}/>,
  [EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE]: <FormattedMessage id={'project.menu.item.image.send.training.navigate'} defaultMessage={'Send to training and go to annotation editor'}/>,
  [EVENTS_ID.DUPLICATE_PROJECT]: <FormattedMessage id={'project.menu.item.image.duplicate'} defaultMessage={'Duplicate project'}/>,
  [EVENTS_ID.DUPLICATE_PROJECT_AND_NAVIGATE]: <FormattedMessage id={'project.menu.item.image.duplicateAndNavigate'} defaultMessage={'Duplicate project and open it'}/>,
  [EVENTS_ID.PROJECT_DELETE_IMAGE]: <FormattedMessage id={'project.menu.item.image.delete'} defaultMessage={'Delete image'}/>,
}

interface MenuInfoOnClick {
  domEvent: React.MouseEvent | React.KeyboardEvent
}

export const ContextualMenu: React.FC<ContextualMenuProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { menuItems, trigger = ['contextMenu'], ...rest } = props
  const intl = useIntl()
  const [contextMenuModal, contextMenuContextHolder] = Modal.useModal()

  return (
    <>
      <Dropdown
        menu={{
          items: (Object.keys(menuItems) as AvailableContextMenuEvent[]).map((eventId) => (
            {
              key: eventId,
              label: EVENTS_CONTEXT_MENU[eventId],
              danger: eventId in dangerKeys,
              onClick: (event: MenuInfoOnClick) => {
                const action = (): void => {
                  window.dispatchEvent(new CustomEvent(eventId, { detail: menuItems[eventId] }))
                }
                // We could generalize this, but for now it's only for the delete image
                if (eventId === EVENTS_ID.PROJECT_DELETE_IMAGE) {
                  void contextMenuModal.confirm({
                    title: intl.formatMessage({ id: 'project.menu.item.confirm.title', defaultMessage: 'Are you sure?' }),
                    content: intl.formatMessage({ id: 'project.menu.item.confirm.content', defaultMessage: 'This action is not reversible.' }),
                    onOk: action,
                    centered: true,
                    transitionName: '',
                    style: {
                      left: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageX - 200),
                      top: Math.max(10, (event as unknown as { domEvent: React.MouseEvent }).domEvent.pageY - 150),
                      position: 'absolute',
                    },
                  })
                } else {
                  action()
                }
              },
            })),
        }}
        trigger={trigger}>
        <div {...rest}>
          {props.children}
        </div>
      </Dropdown>
      {contextMenuContextHolder}
    </>
  )
}
