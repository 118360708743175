/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PredictionMask
 */
export interface PredictionMask {
    /**
     * 
     * @type {string}
     * @memberof PredictionMask
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof PredictionMask
     */
    imageId: string;
    /**
     * 
     * @type {string}
     * @memberof PredictionMask
     */
    projectTrainingSnapshotId: string;
}

/**
 * Check if a given object implements the PredictionMask interface.
 */
export function instanceOfPredictionMask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "projectTrainingSnapshotId" in value;

    return isInstance;
}

export function PredictionMaskFromJSON(json: any): PredictionMask {
    return PredictionMaskFromJSONTyped(json, false);
}

export function PredictionMaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionMask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'imageId': json['image_id'],
        'projectTrainingSnapshotId': json['project_training_snapshot_id'],
    };
}

export function PredictionMaskToJSON(value?: PredictionMask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'image_id': value.imageId,
        'project_training_snapshot_id': value.projectTrainingSnapshotId,
    };
}

