import { Typography, Flex, Button, Slider, Switch, Alert, Select } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/algorithm-settings.module.scss'
import { useAlgorithmId, useDeepLearningProjectTrainingParameters, useRFAlgorithmProjectTrainingParameters, useProjectTrainingState } from '@app/api/hooks'
import clsx from 'clsx'
import { AlgorithmId } from '@app/api/openapi'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'

const { Title, Text, Paragraph } = Typography

interface AlgorithmSectionProps {
  projectId: string
  algorithmId: AlgorithmId | undefined
  enabledLocalUser?: boolean
  algoQuickClemexnetV1Enabled?: boolean
}
export const AlgorithmSection: React.FC<AlgorithmSectionProps> = ({ projectId, algorithmId, enabledLocalUser = false, algoQuickClemexnetV1Enabled = false }) => {
  return <>
    {enabledLocalUser && <AlgorithmChoiceSetting projectId={projectId} />}
    {algorithmId !== AlgorithmId.QuickClemexnet
      ? <RfAlgorithmSettings projectId={projectId} />
      // TODO: Remove the condition with the feature flag when the Algorithms selection will be available for all users with Studio Desktop
      : enabledLocalUser ? <DeepLearningSettings projectId={projectId} available={algoQuickClemexnetV1Enabled} /> : <RfAlgorithmSettings projectId={projectId} />}
  </>
}

interface AlgorithmChoiceSettingProps {
  projectId: string
}
enum AlgorithmOptions {
  RANDOM_FOREST,
  QUICK_CLEMEXNET,
}
export const AlgorithmChoiceSetting: React.FC<AlgorithmChoiceSettingProps> = ({ projectId }) => {
  const { data: algorithmId, mutate: mutateAlgorithmId } = useAlgorithmId(projectId)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const algoValue = algorithmId !== undefined && algorithmId === AlgorithmId.QuickClemexnet ? AlgorithmOptions.QUICK_CLEMEXNET : AlgorithmOptions.RANDOM_FOREST
  return <Flex vertical flex={1} className={styles.root}>
    <Flex vertical flex={1}>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id='training-options.models.label' defaultMessage='Models'/>
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center'>
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.section.algorithm.choice" defaultMessage={'Model Id'} />
          </Title>
          <Select
            options={[
              { value: AlgorithmOptions.RANDOM_FOREST, label: <FormattedMessage id='algorithm-id.random-forest.label' defaultMessage='RANDOM_FOREST'/> },
              { value: AlgorithmOptions.QUICK_CLEMEXNET, label: <FormattedMessage id='algorithm-id.quick-clemexnet.label' defaultMessage='QUICK_CLEMEXNET' /> },
            ]}
            onChange={(value: number) => {
              const newAlgorithmId = value === AlgorithmOptions.RANDOM_FOREST ? AlgorithmId.RfAlgorithmSklearn : AlgorithmId.QuickClemexnet
              void mutateAlgorithmId(newAlgorithmId)
              void mutateProjectTrainingState()
            }}
            defaultValue={algoValue}
          />
        </Flex>
      </Flex>
    </Flex>
  </Flex>
}
interface AlgorithmSettingsProps {
  projectId: string
  available?: boolean
}
export const DeepLearningSettings: React.FC<AlgorithmSettingsProps> = ({ projectId, available = false }) => {
  const { data: projectSettingsDeepLearningTrainingParameters, isLoading, mutateTrainingParameters, reset } = useDeepLearningProjectTrainingParameters(projectId)

  const onChangeEpochs = (epochs: number): void => {
    if (projectSettingsDeepLearningTrainingParameters === undefined) {
      return
    }
    void mutateTrainingParameters({
      ...projectSettingsDeepLearningTrainingParameters,
      epochs,
    })
  }
  if (isLoading || projectSettingsDeepLearningTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }
  const epochs: number = projectSettingsDeepLearningTrainingParameters.epochs ?? 10

  return <Flex vertical flex={1} className={styles.root}>
    <Flex vertical flex={1}>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      </Title>
      {(!available) && <Flex justify='flex-end' align='center'>
        <Alert
          message={<Text>
            <Paragraph>
              <FormattedMessage id="project-settings.modal.deep-learning.training-parameters.licence-restriction.warning.model" defaultMessage='This model use deep learning technology.' />
            </Paragraph>
            <RequestLicenceFeature featureText='Quick Clemexnet' licenceFeatureRequest={UserAssistanceLicenceFeature.AlgoQuickClemexnetV1}/>
          </Text>}
          type="warning"
        />
      </Flex>
      }
      <Flex vertical gap={16} flex={1} className={clsx(styles.settingsSectionContent, { [styles.disabled]: !available })}>
        <Flex justify='space-between' align='center' >
          <Flex gap={16} vertical className={styles.sigmaSliderContainer}>
            <Title level={5} className={styles.settingsItemTitle}>
              <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.epochs" defaultMessage={'Epochs'} />
            </Title>
            <Slider
              tooltip={{ open: false }}
              min={5}
              max={50}
              step={5}
              marks={{
                [epochs]: epochs,
              }}
              value={epochs}
              onChange={onChangeEpochs}
            />
          </Flex>
        </Flex>
        <Flex justify='flex-end' align='center' className={styles.actionsContainer}>
          <Button type='primary' onClick={reset}>
            <FormattedMessage id="project-settings.modal.section.deep-learning.training-parameters.reset" defaultMessage={'Reset to default training options'} />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
}
export const RfAlgorithmSettings: React.FC<AlgorithmSettingsProps> = ({ projectId }) => {
  const { data: projectSettingsRfAlgorithmTrainingParameters, isLoading, mutateTrainingParameters, reset } = useRFAlgorithmProjectTrainingParameters(projectId)
  const onChangeSigma = (sigma: number[]): void => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    void mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      sigmaMin: sigma[0],
      sigmaMax: sigma[1],
    })
  }
  const onToggleIntensity = (): void => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    void mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      intensity: !projectSettingsRfAlgorithmTrainingParameters.intensity,
    })
  }
  const onToggleTexture = (): void => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    void mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      texture: !projectSettingsRfAlgorithmTrainingParameters.texture,
    })
  }
  const onToggleEdges = (): void => {
    if (projectSettingsRfAlgorithmTrainingParameters === undefined) {
      return
    }
    void mutateTrainingParameters({
      ...projectSettingsRfAlgorithmTrainingParameters,
      edges: !projectSettingsRfAlgorithmTrainingParameters.edges,
    })
  }

  if (isLoading || projectSettingsRfAlgorithmTrainingParameters === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.algorithm.loading" defaultMessage={'Loading...'} />
  }

  const { sigmaMin, sigmaMax, edges, intensity, texture } = projectSettingsRfAlgorithmTrainingParameters
  const sigma = [sigmaMin, sigmaMax]
  const marks = { [sigma[0]]: sigma[0], [sigma[1]]: sigma[1] }
  const features = [intensity, edges, texture]
  const enabledFeatures = features.filter((feature) => feature)
  const onlyHasOneFeatureEnabled = enabledFeatures.length === 1

  const disabledIntensity = onlyHasOneFeatureEnabled && intensity
  const disabledEdges = onlyHasOneFeatureEnabled && edges
  const disabledTexture = onlyHasOneFeatureEnabled && texture

  return <Flex vertical flex={1} className={styles.root}>
    <Flex vertical flex={1}>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="project-settings.modal.section.algorithm.section.training-parameters" defaultMessage={'Training options'} />
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center'>
          <Flex gap={16} vertical className={styles.sigmaSliderContainer}>
            <Title level={5} className={styles.settingsItemTitle}>
              <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.sigma" defaultMessage={'Sigma'} />
            </Title>
            <Slider
              tooltip={{ open: false }}
              range
              min={0.1}
              max={20.0}
              step={0.1}
              marks={marks}
              value={sigma}
              onChange={onChangeSigma}
            />
          </Flex>
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.intensity" defaultMessage={'Intensity'} />
          </Title>
          <Switch
            className={clsx({ [styles.disabledSwitch]: disabledIntensity })}
            checked={intensity}
            onChange={() => {
              const element = document.activeElement as HTMLElement
              if (!disabledIntensity) {
                onToggleIntensity()
              }
              element.blur()
            }}
          />
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.edges" defaultMessage={'Edges'} />
          </Title>
          <Switch
            className={clsx({ [styles.disabledSwitch]: disabledEdges })}
            checked={edges}
            onChange={() => {
              const element = document.activeElement as HTMLElement
              if (!disabledEdges) {
                onToggleEdges()
              }
              element.blur()
            }}
          />
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.texture" defaultMessage={'Texture'} />
          </Title>
          <Switch
            className={clsx({ [styles.disabledSwitch]: disabledTexture })}
            checked={texture}
            onChange={() => {
              const element = document.activeElement as HTMLElement
              if (!disabledTexture) {
                onToggleTexture()
              }
              element.blur()
            }}
          />
        </Flex>
        <Flex justify='flex-end' align='center'>
          <Alert
            className={clsx({ [styles.showAlert]: onlyHasOneFeatureEnabled }, styles.alert)}
            message={<FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.switch.warning" defaultMessage="At least one option is required. You will not be able to deactivate the last one." />}
            type="warning"
          />
        </Flex>
        <Flex justify='flex-end' align='center' className={styles.actionsContainer}>
          <Button type='primary' onClick={reset}>
            <FormattedMessage id="project-settings.modal.section.rf-algorithm.training-parameters.reset" defaultMessage={'Reset to default training options'} />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
}
