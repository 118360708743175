/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PredictionMaskStatus is used to know the status of the prediction mask of an image.
 * @export
 */
export const PredictionMaskStatus = {
    Ready: 'READY',
    NotReady: 'NOT_READY'
} as const;
export type PredictionMaskStatus = typeof PredictionMaskStatus[keyof typeof PredictionMaskStatus];


export function PredictionMaskStatusFromJSON(json: any): PredictionMaskStatus {
    return PredictionMaskStatusFromJSONTyped(json, false);
}

export function PredictionMaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionMaskStatus {
    return json as PredictionMaskStatus;
}

export function PredictionMaskStatusToJSON(value?: PredictionMaskStatus | null): any {
    return value as any;
}

