import * as React from 'react'
import { notification, Modal, Typography, Space } from 'antd'
import * as RFAPI from '@api/api'
import { PredictionMaskStatus, ProjectDatasetContext, type ProjectImageResponse } from '@api/openapi'
import {
  LeftPanel,
  type LeftPanelImage,
} from '@components/left-panel'
import { EVENTS_ID } from '@app/constants'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import {
  useAnnotationClassesDistribution,
  useProject,
  useProjectActions,
  useProjectDatasetPredictionMasksStatus,
  useProjectImages,
  useProjectTrainingSnapshot,
  useProjectTrainingState,
  useRFAlgorithmProjectTrainingParameters,
  useDeepLearningProjectTrainingParameters,
} from '@app/api/hooks'
import { Project } from '@app/api/models'
import { UploadImagesModal } from '@components/modals/upload-images-modal'
import { usePredictionQueuing, useSelectedImageId } from '@app/hooks/editor'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import { useSearchParams } from 'react-router-dom'
import { usePredictionStore } from '@app/stores/prediction'

const { Paragraph } = Typography
interface EditorLeftPanelProps {
  projectSlug: string
  projectId: string
  context: ProjectDatasetContext
}

export const EditorLeftPanel: React.FC<EditorLeftPanelProps> = ({
  projectSlug,
  projectId,
  context,
}) => {
  const intl = useIntl()
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [openUploadModal, setOpenUploadModal] = React.useState(false)
  const { data: projectResponse, mutate: mutateProject } = useProject(projectSlug)
  const { mutate: mutateProjectTrainingState } = useProjectTrainingState(projectId)
  const { data: images, isLoading: isImagesLoading, isValidating: isImagesValidating, mutate: mutateImages } = useProjectImages(projectId, context)
  const [selectedImageId, setSelectedImageId] = useSelectedImageId()
  const { data: projectTrainingSnapshot, mutate: mutateProjectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { triggerReset } = useProjectActions(projectId)
  const { invalidate: invalidateRFAlgorithmTrainingParameters } = useRFAlgorithmProjectTrainingParameters(projectId)
  const { invalidate: invalidateDeepLearningTrainingParameters } = useDeepLearningProjectTrainingParameters(projectId)
  usePredictionQueuing(
    projectId,
    context,
    selectedImageId,
    projectTrainingSnapshot?.projectTrainingSnapshotId,
  )
  const currentlyPredictingImageId = usePredictionStore((state) => state.currentlyPredictingImageId)
  const { data: projectDatasetPredictionMasksStatus } = useProjectDatasetPredictionMasksStatus(projectId, context, projectTrainingSnapshot?.projectTrainingSnapshotId)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [confirmResetProjectModal, confirmResetProjectContextHolder] = Modal.useModal()
  const { mutate: mutateAnnotationClassesDistribution } = useAnnotationClassesDistribution(context === ProjectDatasetContext.Training ? projectId : undefined, selectedImageId)

  React.useEffect(() => {
    const sendImageTo = async (
      imageId: string,
      context: ProjectDatasetContext,
    ): Promise<void> => {
      await RFAPI.sendImageToDataset(projectId, imageId, context)
      await mutateImages()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TRAIN))
    }
    const onSendToValidation = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await sendImageTo(imageId, ProjectDatasetContext.Validation)
      await mutateProjectTrainingState()
    }
    const onSendToTraining = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await sendImageTo(imageId, ProjectDatasetContext.Training)
      await mutateProjectTrainingState()
    }
    const onSendToTrainingAndNavigate = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      const imageSlug = (event as CustomEvent).detail.imageSlug
      await sendImageTo(imageId, ProjectDatasetContext.Training)
      const annotateProjectRoute = WebUIRoutes.annotateProject(projectResponse?.slug, { image: imageSlug })
      navigate({ pathname: annotateProjectRoute.path, search: annotateProjectRoute.search })
      await mutateProjectTrainingState()
    }
    const onUploadImage = (): void => {
      setOpenUploadModal(true)
    }
    const onConfirmReset = async (): Promise<void> => {
      await triggerReset()
    }

    const onResetProject = async (): Promise<void> => {
      void confirmResetProjectModal.confirm({
        title: intl.formatMessage({ id: 'project.menu.item.reset.confirm.title', defaultMessage: 'Confirm reset project?' }),
        content: <Space direction="vertical">
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p1',
                defaultMessage: 'This action will delete all annotations from all images and unlink the trained model from the project.',
              })
            }
          </Paragraph>
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p2',
                defaultMessage: 'This action is not reversible.',
              })
            }
          </Paragraph>
          <Paragraph>
            {
              intl.formatMessage({
                id: 'project.menu.item.reset.confirm.p3',
                defaultMessage: 'Are you sure to continue?',
              })
            }
          </Paragraph>
        </Space>,
        okText: intl.formatMessage({ id: 'modal.confirm.continue.label', defaultMessage: 'Continue' }),
        onOk: onConfirmReset,
        centered: true,
        transitionName: '',
      })
    }

    const onDeleteProjectImage = async (event: Event): Promise<void> => {
      const imageId = (event as CustomEvent).detail.imageId as string
      await RFAPI.sendImageToDataset(
        projectId,
        imageId,
        ProjectDatasetContext.Deleted,
      )
      void mutateImages()
    }
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION,
      onSendToValidation,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING,
      onSendToTraining,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE,
      onSendToTrainingAndNavigate,
      {},
    )
    window.addEventListener(
      EVENTS_ID.PROJECT_DELETE_IMAGE,
      onDeleteProjectImage,
      {},
    )
    window.addEventListener(EVENTS_ID.UPLOAD_IMG_MODAL, onUploadImage, {})
    window.addEventListener(EVENTS_ID.PROJECT_RESET, onResetProject, {})
    return () => {
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_VALIDATION,
        onSendToValidation,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING,
        onSendToTraining,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_SEND_IMAGE_TO_TRAINING_AND_NAVIGATE,
        onSendToTrainingAndNavigate,
        {},
      )
      window.removeEventListener(
        EVENTS_ID.PROJECT_DELETE_IMAGE,
        onDeleteProjectImage,
        {},
      )
      window.removeEventListener(EVENTS_ID.PROJECT_RESET, onResetProject, {})
      window.removeEventListener(EVENTS_ID.UPLOAD_IMG_MODAL, onUploadImage, {})
    }
  }, [images?.length, invalidateRFAlgorithmTrainingParameters, invalidateDeepLearningTrainingParameters, mutateImages, mutateProject, mutateProjectTrainingSnapshot, navigate, projectId, projectResponse?.slug, intl, confirmResetProjectModal, mutateAnnotationClassesDistribution, mutateProjectTrainingState, triggerReset])

  const selectedImageSlug = searchParams.get('image')

  // Set initial selected image
  React.useEffect(() => {
    if (selectedImageSlug !== null && images !== undefined && !isImagesLoading && !isImagesValidating) {
      const image = images.find((image) => image.slug === selectedImageSlug)
      if (image !== undefined) {
        setSelectedImageId(image.id)
      } else {
        setSelectedImageId(images[0]?.id ?? undefined)
      }
    } else if (images !== undefined && images.length > 0) {
      setSelectedImageId(images[0]?.id ?? undefined)
    } else {
      setSelectedImageId(undefined)
    }
    return () => {
      setSelectedImageId(undefined)
    }
  }, [context, images, selectedImageSlug, setSelectedImageId, isImagesLoading, isImagesValidating])

  React.useEffect(() => {
    const image = (isImagesLoading || isImagesValidating) ? undefined : images?.find((image) => image.id === selectedImageId)
    if (image !== undefined) {
      setSearchParams((previousParams) => {
        return {
          ...previousParams,
          image: image.slug,
        }
      }, {
        replace: true,
      })
    }
  }, [images, isImagesLoading, isImagesValidating, selectedImageId, setSearchParams])

  const imageIdsWithPrediction = React.useMemo(() => {
    if (projectDatasetPredictionMasksStatus === undefined) {
      return []
    }
    return projectDatasetPredictionMasksStatus?.images
      .filter((image) => image.status === PredictionMaskStatus.Ready)
      .map((image) => image.imageId)
  }, [projectDatasetPredictionMasksStatus])

  const project = React.useMemo(() => {
    if (projectResponse === undefined) {
      return
    }
    return Project.fromProjectResponse(projectResponse)
  }, [projectResponse])

  const [leftPanelUploadNotification, leftPanelUploadContextHolder] = notification.useNotification()
  const onUpload = React.useCallback(
    async (response: unknown, selectImage: boolean): Promise<void> => {
      if (project === undefined) {
        return
      }
      const image = RFAPI.getImageSizeFromImageResponse(
        response as ProjectImageResponse,
      )
      try {
        await RFAPI.sendImageToDataset(project.id, image.id, context)
        await mutateImages()
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPLOADED_IMG))
        if (selectImage || selectedImageId === undefined) {
          setSelectedImageId(image.id)
        }
      } catch (err) {
        leftPanelUploadNotification.error({
          message: intl.formatMessage({
            id: 'editor.notification.left.panel.upload.error.title',
            defaultMessage: 'Something went wrong.',
          }),
          description: intl.formatMessage({
            id: 'editor.notification.left.panel.display.error.body',
            defaultMessage: 'Please reload the page.',
          }),
        })
        Sentry.captureException(err)
      }
    },
    [context, intl, mutateImages, project, selectedImageId, setSelectedImageId, leftPanelUploadNotification],
  )

  const panelImages = React.useMemo(
    () =>
      (images ?? []).map((image): LeftPanelImage => {
        return {
          ...image,
          url: RFAPI.createImageThumbnailURL(image.id, 256, 180),
          isPredicting: currentlyPredictingImageId === image.id,
          hasPrediction: imageIdsWithPrediction.includes(image.id),
          imagePredictionThumbnailURL: projectTrainingSnapshot !== undefined
            ? RFAPI.createImagePredictionThumbnail(
              image.id,
              projectTrainingSnapshot.projectTrainingSnapshotId,
              256,
              180,
            )
            : undefined,
        }
      }),
    [images, currentlyPredictingImageId, imageIdsWithPrediction, projectTrainingSnapshot],
  )

  const confirmImageUpload = async (imageIds: string[]): Promise<void> => {
    setIsConfirming(true)
    try {
      await Promise.all(
        imageIds.map(async (imageId) => {
          await RFAPI.sendImageToDataset(projectId, imageId, context)
        }),
      )
      const newTrainingImagesInfo = await Promise.all(
        imageIds.map(
          async (imageId) => await RFAPI.getImageInfo(imageId, projectId),
        ),
      )
      await mutateImages()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPLOADED_IMG))

      setSelectedImageId(newTrainingImagesInfo[0].id)
    } finally {
      setOpenUploadModal(false)
      setIsConfirming(false)
    }
  }

  return (project !== undefined)
    ? (
        <>
          {leftPanelUploadContextHolder}
          <LeftPanel
            addImage={() => {
              setOpenUploadModal(true)
            }}
            actionUrl={`/api/projects/${project.id}/image/upload`}
            selectedImageId={selectedImageId ?? ''}
            onUpload={onUpload}
            images={panelImages}
            selectImage={(imageId: string) => {
              setSelectedImageId(imageId)
            }}
            context={context}
            forceDisableDndZone={openUploadModal}
            projectId={projectId}
          >
            <UploadImagesModal
              isOpen={openUploadModal}
              onClose={() => {
                setOpenUploadModal(false)
              }}
              onConfirm={confirmImageUpload}
              uploadUrl={`/api/projects/${projectId}/image/upload`}
              isConfirmingUpload={isConfirming}
            />
            {confirmResetProjectContextHolder}
          </LeftPanel>
        </>
      )
    : null
}
