/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Errors codes available to be transferred to front end
 * @export
 */
export const ErrorMessageCodes = {
    RetryableError: 'RETRYABLE_ERROR',
    UnrecoverableError: 'UNRECOVERABLE_ERROR',
    ForbiddenError: 'FORBIDDEN_ERROR',
    NotAvailable: 'NOT_AVAILABLE'
} as const;
export type ErrorMessageCodes = typeof ErrorMessageCodes[keyof typeof ErrorMessageCodes];


export function ErrorMessageCodesFromJSON(json: any): ErrorMessageCodes {
    return ErrorMessageCodesFromJSONTyped(json, false);
}

export function ErrorMessageCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorMessageCodes {
    return json as ErrorMessageCodes;
}

export function ErrorMessageCodesToJSON(value?: ErrorMessageCodes | null): any {
    return value as any;
}

