/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOnboardingTasksResponse
 */
export interface UserOnboardingTasksResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasCompletedOnboardingFeedback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasDiscoveredMenu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasReadPluginInstallationInstructions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    irreversibleUserHasReadLogoutNotice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHasReadWelcome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHasReadPageTutorialWelcome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToCreateProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToOpenProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    projectListUserHowToEditProject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadUploadTrainingImage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadClassSection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAddAnnotation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadAccessValidate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    annotationPageUserHasReadTrain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadValidateIntroduction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadUploadImage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadVisualizeResultsOpacity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadVisualizeResultsClassHide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadSendImageAnnotate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingTasksResponse
     */
    validationPageUserHasReadDownloadPlugin?: boolean;
}

/**
 * Check if a given object implements the UserOnboardingTasksResponse interface.
 */
export function instanceOfUserOnboardingTasksResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserOnboardingTasksResponseFromJSON(json: any): UserOnboardingTasksResponse {
    return UserOnboardingTasksResponseFromJSONTyped(json, false);
}

export function UserOnboardingTasksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOnboardingTasksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'irreversibleUserHasCompletedOnboardingFeedback': !exists(json, 'irreversible_user_has_completed_onboarding_feedback') ? undefined : json['irreversible_user_has_completed_onboarding_feedback'],
        'irreversibleUserHasDiscoveredMenu': !exists(json, 'irreversible_user_has_discovered_menu') ? undefined : json['irreversible_user_has_discovered_menu'],
        'irreversibleUserHasReadPluginInstallationInstructions': !exists(json, 'irreversible_user_has_read_plugin_installation_instructions') ? undefined : json['irreversible_user_has_read_plugin_installation_instructions'],
        'irreversibleUserHasReadLogoutNotice': !exists(json, 'irreversible_user_has_read_logout_notice') ? undefined : json['irreversible_user_has_read_logout_notice'],
        'projectListUserHasReadWelcome': !exists(json, 'project_list_user_has_read_welcome') ? undefined : json['project_list_user_has_read_welcome'],
        'projectListUserHasReadPageTutorialWelcome': !exists(json, 'project_list_user_has_read_page_tutorial_welcome') ? undefined : json['project_list_user_has_read_page_tutorial_welcome'],
        'projectListUserHowToCreateProject': !exists(json, 'project_list_user_how_to_create_project') ? undefined : json['project_list_user_how_to_create_project'],
        'projectListUserHowToOpenProject': !exists(json, 'project_list_user_how_to_open_project') ? undefined : json['project_list_user_how_to_open_project'],
        'projectListUserHowToEditProject': !exists(json, 'project_list_user_how_to_edit_project') ? undefined : json['project_list_user_how_to_edit_project'],
        'annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain': !exists(json, 'annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train') ? undefined : json['annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train'],
        'annotationPageUserHasReadUploadTrainingImage': !exists(json, 'annotation_page_user_has_read_upload_training_image') ? undefined : json['annotation_page_user_has_read_upload_training_image'],
        'annotationPageUserHasReadClassSection': !exists(json, 'annotation_page_user_has_read_class_section') ? undefined : json['annotation_page_user_has_read_class_section'],
        'annotationPageUserHasReadAddAnnotation': !exists(json, 'annotation_page_user_has_read_add_annotation') ? undefined : json['annotation_page_user_has_read_add_annotation'],
        'annotationPageUserHasReadAccessValidate': !exists(json, 'annotation_page_user_has_read_access_validate') ? undefined : json['annotation_page_user_has_read_access_validate'],
        'annotationPageUserHasReadTrain': !exists(json, 'annotation_page_user_has_read_train') ? undefined : json['annotation_page_user_has_read_train'],
        'validationPageUserHasReadValidateIntroduction': !exists(json, 'validation_page_user_has_read_validate_introduction') ? undefined : json['validation_page_user_has_read_validate_introduction'],
        'validationPageUserHasReadUploadImage': !exists(json, 'validation_page_user_has_read_upload_image') ? undefined : json['validation_page_user_has_read_upload_image'],
        'validationPageUserHasReadVisualizeResultsOpacity': !exists(json, 'validation_page_user_has_read_visualize_results_opacity') ? undefined : json['validation_page_user_has_read_visualize_results_opacity'],
        'validationPageUserHasReadVisualizeResultsClassHide': !exists(json, 'validation_page_user_has_read_visualize_results_class_hide') ? undefined : json['validation_page_user_has_read_visualize_results_class_hide'],
        'validationPageUserHasReadSendImageAnnotate': !exists(json, 'validation_page_user_has_read_send_image_annotate') ? undefined : json['validation_page_user_has_read_send_image_annotate'],
        'validationPageUserHasReadDownloadPlugin': !exists(json, 'validation_page_user_has_read_download_plugin') ? undefined : json['validation_page_user_has_read_download_plugin'],
    };
}

export function UserOnboardingTasksResponseToJSON(value?: UserOnboardingTasksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'irreversible_user_has_completed_onboarding_feedback': value.irreversibleUserHasCompletedOnboardingFeedback,
        'irreversible_user_has_discovered_menu': value.irreversibleUserHasDiscoveredMenu,
        'irreversible_user_has_read_plugin_installation_instructions': value.irreversibleUserHasReadPluginInstallationInstructions,
        'irreversible_user_has_read_logout_notice': value.irreversibleUserHasReadLogoutNotice,
        'project_list_user_has_read_welcome': value.projectListUserHasReadWelcome,
        'project_list_user_has_read_page_tutorial_welcome': value.projectListUserHasReadPageTutorialWelcome,
        'project_list_user_how_to_create_project': value.projectListUserHowToCreateProject,
        'project_list_user_how_to_open_project': value.projectListUserHowToOpenProject,
        'project_list_user_how_to_edit_project': value.projectListUserHowToEditProject,
        'annotation_page_user_has_read_annotate_page_tutorial_annotate_and_train': value.annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain,
        'annotation_page_user_has_read_upload_training_image': value.annotationPageUserHasReadUploadTrainingImage,
        'annotation_page_user_has_read_class_section': value.annotationPageUserHasReadClassSection,
        'annotation_page_user_has_read_add_annotation': value.annotationPageUserHasReadAddAnnotation,
        'annotation_page_user_has_read_access_validate': value.annotationPageUserHasReadAccessValidate,
        'annotation_page_user_has_read_train': value.annotationPageUserHasReadTrain,
        'validation_page_user_has_read_validate_introduction': value.validationPageUserHasReadValidateIntroduction,
        'validation_page_user_has_read_upload_image': value.validationPageUserHasReadUploadImage,
        'validation_page_user_has_read_visualize_results_opacity': value.validationPageUserHasReadVisualizeResultsOpacity,
        'validation_page_user_has_read_visualize_results_class_hide': value.validationPageUserHasReadVisualizeResultsClassHide,
        'validation_page_user_has_read_send_image_annotate': value.validationPageUserHasReadSendImageAnnotate,
        'validation_page_user_has_read_download_plugin': value.validationPageUserHasReadDownloadPlugin,
    };
}

