import type * as React from 'react'
import styles from './styles/training-options.module.scss'
import { Collapse, Flex } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useFeatureFlags, useLicence, useProjectSettings } from '@app/api/hooks'
import { AlgorithmSection } from '@components/modals/project-settings/algorithm-settings'

interface TrainingOptionsProps {
  projectId: string
}
export const TrainingOptions: React.FC<TrainingOptionsProps> = ({ projectId }) => {
  const { data: flags } = useFeatureFlags()
  const { data: projectSettings } = useProjectSettings(projectId)
  const { data: licence } = useLicence()

  return <Flex vertical className={styles.trainingOptions}>
    <Collapse
      className={styles.collapseOptions}
      items={[{
        key: '1',
        label: <FormattedMessage id="training-section.algorithm-options.label" defaultMessage={'Options'} />,
        children: <AlgorithmSection
          projectId={projectId}
          enabledLocalUser={(flags !== undefined && !flags.enableLocalUser)}
          algorithmId={projectSettings?.algorithmId}
          algoQuickClemexnetV1Enabled={licence?.algoQuickClemexnetV1}
        />,
      }]}
      ghost />
  </Flex>
}
