import type * as React from 'react'
import { EmailLoginForm, type EmailLoginFormResult } from '@components/authentication/email-login-form'
import { AuthenticationProviders } from '@components/authentication/authentication-providers'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { AuthenticationFormDivider } from '@components/authentication/divider'
import { useIntl } from 'react-intl'
import styles from './styles/login.module.scss'
import { UserManagementResponseStatus } from '@app/api/openapi'
import { useBaseNotificationStore } from '@app/hooks/notifications'

export const LoginPage: React.FC = () => {
  const intl = useIntl()
  const { addNotification } = useBaseNotificationStore()
  const navigate = useNavigate()

  const handleEmailLoginSubmit = async (formData: EmailLoginFormResult): Promise<void> => {
    try {
      const response = await RFAPI.login(formData.email, formData.password, formData.remember)
      if (response.status !== UserManagementResponseStatus.Success) {
        throw new Error(intl.formatMessage({
          id: 'login.notification.login.user.invalid',
          defaultMessage: 'Invalid user login',
        }))
      }
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'login.notification.login.success.title',
          defaultMessage: 'Login is a Success!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.login.success.body',
          defaultMessage: 'Welcome to the Clemex\'s Studio',
        }),
      })
      navigate(WebUIRoutes.projectList().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'login.notification.login.error.title',
          defaultMessage: 'Login failed!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.login.error.body',
          defaultMessage: 'Invalid login or not active account. Please try again soon or use registration email link. If it persists please contact Clemex support (support@clemex.com).',
        }),
      })
      Sentry.captureException(error)
    }
  }

  return <RFPage background backgroundOnTopFooter className={styles.loginPage}>
    <AuthenticationHeader>
      <WelcomeTitle />
    </AuthenticationHeader>
    <RFContent className={styles.loginContent}>
      <div className={styles.loginFormsContainer}>
        <EmailLoginForm onSubmit={handleEmailLoginSubmit} />
        <AuthenticationFormDivider />
        <AuthenticationProviders />
      </div>
    </RFContent>
  </RFPage>
}
