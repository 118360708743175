import { NotificationPredictionUnknownError, NotificationAlgorithmConnectionLostDuringPrediction, NotificationAlgorithmConnectionLostDuringTraining, NotificationTrainingUnknownError, NotificationAlgorithmConnectionFailed, NotificationTrainingForbiddenError } from '@components/notifications/notification'
import { type ProjectNotificationType, useProjectsNotification } from '@app/hooks/notifications'
import type * as React from 'react'

export const NotificationRouter: React.FC = () => {
  const { data: notifications } = useProjectsNotification()
  return <>
    {
      notifications?.map((notification): React.ReactNode => {
        const notificationTypeToNotificationComponent: { [key in ProjectNotificationType]: React.ReactNode } = {
          AlgorithmConnectionFailed: <NotificationAlgorithmConnectionFailed
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          AlgorithmConnectionLostDuringPrediction: <NotificationAlgorithmConnectionLostDuringPrediction
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          AlgorithmConnectionLostDuringTraining: <NotificationAlgorithmConnectionLostDuringTraining
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          TrainingForbidden: <NotificationTrainingForbiddenError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          PredictionUnknown: <NotificationPredictionUnknownError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
          TrainingUnknown: <NotificationTrainingUnknownError
            key={notification.id}
            duration={notification.duration}
            projectId={notification.projectId}
          />,
        }

        return notificationTypeToNotificationComponent[notification.type]
      })
    }
  </>
}
