import * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { useIntl } from 'react-intl'
import styles from './styles/login.module.scss'
import { ActivateAccountForm, type ActivateAccountFormResult } from '@components/authentication/activate-account-form'
import { useBaseNotificationStore } from '@app/hooks/notifications'

interface ActivateAccountPageProps {
  token: string
}
export const ActivateAccountPage: React.FC<ActivateAccountPageProps> = ({ token }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useBaseNotificationStore()

  const handleActivateAccountSubmit = React.useCallback(async (formData: ActivateAccountFormResult): Promise<void> => {
    try {
      const response = await RFAPI.activateAccount(formData.token)
      if (response.status === 'FAIL') {
        throw new Error(intl.formatMessage({
          id: 'activate-account.notification.activate-account.failed',
          defaultMessage: 'Failed to activate account',
        }))
      }
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'activate-account.notification.activate-account.success.title',
          defaultMessage: 'Account activation was a success!',
        }),
        description: intl.formatMessage({
          id: 'activate-account.notification.activate-account.success.body',
          defaultMessage: 'Welcome to the Clemex\'s Studio',
        }),
      })
      navigate(WebUIRoutes.projectList().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'activate-account.notification.activate-account.error.title',
          defaultMessage: 'Failed to activate account',
        }),
        description: intl.formatMessage({
          id: 'activate-account.notification.activate-account.error.body',
          defaultMessage: 'The activation link might have expired. Try reset your password again.',
        }),
        duration: 10,
      })
      Sentry.captureException(error)
      navigate(WebUIRoutes.authentication().path)
    }
  }, [intl, navigate, addNotification])

  React.useEffect(() => {
    void handleActivateAccountSubmit({ token })
  }, [handleActivateAccountSubmit, token])

  return <RFPage background backgroundOnTopFooter>
    <AuthenticationHeader >
      <WelcomeTitle />
    </AuthenticationHeader>
    <RFContent className={styles.activateAccount}>
      <div className={styles.activateAccountContainer}>
        <ActivateAccountForm onSubmit={handleActivateAccountSubmit} token={token}/>
      </div>
    </RFContent>
  </RFPage>
}
