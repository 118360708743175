import * as React from 'react'
import { Button, Typography, Row, notification, Tooltip, Badge, Empty, Flex } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import UploadIcon from '@material-design-icons/svg/outlined/file_upload.svg'
import AddIcon from '@material-design-icons/svg/filled/add.svg'
import styles from './styles/left-panel.module.scss'
import { SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE, TOOLTIP_MOUSE_ENTER_DELAY, MESSAGE_UPLOAD_IMAGE_DIMENSION, MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT, MINIMAL_IMAGE_DIMENSION_PIXEL } from '@app/constants'
import { ImageDropAreaComponent } from './image-drop-area'
import { type RcFile } from 'rc-upload/lib/interface'
import { UploadingProgressionComponent } from './uploading-progression'
import { JumpingDots } from '@components/common/jumping-dots'
import { EditorImageThumbnail } from '@components/image-thumbnail'
import { ProjectDatasetContext } from '@app/api/openapi'
import { EditorClassEditor } from '@app/pages/editor-page/class-editor'
import { AnnotationClassesDistributionComponent } from '@components/panel/panel-elements/classes-distribution'
import { AnnotationParametersGlobalOpacity } from '@components/panel/panel-elements/annotation-parameters'

const { Title, Text } = Typography

interface ImagePredictionStatusWrapperProps {
  isPredicting: boolean
  hasPrediction: boolean
  children: React.ReactNode
}
const ImagePredictionStatusWrapper: React.FC<ImagePredictionStatusWrapperProps> = ({ children, isPredicting }) => {
  const ribbonText = <div className={styles.predictionStatus}>
    <Tooltip
      title={
        <FormattedMessage id={'image-prediction-status.tooltip.status.is-prediction'} defaultMessage={'Predicting...'} />
      }
      mouseLeaveDelay={0}
      mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
    >
      <JumpingDots />
    </Tooltip>
  </div>
  return <Badge.Ribbon placement="start" className={clsx(styles.imagePredictionStatusRibbon, { [styles.hasStatus]: (isPredicting) })} text={ribbonText} color="#e6e4e3">
    {children}
  </Badge.Ribbon>
}

interface ImageInfoWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  shouldDisplayImageInfo: boolean
  imageName: string
  width: number
  height: number
  imageIndex: number
}
const ImageInfoWrapper: React.FC<ImageInfoWrapperProps> = ({ children, shouldDisplayImageInfo, imageName, width, height, imageIndex, ...divProps }) => {
  return <div
    {...divProps}
    className={clsx(divProps.className, styles.imageInfoWrapper)}
  >
    {children}
    <div className={clsx(styles.imageInfo, { [styles.shouldDisplayImageInfo]: shouldDisplayImageInfo })}>
      <div className={styles.imageInfoTitle}>
        <FormattedMessage id={'left-panel.images.image-info.title'} defaultMessage={'Image #{imageIndex}'} values={{ imageIndex: imageIndex + 1 }} />
      </div>
      <div className={styles.imageName} title={imageName}>
        <FormattedMessage id={'left-panel.images.image-info.image-name'} defaultMessage={'{imageName}'} values={{ imageName }} />
      </div>
      <div className={styles.imageSize}>
        <FormattedMessage id={'left-panel.images.image-info.image-size'} defaultMessage={'{width}x{height}px'} values={{ width, height }} />
      </div>
    </div>
  </div>
}

export interface LeftPanelImage {
  id: string
  name: string
  slug: string
  height: number
  width: number
  url: string
  isPredicting: boolean
  hasPrediction: boolean
  imagePredictionThumbnailURL?: string
}

interface LeftPanelElementProps {
  underline?: boolean
  topLine?: boolean
  expand?: boolean
  shrinkable?: boolean
  separator?: boolean
  children: React.ReactNode
  className?: string
}
export const LeftPanelElement: React.FC<LeftPanelElementProps> = ({ underline, topLine, expand, shrinkable, separator, children, className }) => {
  return <Row
    className={clsx(
      styles.panelRow,
      {
        [styles.underline]: underline,
        [styles.topLine]: topLine,
        [styles.expand]: expand,
        [styles.shrinkable]: shrinkable,
        [styles.smallSeparator]: separator,
      },
      className,
    )}
  >
    {children}
  </Row>
}

interface LeftPanelProps {
  actionUrl: string
  selectedImageId: string
  images: LeftPanelImage[]
  addImage: () => void
  selectImage: (imageId: string) => void
  onUpload: (image: unknown, selectImage: boolean) => void
  context: ProjectDatasetContext
  forceDisableDndZone: boolean
  projectId: string
  children?: React.ReactNode
}
export const LeftPanel: React.FC<LeftPanelProps> = (props) => {
  const { actionUrl, onUpload, context: step, forceDisableDndZone, projectId, children } = props

  const intl = useIntl()
  const [uploadingData, setUploadingData] = React.useState<Array<[string, number, RcFile]>>([])
  const [shouldDisplayImageInfo, setShouldDisplayImageInfo] = React.useState<boolean>(false)
  const [uploadImageNotification, uploadImageContextHolder] = notification.useNotification()
  const selectedImageElement = React.useRef<HTMLDivElement>(null)

  const onMouseEnterImageList = (): void => {
    setShouldDisplayImageInfo(true)
  }
  const onMouseLeaveImageList = (): void => {
    setShouldDisplayImageInfo(false)
  }

  const onUploadSuccess = async (response: unknown, isSingleUpload: boolean): Promise<void> => {
    onUpload(response, isSingleUpload)
  }
  const onUploadFail = (file: RcFile): void => {
    uploadImageNotification.error({
      message: intl.formatMessage({
        id: 'projects.uploadImage.form.image.upload.error',
        defaultMessage: 'Image {fileName} upload failed. The image format or dimensions could be incorrect. Please validate the image is {minDimension}x{minDimension} and in the following formats: {imageFormat}',
      }, {
        fileName: file.name,
        minDimension: MINIMAL_IMAGE_DIMENSION_PIXEL,
        imageFormat: SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE,
      }),
    })
  }

  React.useEffect(() => {
    if ((selectedImageElement?.current) != null) {
      selectedImageElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedImageElement, props.selectedImageId])

  return <>
    {uploadImageContextHolder}
    <ImageDropAreaComponent
      className={clsx(styles.leftPanel, styles.uploadZone)}
      accept={SUPPORTED_UPLOAD_IMAGE_FORMATS_MEDIA_TYPE}
      action={actionUrl}
      method={'POST'}
      name={'image'}
      onSuccess={onUploadSuccess}
      onFail={onUploadFail}
      onUploadingChange={setUploadingData}
      dropZoneText={
        <div className={styles.dropZone}>
          <div>
            <div className={styles.dropZoneIcon}>
              <UploadIcon />
            </div>
            <Text className={styles.dropZoneLabel}>
              <FormattedMessage id="editor.add-image.dropzone.label" defaultMessage={'Release here to upload images'} />
              <br/>
              { MESSAGE_UPLOAD_IMAGE_SUPPORTED_FORMAT }
              <br/>
              { MESSAGE_UPLOAD_IMAGE_DIMENSION }
            </Text>
          </div>
        </div>
      }
      forceDisable={forceDisableDndZone || props.images.length === 0}
    >
      {children}
      <LeftPanelElement>
        <div className={styles.addImage} id='upload-image-section'>

          <div className={styles.addImageHeader}>
            <Title level={5} className={styles.addImageTitle}>
              <FormattedMessage id="editor.left-panel.title" defaultMessage={'Images'} />
            </Title>
            <Tooltip
              title={
                <FormattedMessage id={'left-panel.images.button.add'} defaultMessage={'Upload images'} />
              }
              mouseLeaveDelay={0}
              mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY}
            >
              <Button
                className={styles.addImageButton}
                icon={<AddIcon />}
                onClick={() => {
                  const element = document.activeElement as HTMLElement
                  props.addImage()
                  element.blur()
                }}
              />
            </Tooltip>
          </div>
        </div>
      </LeftPanelElement>
      <LeftPanelElement expand className={styles.leftPanelImages}>
        <div className={styles.imageListContainer}>
          <div className={styles.imageList} onMouseEnter={onMouseEnterImageList} onMouseLeave={onMouseLeaveImageList} id='image-list'>
            {props.images.length === 0 && uploadingData.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {props.images.map((image, imageIndex) => {
              return <ImageInfoWrapper
                key={image.id}
                shouldDisplayImageInfo={shouldDisplayImageInfo}
                imageName={image.name}
                height={image.height}
                width={image.width}
                imageIndex={imageIndex}
                onClick={() => { props.selectImage(image.id) }}
              >
                <ImagePredictionStatusWrapper isPredicting={image.isPredicting} hasPrediction={image.hasPrediction}>
                  <div ref={props.selectedImageId === image.id ? selectedImageElement : undefined} className={clsx(styles.imageContainer, { [styles.selected]: props.selectedImageId === image.id })}>
                    <EditorImageThumbnail
                      imageId={image.id}
                      imageSlug={image.slug}
                      imageThumbnailUrl={image.url}
                      imagePredictionMaskThumbnailUrl={image.hasPrediction ? image.imagePredictionThumbnailURL : undefined}
                      width={256}
                      height={180}
                      step={step}
                      isOneImage={props.images.length === 1}
                    />
                  </div>
                </ImagePredictionStatusWrapper>
              </ImageInfoWrapper>
            })}
          </div>
        </div>
      </LeftPanelElement>
      <UploadingProgressionComponent uploadingData={uploadingData} />
      <Flex vertical justify='flex-end' className={styles.leftPanelBottom}>
        <LeftPanelElement underline topLine shrinkable className={styles.leftPanelClasses}>
          <EditorClassEditor projectId={projectId} context={step} />
        </LeftPanelElement>
        {
          step === ProjectDatasetContext.Training &&
          <LeftPanelElement underline>
            <AnnotationParametersGlobalOpacity
            />
          </LeftPanelElement>
        }
        {step === ProjectDatasetContext.Training && (<LeftPanelElement expand >
          <AnnotationClassesDistributionComponent
            projectId={projectId}
          />
        </LeftPanelElement>)}
      </Flex>
    </ImageDropAreaComponent >
  </>
}
