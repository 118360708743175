import type * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Typography, notification, Button, Flex, Space } from 'antd'
import { useFeatureFlags, useProfile } from '@app/api/hooks'
import * as RFAPI from '@app/api/api'
import { UserAssistanceType } from '@app/api/openapi'
import { type UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'
const { Text, Paragraph } = Typography

interface RequestLicenceFeatureProps {
  featureText: string
  licenceFeatureRequest: UserAssistanceLicenceFeature
  className?: string
}
export const RequestLicenceFeature: React.FC<RequestLicenceFeatureProps> = ({ featureText, licenceFeatureRequest, className }) => {
  const { data: flags } = useFeatureFlags()
  const { data: userProfile } = useProfile()
  const [form] = Form.useForm()
  const [notificationApi, notificationContext] = notification.useNotification({
    placement: 'topRight',
  })
  const intl = useIntl()

  const mailToContactSales = (): string => {
    const mailSubject = intl.formatMessage({
      id: 'request-licence-feature.mail.template.subject',
      defaultMessage: 'Request access to {feature} in Clemex Studio',
    }, { feature: featureText })
    const mailBody = intl.formatMessage({
      id: 'request-licence-feature.mail.template.body2',
      defaultMessage: "Hi,\n\nI'd like to activate the {feature} [{licenceFeatureRequest}] in Clemex Studio.\n\nCan you assist me with this? ",
    }, { url: window.location.href, feature: featureText, licenceFeatureRequest })
    return `mailto:clemex-sales@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
  }

  const submitForm = async (): Promise<void> => {
    form.resetFields()
    void RFAPI.requestAssistance([UserAssistanceType.RequestLicenceFeature], licenceFeatureRequest).then(() => {
      notificationApi.success({
        message: <FormattedMessage id='request-licence-feature.notification.success.contact-me.title' defaultMessage='Request submitted' />,
        description: <FormattedMessage
          id='request-licence-feature.notification.success.contact-me.description'
          defaultMessage='Your request for the feature has been submitted to Clemex team. Expect to be contacted within two business days' />,
        placement: 'topRight',
        duration: 7,
      })
    }).catch(() => {
      notificationApi.error({
        message: <FormattedMessage id='request-licence-feature.notification.error.contact-me.title' defaultMessage='Request failed' />,
        description: <FormattedMessage
          id='request-licence-feature.notification.error.contact-me.description'
          defaultMessage='Your request for the feature could not be submitted to Clemex. Please try again or contact us at: {salesEmail}'
          values={{
            salesEmail: <a href={mailToContactSales()}>clemex-sales@clemex.com</a>,
          }} />,
        placement: 'topRight',
        duration: 7,
      })
    })
  }

  return <div className={className}>
    <Paragraph>
      <FormattedMessage
        id='request-licence-feature.acquire-feature.contact'
        defaultMessage='The {feature} is a feature available upon request.'
        values={{ feature: <Text italic>{featureText}</Text> }}
      />
    </Paragraph>
    {notificationContext}
    {flags !== undefined && !flags.enableLocalUser
      ? <Form form={form} layout="vertical" onFinish={submitForm}>
          <Paragraph>
            <FormattedMessage
              id='request-licence-feature.acquire-feature.contacted-email'
              defaultMessage='Click on the button to request it, you will be contacted at the following email address: '
            />
            <Text ellipsis={{ tooltip: true }} >
              {userProfile?.email}
            </Text>
          </Paragraph>
          <Flex justify='flex-end' >
            <Button type='primary' htmlType="submit">
              <FormattedMessage id='request-licence-feature.button.contact-me' defaultMessage='Contact me' />
            </Button>
          </Flex>
        </Form>
      : <Space direction='vertical' size='middle'>
          <Text>
            <FormattedMessage id="request-licence-feature.contact" defaultMessage='To contact Clemex you can send an email at: {salesEmail} or by phone at: {salesPhoneNumber}' values={{
              salesEmail: <><br/><a href={mailToContactSales()}>clemex-sales@clemex.com</a></>,
              salesPhoneNumber: '1-888-651-6573',
            }} />
          </Text>
        </Space>
    }
  </div>
}
