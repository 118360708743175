import type * as React from 'react'
import { Row } from 'antd'
import styles from './panel-element.module.scss'
import clsx from 'clsx'

interface PanelElementProps {
  underline?: boolean
  expand?: boolean
  shrinkable?: boolean
  children: React.ReactNode
}
export const PanelElement: React.FC<PanelElementProps> = ({ underline, expand, shrinkable, children }) => {
  return <Row
    className={clsx(
      styles.panelRow,
      {
        [styles.underline]: underline,
        [styles.expand]: expand,
        [styles.shrinkable]: shrinkable,
      },
    )}
  >
    {children}
  </Row>
}
