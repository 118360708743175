/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PredictionMaskStatus } from './PredictionMaskStatus';
import {
    PredictionMaskStatusFromJSON,
    PredictionMaskStatusFromJSONTyped,
    PredictionMaskStatusToJSON,
} from './PredictionMaskStatus';

/**
 * DatasetImagePredictionMaskStatus is used to know the status of the prediction mask of an image in a dataset.
 * @export
 * @interface DatasetImagePredictionMaskStatus
 */
export interface DatasetImagePredictionMaskStatus {
    /**
     * 
     * @type {string}
     * @memberof DatasetImagePredictionMaskStatus
     */
    imageId: string;
    /**
     * 
     * @type {PredictionMaskStatus}
     * @memberof DatasetImagePredictionMaskStatus
     */
    status: PredictionMaskStatus;
}

/**
 * Check if a given object implements the DatasetImagePredictionMaskStatus interface.
 */
export function instanceOfDatasetImagePredictionMaskStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DatasetImagePredictionMaskStatusFromJSON(json: any): DatasetImagePredictionMaskStatus {
    return DatasetImagePredictionMaskStatusFromJSONTyped(json, false);
}

export function DatasetImagePredictionMaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetImagePredictionMaskStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageId': json['image_id'],
        'status': PredictionMaskStatusFromJSON(json['status']),
    };
}

export function DatasetImagePredictionMaskStatusToJSON(value?: DatasetImagePredictionMaskStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_id': value.imageId,
        'status': PredictionMaskStatusToJSON(value.status),
    };
}

