import { useAlgorithmStatus, usePrepareAlgorithm } from '@app/api/hooks'
import {
  MessageAlgorithmConnected,
  MessageWaitingAlgorithmAvailable,
} from '@components/messages/messages'
import type React from 'react'

export const AlgorithmStatusMessages: React.FC<{ projectId?: string }> = ({
  projectId,
}) => {
  const { data: isAlgorithmReady } = useAlgorithmStatus(projectId)
  const { isPreparingAlgorithm } = usePrepareAlgorithm(projectId)
  return (
    <>
      <MessageWaitingAlgorithmAvailable
        isAlgorithmReady={isAlgorithmReady ?? false}
        isPreparingAlgorithm={isPreparingAlgorithm}
      />
      <MessageAlgorithmConnected
        isAlgorithmReady={isAlgorithmReady ?? false}
        isPreparingAlgorithm={isPreparingAlgorithm}
      />
    </>
  )
}
