/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSettingsCanvasValueObject } from './UserSettingsCanvasValueObject';
import {
    UserSettingsCanvasValueObjectFromJSON,
    UserSettingsCanvasValueObjectFromJSONTyped,
    UserSettingsCanvasValueObjectToJSON,
} from './UserSettingsCanvasValueObject';

/**
 * User settings.
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {UserSettingsCanvasValueObject}
     * @memberof UserSettings
     */
    canvasSettings: UserSettingsCanvasValueObject;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "canvasSettings" in value;

    return isInstance;
}

export function UserSettingsFromJSON(json: any): UserSettings {
    return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canvasSettings': UserSettingsCanvasValueObjectFromJSON(json['canvas_settings']),
    };
}

export function UserSettingsToJSON(value?: UserSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canvas_settings': UserSettingsCanvasValueObjectToJSON(value.canvasSettings),
    };
}

