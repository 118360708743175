import type * as React from 'react'
import { Switch, Typography, Flex, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useExportOptions } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'
import { useBindExportProjectShortcut } from '@app/hooks/editor-shortcut'
import styles from './styles/export-settings.module.scss'
import clsx from 'clsx'
import { RequestLicenceFeature } from '@components/messages/request-licensed-feature'
import { UserAssistanceLicenceFeature } from '@app/api/openapi/models/UserAssistanceLicenceFeature'

const { Title, Paragraph } = Typography
interface ExportSettingsProps {
  projectId: string
}
export const ExportSettings: React.FC<ExportSettingsProps> = ({ projectId }) => {
  const { data: exportOptions, mutateExportOptions } = useExportOptions(projectId)
  useBindExportProjectShortcut(projectId)

  return <Flex vertical className={styles.root}>
    <Title level={4} className={styles.settingsSectionTitle}>
      <FormattedMessage id='options.label' defaultMessage='Export Options' />
    </Title>
    <Flex vertical gap={'middle'} justify='space-between' className={styles.settingsSectionContent}>
      <Flex justify='space-between'>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id='export.options.re-export-original-image.label' defaultMessage={'Re-export original image'} />
        </Title>
        <Switch defaultChecked={exportOptions?.includeOriginalImage}
          onChange={(checked: boolean) => {
            if (exportOptions === undefined) {
              return
            }
            void mutateExportOptions({
              ...exportOptions,
              includeOriginalImage: checked,
            })
          }}
        />

      </Flex>
      <Flex justify='space-between'>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id='export.options.combine-masks.label' defaultMessage={'Combine masks in a single colorized file'} />
        </Title>
        <Switch defaultChecked={exportOptions?.includeCombinedMasks}
          onChange={(checked: boolean) => {
            if (exportOptions === undefined) {
              return
            }
            void mutateExportOptions({
              ...exportOptions,
              includeCombinedMasks: checked,
              includeIndividualMasks: !checked,
            })
          }}
        />

      </Flex>
      <Flex justify='flex-end'>
        <Button type='primary' className={styles.exportButton} onClick={() => {
          window.dispatchEvent(new CustomEvent(EVENTS_ID.EXPORT_PROJECT))
        }}>
          <FormattedMessage id='project.export.label' defaultMessage={'Export'} />
        </Button>
      </Flex>
    </Flex>
  </Flex>
}

export const ExportFeatureExplanation: React.FC = () => {
  return <Flex className={styles.root} vertical>
    <Title level={4} className={styles.settingsSectionTitle}><FormattedMessage id="project.export.explanation.modal.title" defaultMessage="What is the project export feature?" />
    </Title>
    <Flex className={clsx(styles.explanationContent)} vertical>
      <Paragraph>
        <FormattedMessage id="project.export.explanation.modal.description.2" defaultMessage="Project exportation allows to export all images, annotation masks and prediction masks of a project." />
      </Paragraph>
      <RequestLicenceFeature featureText='Project export' licenceFeatureRequest={UserAssistanceLicenceFeature.ExportProject} />
    </Flex>
  </Flex>
}
