import { useAlgorithmStatus, useProjectDatasetPredictionMasksStatus, useProjectImages } from '@app/api/hooks'
import { PredictionMaskStatus, type ProjectDatasetContext } from '@app/api/openapi'

import * as Sentry from '@sentry/browser'
import { api } from '@app/api/api'
import { type EditorStoreState, useEditorStore, type EditorStoreAction } from '../stores/editor'
import React, { useEffect } from 'react'
import { Queue } from '@app/helpers/queue'
import { useSWRConfig } from 'swr'
import { usePredictionStore } from '@app/stores/prediction'
import { sortBy } from 'lodash'
import { CACHE_KEYS } from '@app/api/cache-keys'

export enum PredictionEventIds {
  PREDICTION_STARTING = 'PREDICTION_STARTING',
  PREDICTION_SUCCESS = 'PREDICTION_SUCCESS',
  PREDICTION_FAIL = 'PREDICTION_FAIL',
}
export interface PredictionFailEventPayload {
  imageId: string
  projectId: string
  projectTrainingSnapshotId: string
}
export interface PredictionQueueEvent {
  projectId: string
  imageId: string
  projectTrainingSnapshotId: string
}
export interface PredictionEventStarting extends PredictionQueueEvent { }
export interface PredictionEventSuccess extends PredictionQueueEvent {
  url: string
}
export interface PredictionEventFail extends PredictionQueueEvent {
  error: unknown
}

export const usePredict = (): (
imageId: string,
projectId: string,
context: ProjectDatasetContext,
projectTrainingSnapshotId: string,
) => Promise<void> => {
  const { mutate: globalMutate } = useSWRConfig()

  const predict = async (
    imageId: string,
    projectId: string,
    context: ProjectDatasetContext,
    projectTrainingSnapshotId: string,
  ): Promise<void> => {
    const payload = {
      imageId,
      projectId,
      projectTrainingSnapshotId,
    }
    try {
      window.dispatchEvent(
        new CustomEvent<PredictionEventStarting>(
          PredictionEventIds.PREDICTION_STARTING,
          { detail: payload },
        ),
      )

      const response = await api.predictOnImageUsingExplicitTrainingSnapshotApiAlgorithmPredictProjectIdImageIdProjectTrainingSnapshotIdPost(payload)
      await globalMutate(CACHE_KEYS.PROJECT_DATASET_PREDICTION_MASKS_STATUS(projectId, context, projectTrainingSnapshotId))
      await globalMutate(CACHE_KEYS.IMAGE_PREDICTION_MASKS_STATUS(projectId, imageId, projectTrainingSnapshotId))

      window.dispatchEvent(
        new CustomEvent<PredictionEventSuccess>(
          PredictionEventIds.PREDICTION_SUCCESS,
          { detail: { ...payload, ...response } },
        ),
      )
    } catch (error) {
      window.dispatchEvent(
        new CustomEvent<PredictionEventFail>(PredictionEventIds.PREDICTION_FAIL, {
          detail: { ...payload, error },
        }),
      )
      Sentry.captureException(error)
    }
  }
  return predict
}

/**
 * `usePredictionQueuing` is a hook that allows to queue predictions.
 * It manages when to add prediction to the queue, reset the queue, change priority, etc.
 * The queue also manage the retry of the prediction if it fails up to the max retry count.
 *
 * This hook refresh the queue when:
 * - the project training snapshot id changes
 * - the images from dataset changes (add image, remove image)
 * - the selected image id changes (in order to prioritize the selected image)
 */
export const usePredictionQueuing = (
  projectId: string | undefined,
  context: ProjectDatasetContext,
  selectedImageId: string | undefined,
  projectTrainingSnapshotId: string | undefined,
): void => {
  // Get the status of the prediction masks for the project dataset
  const { data: projectDatasetPredictionMasksStatus } = useProjectDatasetPredictionMasksStatus(projectId, context, projectTrainingSnapshotId)
  // Get the images from the project dataset
  const { data: images } = useProjectImages(projectId, context)
  const { data: isAlgorithmReady } = useAlgorithmStatus(projectId)
  const predict = usePredict()
  const setCurrentlyPredictingImageId = usePredictionStore((state) => state.setCurrentPredictingImageId)
  const incrementPredictionFailCount = usePredictionStore((state) => state.incrementPredictionFailCount)
  const getPredictionRetryCount = usePredictionStore((state) => state.getPredictionRetryCount)
  const isPredictionAllowed = usePredictionStore((state) => state.isPredictionAllowed)
  const predictionRetryCount = usePredictionStore((state) => state.predictionRetryCount)

  // Bind the prediction fail event to the store
  // This will allow to increment the prediction fail count
  useEffect(() => {
    const handlePredictionFail = (event: Event): void => {
      const customEvent = (event as CustomEvent<PredictionFailEventPayload>)
      const { imageId, projectTrainingSnapshotId } = customEvent.detail
      incrementPredictionFailCount(imageId, projectTrainingSnapshotId)
    }
    window.addEventListener(PredictionEventIds.PREDICTION_FAIL, handlePredictionFail, {})
    return () => {
      window.removeEventListener(PredictionEventIds.PREDICTION_FAIL, handlePredictionFail, {})
    }
  }, [incrementPredictionFailCount])

  // Create the queue ref with its consumer callback
  const queueRef = React.useRef(new Queue<{
    imageId: string
    projectId: string
    context: ProjectDatasetContext
    projectTrainingSnapshotId: string
  }>(async (data) => {
    setCurrentlyPredictingImageId(data.imageId)
    // Note: if the prediction failed, it will trigger a PredictionFail event
    //       The event is handled in the useEffect above
    await predict(
      data.imageId,
      data.projectId,
      data.context,
      data.projectTrainingSnapshotId,
    )
    setCurrentlyPredictingImageId(undefined)
  }))

  // When the state dependencies changes, we want to update the queue
  useEffect(() => {
    if (isAlgorithmReady !== true || projectId === undefined || projectTrainingSnapshotId === undefined || images === undefined) {
      queueRef.current.flush()
      return
    }
    const alreadyPredictedImageIds = projectDatasetPredictionMasksStatus?.images
      .filter((pm) => pm.status === PredictionMaskStatus.Ready)
      .map((pm) => pm.imageId) ?? []

    // Prioritize the array:
    // - Selected image has the highest priority
    // - Image with prediction failure has malus priority
    // - The rest of the images are sorted by their index in the dataset
    const imageIdsToPredictPrioritized = sortBy(
      images.map((image, imageIndex) => { return { imageId: image.id, imageIndex } }),
      (iteratee) => {
        return [
          iteratee.imageId === selectedImageId ? 0 : 1,
          getPredictionRetryCount(iteratee.imageId, projectTrainingSnapshotId),
          iteratee.imageIndex,
        ]
      },
    ).map((imageId) => imageId.imageId)
      // Filter the already predicted images
      .filter((imageId) => !alreadyPredictedImageIds.includes(imageId))
      // Filter using the store to check if we can predict the image
      // (if the image has been predicted too many times, we don't want to predict it again)
      .filter((imageId) => isPredictionAllowed(imageId, projectTrainingSnapshotId))

    // Flush the queue and recreate it with the new image ids
    queueRef.current.flush()
    imageIdsToPredictPrioritized.forEach((imageId) => {
      queueRef.current.addTask({
        imageId,
        projectId,
        context,
        projectTrainingSnapshotId,
      }, { startQueue: false })
    })
    queueRef.current.resume()
  }, [
    isAlgorithmReady,
    context,
    getPredictionRetryCount,
    images,
    isPredictionAllowed,
    projectDatasetPredictionMasksStatus,
    projectId,
    projectTrainingSnapshotId,
    selectedImageId,
    // Add predictionRetryCount to the dependencies to force the useEffect to be called when the predictionRetryCount changes
    predictionRetryCount,
  ])
}

type useSelectedImageIdReturn = [
  EditorStoreState['selectedImageId'],
  EditorStoreAction['setSelectedImage'],
]
export function useSelectedImageId (): useSelectedImageIdReturn {
  const selectedImage = useEditorStore((state) => state.selectedImageId)
  const setSelectedImage = useEditorStore((state) => state.setSelectedImage)
  return [selectedImage, setSelectedImage]
}

type useSelectedToolReturn = [
  EditorStoreState['selectedTool'],
  EditorStoreAction['setSelectedTool'],
]
export function useSelectedTool (): useSelectedToolReturn {
  const selectedTool = useEditorStore((state) => state.selectedTool)
  const setSelectedTool = useEditorStore((state) => state.setSelectedTool)
  return [selectedTool, setSelectedTool]
}

type useBrushSizeReturn = [
  EditorStoreState['brushSize'],
  EditorStoreAction['setBrushSize'],
]
export function useBrushSize (): useBrushSizeReturn {
  return useEditorStore((state) => [state.brushSize, state.setBrushSize])
}

type useSelectedAnnotationClassIndexReturn = [
  EditorStoreState['selectedAnnotationClassColorIndex'],
  EditorStoreAction['setSelectedAnnotationClassColorIndex'],
]
export function useSelectedAnnotationClassColorIndex (): useSelectedAnnotationClassIndexReturn {
  const selectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.selectedAnnotationClassColorIndex,
  )
  const setSelectedAnnotationClassColorIndex = useEditorStore(
    (state) => state.setSelectedAnnotationClassColorIndex,
  )
  return [selectedAnnotationClassColorIndex, setSelectedAnnotationClassColorIndex]
}

type useHiddenAnnotationClassIndexesReturn = [
  EditorStoreState['hiddenAnnotationClassIndexes'],
  EditorStoreAction['setHiddenAnnotationClassIndexes'],
]
export function useHiddenAnnotationClassIndexes (): useHiddenAnnotationClassIndexesReturn {
  const hiddenAnnotationClassIndexes = useEditorStore((state) => state.hiddenAnnotationClassIndexes)
  const setHiddenAnnotationClassIndexes = useEditorStore(
    (state) => state.setHiddenAnnotationClassIndexes,
  )
  return [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes]
}

type useGlobalAnnotationOpacityReturn = [
  ReturnType<EditorStoreState['getGlobalAnnotationOpacity']>,
  EditorStoreAction['setGlobalAnnotationOpacity'],
]
export function useGlobalAnnotationOpacity (): useGlobalAnnotationOpacityReturn {
  const globalAnnotationOpacity = useEditorStore((state) => state.getGlobalAnnotationOpacity())
  const setGlobalAnnotationOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationOpacity,
  )
  return [globalAnnotationOpacity, setGlobalAnnotationOpacity]
}

type useGlobalAnnotationFullOpacityReturn = [
  EditorStoreState['globalAnnotationFullOpacity'],
  EditorStoreAction['setGlobalAnnotationFullOpacity'],
]
export function useGlobalAnnotationFullOpacity (): useGlobalAnnotationFullOpacityReturn {
  const globalAnnotationFullOpacity = useEditorStore((state) => state.globalAnnotationFullOpacity)
  const setGlobalAnnotationFullOpacity = useEditorStore(
    (state) => state.setGlobalAnnotationFullOpacity,
  )
  return [globalAnnotationFullOpacity, setGlobalAnnotationFullOpacity]
}

type useGlobalPredictionOpacityReturn = [
  ReturnType<EditorStoreState['getGlobalPredictionOpacity']>,
  EditorStoreAction['setGlobalPredictionOpacity'],
]
export function useGlobalPredictionOpacity (): useGlobalPredictionOpacityReturn {
  const globalPredictionOpacity = useEditorStore(
    (state) => state.getGlobalPredictionOpacity(),
  )
  const setGlobalPredictionOpacity = useEditorStore(
    (state) => state.setGlobalPredictionOpacity,
  )
  return [globalPredictionOpacity, setGlobalPredictionOpacity]
}

type useGlobalPredictionFullOpacityReturn = [
  EditorStoreState['globalPredictionFullOpacity'],
  EditorStoreAction['setGlobalPredictionFullOpacity'],
]
export function useGlobalPredictionFullOpacity (): useGlobalPredictionFullOpacityReturn {
  const globalPredictionFullOpacity = useEditorStore(
    (state) => state.globalPredictionFullOpacity,
  )
  const setGlobalPredictionFullOpacity = useEditorStore(
    (state) => state.setGlobalPredictionFullOpacity,
  )
  return [globalPredictionFullOpacity, setGlobalPredictionFullOpacity]
}

type useGlobalPredictionVisibilityReturn = [
  EditorStoreState['globalPredictionVisibility'],
  EditorStoreAction['setGlobalPredictionVisibility'],
]
export function useGlobalPredictionVisibility (): useGlobalPredictionVisibilityReturn {
  const globalPredictionVisibility = useEditorStore(
    (state) => state.globalPredictionVisibility,
  )
  const setGlobalPredictionVisibility = useEditorStore(
    (state) => state.setGlobalPredictionVisibility,
  )
  return [globalPredictionVisibility, setGlobalPredictionVisibility]
}

type useIsTrainingReturn = [
  EditorStoreState['isTraining'],
  EditorStoreAction['setIsTraining'],
]
export function useIsTraining (): useIsTrainingReturn {
  const isTraining = useEditorStore((state) => state.isTraining)
  const setIsTraining = useEditorStore((state) => state.setIsTraining)
  return [isTraining, setIsTraining]
}

type UseIsDirectMeasureAreaToolFreehand = [
  EditorStoreState['isDirectMeasureAreaToolFreehand'],
  EditorStoreAction['setIsDirectMeasureAreaToolFreehand'],
]
export const useIsDirectMeasureAreaToolFreehand = (): UseIsDirectMeasureAreaToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasureAreaToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasureAreaToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type UseIsDirectMeasurePerimeterToolFreehand = [
  EditorStoreState['isDirectMeasurePerimeterToolFreehand'],
  EditorStoreAction['setIsDirectMeasurePerimeterToolFreehand'],
]
export const useIsDirectMeasurePerimeterToolFreehand = (): UseIsDirectMeasurePerimeterToolFreehand => {
  const isFreehand = useEditorStore((state) => state.isDirectMeasurePerimeterToolFreehand)
  const setIsFreehand = useEditorStore((state) => state.setIsDirectMeasurePerimeterToolFreehand)
  return [isFreehand, setIsFreehand] as const
}

type useHiddenPredictionClassIndexesReturn = [
  EditorStoreState['hiddenPredictionClassIndexes'],
  EditorStoreAction['setHiddenPredictionClassIndexes'],
]
export function useHiddenPredictionClassIndexes (): useHiddenPredictionClassIndexesReturn {
  const hiddenPredictionClassIndexes = useEditorStore((state) => state.hiddenPredictionClassIndexes)
  const setHiddenPredictionClassIndexes = useEditorStore(
    (state) => state.setHiddenPredictionClassIndexes,
  )
  return [hiddenPredictionClassIndexes, setHiddenPredictionClassIndexes]
}

type useGlobalAnnotationVisibilityReturn = [
  EditorStoreState['globalAnnotationVisibility'],
  EditorStoreAction['setGlobalAnnotationVisibility'],
]
export function useGlobalAnnotationVisibility (): useGlobalAnnotationVisibilityReturn {
  const globalAnnotationVisibility = useEditorStore(
    (state) => state.globalAnnotationVisibility,
  )
  const setGlobalAnnotationVisibility = useEditorStore(
    (state) => state.setGlobalAnnotationVisibility,
  )
  return [globalAnnotationVisibility, setGlobalAnnotationVisibility]
}
