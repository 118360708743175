import * as React from 'react'
import styles from './styles/image-drop-area.module.scss'
import clsx from 'clsx'
import RcUpload from 'rc-upload'
import { type RcFile, type UploadProgressEvent, type UploadRequestMethod } from 'rc-upload/lib/interface'

export interface ImageDropAreaComponentProps {
  onSuccess: (image: unknown, isSingleUpload: boolean, file: RcFile) => void
  onFail: (file: RcFile) => void
  onUploadingChange: (uploadingData: Array<[string, number, RcFile]>) => void
  dropZoneText: React.ReactNode
  action: string
  method?: UploadRequestMethod
  accept?: string
  name?: string
  className?: string
  forceDisable: boolean
  children?: React.ReactNode
}

export const ImageDropAreaComponent: React.FC<ImageDropAreaComponentProps> = (props: React.PropsWithChildren<ImageDropAreaComponentProps>) => {
  const { onSuccess, onFail, onUploadingChange, dropZoneText, action, method = 'POST', name, accept, forceDisable } = props
  const { className } = props
  const { children } = props

  const [isDragingOver, setIsDragingOver] = React.useState<boolean>(false)
  const [isDisabled, setIsDisabled] = React.useState<boolean>(forceDisable)
  const [imageUploadingProgress, setImageUploadingProgress] = React.useState<Array<[string, number, RcFile]>>([])
  const [lastSingleUploadId, setLastSingleUploadId] = React.useState<string | undefined>(undefined)

  const handleSuccessUpload = async (response: unknown, file: RcFile): Promise<void> => {
    setImageUploadingProgress(imageUploadingProgress => (imageUploadingProgress.filter(([imgFileId]) => imgFileId !== file.uid)))
    setIsDragingOver(false)
    onSuccess(response, file.uid === lastSingleUploadId, file)
  }
  const handleStartUpload = async (file: RcFile): Promise<void> => {
    setImageUploadingProgress(imageUploadingProgress => ([...imageUploadingProgress, [file.uid, 0, file]]))
  }
  const handleErrorUpload = async (_error: Error, _ret: unknown, file: RcFile): Promise<void> => {
    setImageUploadingProgress(imageUploadingProgress => (imageUploadingProgress.filter(([imgFileId]) => imgFileId !== file.uid)))
    onFail(file)
  }
  const handleProgressUpload = (event: UploadProgressEvent, file: RcFile): void => {
    setImageUploadingProgress(imageUploadingProgress => (imageUploadingProgress.map(([imgFileId, progress, currentFile]) => [imgFileId, imgFileId === file.uid && event.percent !== undefined ? event.percent : progress, currentFile])))
  }

  const handleBatchStart = (fileList: Array<{ file: RcFile }>): void => {
    if (fileList.length === 1) {
      setLastSingleUploadId(fileList[0].file.uid)
    }
  }

  React.useEffect(() => {
    onUploadingChange(imageUploadingProgress)
  }, [imageUploadingProgress, onUploadingChange])

  React.useEffect(() => {
    let draggingEventCounter = 0

    setIsDisabled(forceDisable)
    const handleDragStart = (): void => {
      setIsDisabled(true)
    }
    const handleDragEnd = (): void => {
      setIsDisabled(forceDisable)
    }
    const handleDrop = (event: React.DragEvent | DragEvent): void => {
      event.preventDefault()
      setIsDragingOver(false)
      draggingEventCounter = 0
    }
    const handleDragEnter = (event: React.DragEvent | DragEvent): void => {
      event.preventDefault()
      setIsDragingOver(true)
      draggingEventCounter += 1
    }
    const handleDragLeave = (event: React.DragEvent | DragEvent): void => {
      event.preventDefault()
      draggingEventCounter -= 1
      if (draggingEventCounter <= 0) {
        setIsDragingOver(false)
        draggingEventCounter = 0
      }
    }
    const preventDragAndDropNavigation = (event: React.DragEvent | DragEvent): void => {
      event.preventDefault()
    }

    window.addEventListener('dragover', preventDragAndDropNavigation, {})
    window.addEventListener('drop', handleDrop, {})
    window.addEventListener('dragenter', handleDragEnter, {})
    window.addEventListener('dragleave', handleDragLeave, {})
    window.addEventListener('dragstart', handleDragStart, {})
    window.addEventListener('dragend', handleDragEnd, {})
    return () => {
      window.removeEventListener('dragover', preventDragAndDropNavigation, {})
      window.removeEventListener('drop', handleDrop, {})
      window.removeEventListener('dragenter', handleDragEnter, {})
      window.removeEventListener('dragleave', handleDragLeave, {})
      window.removeEventListener('dragstart', handleDragStart, {})
      window.removeEventListener('dragend', handleDragEnd, {})
    }
  }, [forceDisable])

  return <RcUpload
    className={clsx(styles.dragger, className)}
    disabled={isDisabled}
    multiple={true}
    accept={accept}
    name={name}
    openFileDialogOnClick={false}
    action={action}
    method={method}
    onSuccess={handleSuccessUpload}
    onStart={handleStartUpload}
    onProgress={handleProgressUpload}
    onError={handleErrorUpload}
    onBatchStart={handleBatchStart}
  >
    <div className={clsx(styles.dropZoneTextHelper, { [styles.isDragingOver]: isDragingOver && !isDisabled })}>
      {dropZoneText}
    </div>
    {children}
  </RcUpload>
}
