import { createRoot } from 'react-dom/client'
import Index from '@app/index'
import { BrowserRouter } from 'react-router-dom'

// TODO: Put thee app into strict mode and fix the errors
// <React.StrictMode>
// </React.StrictMode>

const container = document.getElementById('root')
if (container === null) throw new Error('No root element found')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Index />
  </BrowserRouter>,
)
