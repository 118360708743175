import * as React from 'react'
import styles from '../styles/editor-page.module.scss'
import { RFPage, RFHeader, RFContent } from '@components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import {
  useFeatureFlags,
  useProject,
  useProjectClasses,
  useProjectImages,
  userOnboardingTasksFetcher,
} from '@app/api/hooks'
import { preload } from 'swr'
import { WorkflowSteps } from '@components/workflow-steps'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import { EditorLeftPanel } from '@app/pages/editor-page/left-panel'
import {
  KeyboardShortcutsModal,
  useListenToKeyboardBindings,
} from '@components/modals/keyboard-shortcuts-modal'
import { CreateProjectModal } from '@components/modals/create-project-modal'
import { EVENTS_ID, ONBOARDING_TOUR_CONTEXT, TUTORIAL_ENABLED } from '@app/constants'
import { EditorRightPanel } from '@app/pages/editor-page/right-panel'
import { AlgorithmStatusMessages } from '@app/pages/editor-page/algorithm-messages'
import { ProjectDatasetContext } from '@app/api/openapi'
import { useImageNavigationEventHandler } from '@app/hooks/event-handlers/image-navigation'
import { NotAuthorized } from '@app/pages/not-authorized'
import { ErrorPage } from '@app/pages/error-page'
import { ClemexMosaicCanvasViewer } from '@components/canvas/clemex-mosaic-canvas'
import { UploadFirstImagesOfProject } from '@app/pages/editor-page/upload-first-images'
import { useBindExportProjectShortcut } from '@app/hooks/editor-shortcut'
import { MenuContext } from '@components/layout/navigation'
import { EditorStatusBar } from '@app/pages/editor-page/status-bar'
import { ProjectSettingsModal, ProjectSettingsPageContext } from '@components/modals/project-settings/project-settings-modal'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { AnnotationEditorTour } from '@components/tutorial/annotation-editor-tour'
import { ValidationTour } from '@components/tutorial/validation-editor-tour'
import { GlobalSettingsModal } from '@components/modals/global-settings/global-settings-modal'
import { ProjectSharingModal } from '@components/modals/project-sharing-modal'
import * as RFAPI from '@app/api/api'
import { notification } from 'antd'

interface EditorPageProps {
  projectSlug: string
  currentWorkflowStep: number
}

export const EditorPage: React.FC<EditorPageProps> = (props) => {
  const { projectSlug, currentWorkflowStep } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const context =
    currentWorkflowStep === 0
      ? ProjectDatasetContext.Training
      : ProjectDatasetContext.Validation
  const { data: project, isLoading: isProjectLoading, error: projectLoadingError } = useProject(projectSlug)
  const { data: images, isLoading: isImagesLoading } = useProjectImages(project?.id, context)
  const { data: flags, isLoading: isFeatureFlagLoading } = useFeatureFlags()
  const { isLoading: isProjectClassesLoading } = useProjectClasses(project?.id)
  const [showShareProject, setShowShareProject] = React.useState(false)
  const [projectSharingUrl, setProjectSharingUrl] = React.useState('')
  const [notificationApi, notificationContext] = notification.useNotification()
  const onboardingTourContext: ONBOARDING_TOUR_CONTEXT = (context === ProjectDatasetContext.Training) ? ONBOARDING_TOUR_CONTEXT.ANNOTATION : ONBOARDING_TOUR_CONTEXT.VALIDATION

  // Preload data
  React.useEffect(() => {
    void preload(CACHE_KEYS.USER_ONBOARDING_TASKS, userOnboardingTasksFetcher)
  }, [])

  const openProjectSharingModal = React.useCallback(async (): Promise<void> => {
    if (project?.id !== undefined) {
      let sharingToken
      try {
        sharingToken = (await RFAPI.requestProjectSharingToken(project.id)).sharingToken
      } catch (err) {
        notificationApi.error({
          message: <FormattedMessage
            id='project.sharing.link.generate.error.description'
            defaultMessage='There was an error generating the project sharing link. Please try again'/>,
        })
      }
      setProjectSharingUrl(window.origin + WebUIRoutes.projectList().path + '?sharedProjectToken=' + sharingToken)
      setShowShareProject(true)
    }
  }, [project?.id, notificationApi])

  React.useEffect(() => {
    window.addEventListener(EVENTS_ID.PROJECT_SHARE_MODAL, openProjectSharingModal)
    return () => {
      window.removeEventListener(
        EVENTS_ID.PROJECT_SHARE_MODAL,
        openProjectSharingModal,
      )
    }
  }, [openProjectSharingModal])

  useBindExportProjectShortcut(project?.id)

  const handleWorkflowChange = React.useCallback(
    (workflowStepId: 0 | 1): void => {
      if (workflowStepId === 0) {
        navigate(WebUIRoutes.annotateProject(projectSlug).path)
      } else if (workflowStepId === 1) {
        navigate(WebUIRoutes.validateProject(projectSlug).path)
      }
    },
    [navigate, projectSlug],
  )

  useImageNavigationEventHandler(project?.id, context)

  useListenToKeyboardBindings()

  // Bind PROJECT_SLUG_CHANGED event
  React.useEffect(() => {
    const onProjectSlugChange = (event: Event): void => {
      const customEvent = event as CustomEvent<{ slug: string }>
      const route = currentWorkflowStep === 0
        ? WebUIRoutes.annotateProject(customEvent.detail.slug)
        : WebUIRoutes.validateProject(customEvent.detail.slug)
      navigate(route.path, { replace: true })
    }
    window.addEventListener(EVENTS_ID.PROJECT_SLUG_CHANGED, onProjectSlugChange)
    return () => {
      window.removeEventListener(EVENTS_ID.PROJECT_SLUG_CHANGED, onProjectSlugChange)
    }
  }, [currentWorkflowStep, navigate])

  if (projectLoadingError !== undefined) {
    if (projectLoadingError.response?.status === 403) {
      return <NotAuthorized />
    } else {
      return <ErrorPage />
    }
  }

  return (
    <RFPage
      title={intl.formatMessage(
        {
          id: 'page.title.editor',
          defaultMessage: '{project}',
        },
        {
          project: project?.name,
        },
      )}
    >
      <RFHeader
        showProfileMenu={!(flags?.enableLocalUser ?? false)}
        breadcrumbItem={project?.name}
        navigationContext={MenuContext.EDITOR}
        onboardingTourContext={onboardingTourContext}
      >
        <WorkflowSteps
          current={currentWorkflowStep}
          onChange={handleWorkflowChange}
        />
      </RFHeader>
      <RFContent
        isLoading={isImagesLoading || isProjectLoading || isFeatureFlagLoading || isProjectClassesLoading}
      >
        {TUTORIAL_ENABLED && context === ProjectDatasetContext.Training && <AnnotationEditorTour onboardingTourContext={onboardingTourContext} />}
        {TUTORIAL_ENABLED && context === ProjectDatasetContext.Validation && <ValidationTour onboardingTourContext={onboardingTourContext} />}
        <AlgorithmStatusMessages projectId={project?.id} />
        <GlobalSettingsModal />
        <CreateProjectModal />
        <ProjectSettingsModal projectId={project?.id} pageContext={ProjectSettingsPageContext.PROJECT_EDITOR} />
        <KeyboardShortcutsModal />
        <ProjectSharingModal isOpen={showShareProject} onClose={() => { setShowShareProject(false) }} sharingUrl={projectSharingUrl} projectName={project?.name ?? ''} />
        { notificationContext }
        <div className={styles.content}>
          <div className={clsx(styles.panel, styles.leftPanel)}>
            {project !== undefined && (
              <EditorLeftPanel
                projectId={project.id}
                projectSlug={project.slug}
                context={context}
              />
            )}
          </div>
          <div className={styles.canvas}>
            {(images !== undefined) && images.length === 0 && (
              <UploadFirstImagesOfProject
                projectId={project?.id}
                context={context}
              />
            )}
            {project !== undefined && images !== undefined && images.length !== 0 && (
              <ClemexMosaicCanvasViewer
                projectId={project.id}
                context={context}
              />
            )}
            <EditorStatusBar projectId={project?.id} context={context} />
          </div>
          <div className={clsx(styles.panel, styles.rightPanel)}>
            {project !== undefined && (
              <EditorRightPanel projectId={project.id} context={context} />
            )}
          </div>
        </div>
      </RFContent>
    </RFPage>
  )
}
