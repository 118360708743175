import * as React from 'react'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-tools.module.scss'
import { IconMenu } from '@components/common/icon-menu'
import { EVENTS_ID, Tool } from '@app/constants'
import { useFeatureFlags } from '@app/api/hooks'
import PolygonIcon from '@material-design-icons/svg/outlined/hexagon.svg'
import ArrowIcon from '@material-design-icons/svg/filled/call_made.svg'
import LineIcon from '@material-design-icons/svg/outlined/show_chart.svg'
import RectangleIcon from '@material-design-icons/svg/outlined/rectangle.svg'
import EllipseIcon from '@material-design-icons/svg/outlined/circle.svg'
import Texticon from '@material-design-icons/svg/outlined/title.svg'
import { useSelectedTool } from '@app/hooks/editor'
import { useEditorStore } from '@app/stores/editor'

const { Title } = Typography

export const MetadataAnnotationTools: React.FC = () => {
  const { data: featureFlags } = useFeatureFlags()
  const [selectedTool] = useSelectedTool()
  const disabledTools = useEditorStore((store) => store.disabledTools)

  const lengthAnnotationToolItem = React.useMemo(() => {
    const annotationArrowTool = {
      icon: <ArrowIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_ARROW,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_ARROW),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ARROW_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.arrow" defaultMessage={'Arrow'} />,
    }

    const annotationLineTool = {
      icon: <LineIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_LINE,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_LINE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_LINE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.line" defaultMessage={'Line'} />,
    }

    const annotationTextTool = {
      icon: <Texticon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_TEXT,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_TEXT),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_TEXT_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.text" defaultMessage={'Text'} />,
    }

    const tools = [annotationArrowTool, annotationLineTool, annotationTextTool]
    return tools
  }, [selectedTool, disabledTools])

  const areaAnnotationToolItem = React.useMemo(() => {
    const annotationPolygonTool = {
      icon: <PolygonIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_POLYGON,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_POLYGON),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_POLYGON_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.polygon" defaultMessage={'Polygon'} />,
    }
    const annotationRectangleTool = {
      icon: <RectangleIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_RECTANGLE,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_RECTANGLE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_RECTANGLE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.rectangle" defaultMessage={'Rectangle'} />,
    }
    const annotationEllipseTool = {
      icon: <EllipseIcon className={styles.tooltipIcon} />,
      selected: selectedTool === Tool.METADATA_ANNOTATION_ELLIPSE,
      disabled: disabledTools.includes(Tool.METADATA_ANNOTATION_ELLIPSE),
      action: () => {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TOOL_METADATA_ANNOTATION_ELLIPSE_SELECT))
      },
      helpTitle: <FormattedMessage id="tools.metadata-annotation.ellipse" defaultMessage={'Ellipse. While drawing keep shift for circle.'} />,
    }

    const tools = []
    if (featureFlags?.enableDirectMeasureExperimentation === true) {
      tools.push(annotationPolygonTool, annotationRectangleTool, annotationEllipseTool)
    }
    return tools
  }, [selectedTool, disabledTools, featureFlags?.enableDirectMeasureExperimentation])

  return <div id='tools-annotation' className={styles.editorTools}>
    <Title level={5} className={styles.titleSection}>
      <FormattedMessage id="tools.metadata-annotation.title" defaultMessage={'Documentation'} />
    </Title>
    <div className={styles.container}>
      <div className={styles.subSection}>
        <div>
          <IconMenu
            items={lengthAnnotationToolItem}
          />
        </div>
        <div>
          <IconMenu
            items={areaAnnotationToolItem}
          />
        </div>
      </div>
    </div>
  </div>
}
