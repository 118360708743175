import * as React from 'react'
import { type ProjectDatasetContext } from '@app/api/openapi'
import { useGlobalPredictionFullOpacity, useGlobalPredictionOpacity, useGlobalPredictionVisibility } from '@app/hooks/editor'
import styles from '../styles/editor-page.module.scss'
import { Flex, InputNumber, Tooltip, Typography } from 'antd'
import { MadeWithLove } from '@components/common/made-with-love'
import { useStageZoom } from '@app/hooks/stage'
import { FormattedMessage, useIntl } from 'react-intl'
import { useProjectTrainingSnapshot, useSelectedImage } from '@app/api/hooks'
import { isEmpty, isFinite } from 'lodash'
import { PredictionParameters } from '@components/panel/panel-elements/prediction-parameters'
const { Text, Title } = Typography

interface EditorStatusBarProps {
  projectId?: string
  context: ProjectDatasetContext
}

const CurrentZoom: React.FC = () => {
  const stageZoom = useStageZoom()
  const zoomLevel = stageZoom.zoom ?? 1
  return <Flex vertical className={styles.zoom}>
    <Title level={5} className={styles.title}>
      <FormattedMessage id="editor.status.zoom" defaultMessage={'Zoom'} />
    </Title>
    <Text ellipsis className={styles.text}>{Math.round(zoomLevel * 100)} %</Text>
  </Flex>
}

const PixelSize: React.FC<{ projectId?: string }> = ({ projectId }) => {
  const [pixelSizeValue, setPixelSizeValue] = React.useState<string | undefined>(undefined)
  const [validationStatus, setValidationStatus] = React.useState<'error' | undefined>(undefined)
  const selectedImage = useSelectedImage(projectId)
  const intl = useIntl()
  const type = 'um'
  const units = {
    um: <FormattedMessage id="editor.status.pixelSize.unit.um-per-px" defaultMessage={'µm/px'} />,
  }
  const defaultInput = intl.formatMessage({ id: 'editor.status.pixelSize.input.placeholder_empty', defaultMessage: 'N/A' })

  const validateAndSave = (): void => {
    // Unary plus operator to convert to number
    // it will return NaN if the input is not a number
    // instead of trying to convert it ex: 1.1a2 = NaN instead of 1.1
    const pixelSizeValueAsFloat = +(pixelSizeValue ?? 'NaN')
    const isDifferentFromLastSavedValue = pixelSizeValue !== selectedImage.data?.pixelSizeUm
    const isEmptyInput = isEmpty(pixelSizeValue)
    const isDifferentThan0 = pixelSizeValueAsFloat !== 0
    const isBiggerThan0 = pixelSizeValueAsFloat > 0
    const isValid = (isFinite(pixelSizeValueAsFloat) && isDifferentThan0 && isBiggerThan0) || isEmptyInput

    setValidationStatus(isValid ? undefined : 'error')

    if (isValid && isDifferentFromLastSavedValue) {
      void selectedImage.mutate(isEmptyInput ? undefined : pixelSizeValueAsFloat)
    }
  }
  const handleChange = (value: string): void => {
    // I am using the input value directly because and.d does not return
    // invalid input or empty. It returns undefined which is not enough to determine
    // The difference between an empty input (Removal of the pixelSize) and an invalid input.
    setPixelSizeValue(value)
  }

  // The useEffect is required, because we want the component up update
  // when the selected image changes. Otherwise, the input value will not.
  React.useEffect(() => {
    setPixelSizeValue(selectedImage.data?.pixelSizeUm?.toString() ?? undefined)
  }, [setPixelSizeValue, selectedImage.data?.pixelSizeUm, selectedImage.data?.id])

  React.useEffect(() => {
    setValidationStatus(undefined)
  }, [selectedImage.data?.id])

  return <Flex vertical className={styles.pixelSize}>
    <Title level={5} className={styles.title}>
      <FormattedMessage id="editor.status.pixelSize" defaultMessage={'Pixel Size'} />
    </Title>
    <Tooltip
      trigger={[]}
      open={validationStatus === 'error'}
      title={
        <FormattedMessage id={'editor.status.pixelSize.error'} defaultMessage={'Please input a valid pixel size. No text allowed. The pixel size must be bigger than 0.'} />
      }
    >
      <InputNumber
        disabled={selectedImage.data === undefined}
        className={styles.pixelSizeInput}
        value={pixelSizeValue}
        precision={4}
        stringMode
        status={validationStatus}
        addonAfter={units[type]}
        placeholder={defaultInput}
        controls={false}
        keyboard={false}
        onPressEnter={validateAndSave}
        onBlur={validateAndSave}
        onInput={handleChange}
      />
    </Tooltip>
  </Flex>
}

const Spacer = (): JSX.Element => <div className={styles.spacer}>&nbsp;</div>

export const EditorStatusBar: React.FC<EditorStatusBarProps> = ({ projectId }) => {
  const [globalPredictionOpacity, setGlobalPredictionOpacity] = useGlobalPredictionOpacity()
  const [globalPredictionVisibility, setGlobalPredictionVisibility] = useGlobalPredictionVisibility()
  const [globalPredictionFullOpacity, setGlobalPredictionFullOpacity] = useGlobalPredictionFullOpacity()
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  return <>
    <Flex className={styles.statusBar}>
      <CurrentZoom />
      <PixelSize projectId={projectId} />
      <Spacer/>
      <MadeWithLove className={styles.madeWithLove} />
      <PredictionParameters
        className={styles.predictionOpacity}
        disabled={projectTrainingSnapshot === undefined}
        globalPredictionOpacity={globalPredictionOpacity}
        onChangeGlobalPredictionOpacity={setGlobalPredictionOpacity}
        globalPredictionVisibility={globalPredictionVisibility}
        onToggleGlobalPredictionVisibility={() => { setGlobalPredictionVisibility(!globalPredictionVisibility) }}
        onToggleGlobalPredictionFullOpacity={() => { setGlobalPredictionFullOpacity(!globalPredictionFullOpacity) }}
      />
    </Flex>
  </>
}
