import { Flex, Switch, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/selected-annotation-arrow-parameters.module.scss'
import { EVENTS_ID } from '@app/constants'
import { type ArrowGeometryProperties } from '@app/api/openapi'

import { useEditorStore } from '@app/stores/editor'
import { FEATURE_TYPE, FeatureType } from '@clemex/mosaic-canvas'

const { Title } = Typography

export const SelectedAnnotationArrowParameters: React.FC = () => {
  const selectedItems = useEditorStore((store) => store.selectedItems)
  const selectedAnnotationArrow = selectedItems.find((item) => item.get(FEATURE_TYPE) === FeatureType.METADATA_ANNOTATION_ARROW)

  const {
    showRightArrow,
    showLeftArrow,
  } = selectedAnnotationArrow?.getGeometry()?.getProperties() as ArrowGeometryProperties

  const onToggleShowRightArrow = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, {
      detail: {
        showRightArrow: !(showRightArrow as boolean),
        showLeftArrow,
        geometryType: 'ARROW',
      } satisfies ArrowGeometryProperties,
    }))
  }

  const onToggleShowLeftArrow = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, {
      detail: {
        showRightArrow,
        showLeftArrow: !(showLeftArrow as boolean),
        geometryType: 'ARROW',
      } satisfies ArrowGeometryProperties,
    }))
  }

  return <div className={styles.root}>
    <Title level={5} className={styles.title}>
      <FormattedMessage id="selected.annotation.arrow.parameters.title" defaultMessage={'Selected Annotation Arrow'} />
    </Title>
    <div className={styles.content}>
      <Flex justify='space-between' align='center' flex={1}>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id="selected.annotation.arrow.parameters.showRightArrow" defaultMessage={'Show Right Arrow'} />
        </Title>
        <Switch checked={showRightArrow} onClick={onToggleShowRightArrow}/>
      </Flex>
      <Flex justify='space-between' align='center' flex={1}>
        <Title level={5} className={styles.settingsItemTitle}>
          <FormattedMessage id="selected.annotation.arrow.parameters.showLeftArrow" defaultMessage={'Show Left Arrow'} />
        </Title>
        <Switch checked={showLeftArrow} onClick={onToggleShowLeftArrow}/>
      </Flex>
    </div>
  </div>
}

export default SelectedAnnotationArrowParameters
