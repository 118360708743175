import { type ProjectDatasetContext } from '@app/api/openapi'

export interface CacheKey<D> {
  keyId: string
  url: string
  data: D
}

export const CACHE_KEYS = {
  PROJECT_LIST: '/api/project-list/',
  ENVIRONMENT: '/api/environment/',
  FEATURE_FLAGS: '/api/feature-flags/',
  USER_PROFILE: '/api/user/profile/',
  USER_SETTINGS: (): CacheKey<Record<string, unknown>> => ({
    keyId: CACHE_KEYS.USER_SETTINGS.name,
    url: '/api/user/settings',
    data: {},
  }),
  USER_ONBOARDING_TASKS: (onboardingTourContext: string) => `/api/onboarding/tasks/${onboardingTourContext}/`,
  DEMO_IMAGES: '/api/demo-images/',
  IMAGE_PROJECT_INFO: (projectId?: string, imageId?: string) => {
    if (projectId === undefined || imageId === undefined) {
      return null
    }
    return {
      url: `/api/images/${imageId}/${projectId}/info`,
      data: {
        projectId,
        imageId,
      },
    }
  },
  IMAGE_PREDICTION_MASKS_STATUS: (projectId: string | undefined, imageId: string | undefined, projectTrainingSnapshotId: string | undefined) => {
    if (projectId === undefined || imageId === undefined || projectTrainingSnapshotId === undefined) {
      return null
    }
    return {
      url: `/api/project/${projectId}/images/${imageId}/prediction_masks/${projectTrainingSnapshotId}/status`,
      params: {
        projectId,
        imageId,
        projectTrainingSnapshotId,
      },
    }
  },
  PROJECT_DATASET_PREDICTION_MASKS_STATUS: (projectId: string | undefined, context: ProjectDatasetContext, projectTrainingSnapshotId: string | undefined) => {
    if (projectId === undefined || projectTrainingSnapshotId === undefined) {
      return null
    }
    return {
      url: `/api/project/${projectId}/dataset/${context}/prediction_masks/${projectTrainingSnapshotId}/status`,
      params: {
        projectId,
        context,
        projectTrainingSnapshotId,
      },
    }
  },
  PREDICT: (imageId: string) => `/api/predict/image/${imageId}/`,
  PROJECT_IMAGES: (projectId: string, context: ProjectDatasetContext) =>
    `/api/project/${projectId}/${context}/images/`,
  PROJECT: (slug: string) => `/api/project/${slug}/`,
  PROJECT_FROM_ID: (projectId: string | undefined) => {
    if (projectId === undefined) {
      return null
    }
    return {
      url: `/api/project/${projectId}/`,
      params: {
        projectId,
      },
    }
  },
  PROJECT_SESSION: (projectId: string, context: ProjectDatasetContext) => `/api/project-session/${projectId}/${context}`,
  PROJECT_SESSION_WITH_ANNOTATION_MASKS: (projectId: string, context: ProjectDatasetContext) => {
    return {
      url: `/api/project-session/${projectId}/${context}`,
    }
  },
  PROJECT_SETTINGS: (projectId: string | undefined) => {
    if (projectId === undefined) {
      return null
    }
    return {
      url: `/api/project/${projectId}/settings`,
      params: {
        projectId,
      },
    }
  },
  PROJECT_TRAINING_SNAPSHOT: (projectId: string | undefined) => {
    if (projectId === undefined) {
      return null
    }
    return {
      url: `/api/project/${projectId}/training_snapshot`,
      params: {
        projectId,
      },
    }
  },
  PROJECT_CLASSES: (projectId: string | undefined): CacheKey<{ projectId: string }> | null => {
    if (projectId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.PROJECT_CLASSES.name,
      url: `/api/project/${projectId}/class`,
      data: {
        projectId,
      },
    }
  },
  PROJECT_IMAGE_CLASS_ANNOTATIONS: (projectId: string | undefined, imageId: string | undefined): CacheKey<{ projectId: string, imageId: string }> | null => {
    if (projectId === undefined || imageId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.PROJECT_IMAGE_CLASS_ANNOTATIONS.name,
      url: `/api/project/${projectId}/images/${imageId}/annotations`,
      data: {
        projectId,
        imageId,
      },
    }
  },
  PROJECT_IMAGE_DIRECT_MEASURE: (projectId: string | undefined, imageId: string | undefined): CacheKey<{ projectId: string, imageId: string }> | null => {
    if (projectId === undefined || imageId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.PROJECT_IMAGE_DIRECT_MEASURE.name,
      url: `/api/project/${projectId}/images/${imageId}/direct_measure`,
      data: {
        projectId,
        imageId,
      },
    }
  },
  METADATA_ANNOTATIONS: (projectId: string | undefined, imageId: string | undefined): CacheKey<{ projectId: string, imageId: string }> | null => {
    if (projectId === undefined || imageId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.METADATA_ANNOTATIONS.name,
      url: `/api/project/${projectId}/images/${imageId}/metadata-annotation`,
      data: {
        projectId,
        imageId,
      },
    }
  },
  PROJECT_TRAINING_STATE: (projectId: string | undefined): CacheKey<{ projectId: string }> | null => {
    if (projectId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.PROJECT_TRAINING_STATE.name,
      url: `/api/project/${projectId}/training_state`,
      data: {
        projectId,
      },
    }
  },
  ALGORITHM_READY: (projectId: string): string =>
    `/api/project/${projectId}/algorithm-ready/`,
  PREPARE_ALGORITHM: (projectId: string): string =>
    `/api/project/${projectId}/prepare-algorithm/`,
  TRAINING_PARAMETERS: (projectId: string): string =>
    `/api/project/${projectId}/training-parameters/`,
  PROJECT_BUNDLE: (projectId: string) => `/api/project-bundle/${projectId}/`,
  LICENCE: '/api/licence',
  PROJECT_SHARE_DETAILS: (sharedProjectToken: string) => `/api/project/${sharedProjectToken}/share-details`,
  PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION: (projectId: string | undefined, imageId: string | undefined): CacheKey<{ projectId: string, imageId: string }> | null => {
    if (projectId === undefined || imageId === undefined) {
      return null
    }
    return {
      keyId: CACHE_KEYS.PROJECT_IMAGE_ANNOTATION_CLASSES_DISTRIBUTION.name,
      url: `/api/project/${projectId}/images/${imageId}/classes_metrics`,
      data: {
        projectId,
        imageId,
      },
    }
  },
}
