import { AngleUnitValues, type Color } from '@clemex/mosaic-canvas'
import { ColorPicker, type ColorPickerProps, Typography, Flex, Select, Radio, type RadioChangeEvent, InputNumber, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/direct-measure-settings.module.scss'
import { useProjectSettingsDirectMeasure } from '@app/api/hooks'
import { FontWeight } from '@app/api/openapi'
import { VPE_COLORS_PALLETTE } from '@app/constants'
import { TextPositionToShapeValues } from '@clemex/mosaic-canvas'

const { Title } = Typography

const colorToRGBString = (color: Color | string): string => {
  if (typeof color === 'string') {
    return color
  }
  const [r, g, b] = color
  return `rgb(${r},${g},${b})`
}

const MODERN_COLOR_PALETTE = [
  '#1F78B4',
  '#E31A1C',
  '#33A02C',
  '#FF7F00',
  '#6A3D9A',
  '#B15928',
  '#A6CEE3',
  '#FB9A99',
  '#B2DF8A',
  '#FDBF6F',
  '#CAB2D6',
  '#FFFF99',
  '#DB32B2',
  '#44E9F2',
  '#FFF000',
  '#000FFF',
]

const AVAILABLE_FONTS_FAMILY = [
  ...new Set([...document.fonts].map((font) => font.family)),
  // Adding Generic font family
  // https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/
  'sans-serif',
  'serif',
  'cursive',
  'fantasy',
  'monospace',
  'system-ui',
  'emoji',
  'math',
  'fangsong',
  'ui-serif',
  'ui-sans-serif',
  'ui-monospace',
  'ui-rounded',
]

const COLOR_PRESETS = [
  {
    label: <FormattedMessage id="color-palette.preset.recommended.modern.label" defaultMessage={'Modern'} />,
    colors: MODERN_COLOR_PALETTE,
  },
  {
    label: <FormattedMessage id="color-palette.preset.recommended.vision.label" defaultMessage={'Vision'} />,
    colors: VPE_COLORS_PALLETTE,
  },
]

interface DirectMeasureSettingsProps {
  projectId: string
}
export const DirectMeasureSettings: React.FC<DirectMeasureSettingsProps> = ({ projectId }) => {
  const { data: projectSettingsMeasurementData, isLoading, mutateDirectMeasureSettings: mutateMeasurementSettings, reset } = useProjectSettingsDirectMeasure(projectId)

  const onChangeMainColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      mainColor: value.toRgbString(),
    })
  }

  const onChangeMeasuringLineColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      measuringLineColor: value.toRgbString(),
    })
  }

  const onChangeHinterDotColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      measuringPointColor: value.toRgbString(),
    })
  }

  const onChangeFontFamily = (value: string): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontFamily: value,
    })
  }

  const onChangeFontSize = (value: number | null): void => {
    if (value === null) {
      return
    }
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontSize: value,
    })
  }

  const onChangeFontWeight = (value: FontWeight | null): void => {
    if (value === null) {
      return
    }
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontWeight: value,
    })
  }
  const onChangeFontColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontColor: value.toRgbString(),
    })
  }

  const onChangeFontOutlineColor = (value: Parameters<NonNullable<ColorPickerProps['onChange']>>[0]): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      fontOutlineColor: value.toRgbString(),
    })
  }

  const onChangeAngleUnit = (event: RadioChangeEvent): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      angleUnit: event.target.value,
    })
  }

  const onChangeGeometryMeasurementTextPosition = (event: RadioChangeEvent): void => {
    if (projectSettingsMeasurementData === undefined) {
      return
    }
    void mutateMeasurementSettings({
      ...projectSettingsMeasurementData,
      directMeasureTextPosition: event.target.value,
    })
  }

  if (isLoading || projectSettingsMeasurementData === undefined) {
    return <FormattedMessage id="project-settings.modal.tab.measurement.loading" defaultMessage={'Loading...'} />
  }

  return <Flex vertical flex={1} className={styles.root}>
    <Flex vertical flex={1}>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="project-settings.modal.tab.measurement.common" defaultMessage={'Common Measurement Style'} />
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.main-color" defaultMessage={'Main Color'} />
          </Title>
          <ColorPicker
            disabledAlpha={false}
            allowClear={true}
            value={colorToRGBString(projectSettingsMeasurementData.mainColor)}
            onChangeComplete={onChangeMainColor}
            presets={COLOR_PRESETS}
          />
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.measuring-line-color.label" defaultMessage={'Measuring Line Color'} />
          </Title>
          <ColorPicker
            disabledAlpha={false}
            allowClear={true}
            value={colorToRGBString(projectSettingsMeasurementData.measuringLineColor)}
            onChangeComplete={onChangeMeasuringLineColor}
            presets={COLOR_PRESETS}
          />
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.measuring-dot-color.label" defaultMessage={'Measuring Dot Color'} />
          </Title>
          <ColorPicker
            disabledAlpha={false}
            allowClear={true}
            value={colorToRGBString(projectSettingsMeasurementData.measuringPointColor)}
            onChangeComplete={onChangeHinterDotColor}
            presets={COLOR_PRESETS}
          />
        </Flex>

        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.font.label" defaultMessage={'Font'} />
          </Title>
          <Flex gap={16}>
            <Select className={styles.selectFontWeight} value={projectSettingsMeasurementData.fontWeight} options={Object.values(FontWeight).map((fontWeight) => ({ value: fontWeight, label: `${fontWeight}` }))} onChange={onChangeFontWeight}/>
            <Select className={styles.selectFontFamily} value={projectSettingsMeasurementData.fontFamily} options={AVAILABLE_FONTS_FAMILY.map((fontFamily) => ({ value: fontFamily, label: fontFamily }))} onChange={onChangeFontFamily}/>
            <InputNumber className={styles.selectFontSize} min={8} value={projectSettingsMeasurementData.fontSize} onChange={onChangeFontSize} addonAfter={'px'}/>
          </Flex>
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.font.color.label" defaultMessage={'Font color'} />
          </Title>
          <ColorPicker
            disabledAlpha={false}
            allowClear={true}
            value={colorToRGBString(projectSettingsMeasurementData.fontColor)}
            onChangeComplete={onChangeFontColor}
            presets={COLOR_PRESETS}
          />
        </Flex>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.font.outline-color.label" defaultMessage={'Font Outline Color'} />
          </Title>
          <ColorPicker
            disabledAlpha={false}
            allowClear={true}
            value={colorToRGBString(projectSettingsMeasurementData.fontOutlineColor)}
            onChangeComplete={onChangeFontOutlineColor}
            presets={COLOR_PRESETS}
          />
        </Flex>
      </Flex>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="project-settings.modal.tab.measurement.angle.title" defaultMessage={'Angle Tool'} />
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.label" defaultMessage={'Unit'} />
          </Title>
          <Radio.Group value={projectSettingsMeasurementData.angleUnit} onChange={onChangeAngleUnit}>
            <Radio.Button value={AngleUnitValues.DEGREE}>
              <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.inner" defaultMessage={'Degree'} />
            </Radio.Button>
            <Radio.Button value={AngleUnitValues.RADIAN}>
              <FormattedMessage id="project-settings.modal.tab.measurement.angle.unit.outer" defaultMessage={'Radian'} />
            </Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="project-settings.modal.tab.measurement.geometry.title" defaultMessage={'Geometry Tool'} />
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center' >
          <Title level={5} className={styles.settingsItemTitle}>
            <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.label" defaultMessage={'Measurement Text Position'} />
          </Title>
          <Radio.Group value={projectSettingsMeasurementData.directMeasureTextPosition} onChange={onChangeGeometryMeasurementTextPosition}>
            <Radio.Button value={TextPositionToShapeValues.TOP}>
              <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.top" defaultMessage={'Top'} />
            </Radio.Button>
            <Radio.Button value={TextPositionToShapeValues.RIGHT}>
              <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.right" defaultMessage={'Right'} />
            </Radio.Button>
            <Radio.Button value={TextPositionToShapeValues.BOTTOM}>
              <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.bottom" defaultMessage={'Bottom'} />
            </Radio.Button>
            <Radio.Button value={TextPositionToShapeValues.LEFT}>
              <FormattedMessage id="project-settings.modal.tab.measurement.geometry.measurement-text-position.left" defaultMessage={'Left'} />
            </Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>
    </Flex>
    <Flex justify='flex-end' align='center' className={styles.actionsContainer}>
      <Button type='primary' onClick={reset}>
        <FormattedMessage id="project-settings.modal.tab.measurement.reset" defaultMessage={'Reset to default measurement settings'} />
      </Button>
    </Flex>
  </Flex>
}
