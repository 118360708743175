import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Typography, List } from 'antd'
import styles from './styles/keyboard-shortcuts-modal.module.scss'
import { EVENTS_ID, EVENTS_SHORTCUT } from '@app/constants'

const { Title, Text } = Typography

enum SHORTCUT_GROUPS_ID {
  VALIDATION,
  EDITOR,
  GENERAL,
  HELP,
}

interface GroupLabelMapping {
  id: SHORTCUT_GROUPS_ID
  label: React.ReactNode
  items: EVENTS_ID[]
}

const shortcutGroups: GroupLabelMapping [] = [
  {
    id: SHORTCUT_GROUPS_ID.GENERAL,
    label: <FormattedMessage id='shortcuts.group.general.label' defaultMessage='General'/>,
    items: [
      EVENTS_ID.NAVIGATE_PREVIOUS_IMAGE,
      EVENTS_ID.NAVIGATE_NEXT_IMAGE,
      EVENTS_ID.ZOOM_TO_FIT,
      EVENTS_ID.ZOOM_IN,
      EVENTS_ID.ZOOM_OUT,
      EVENTS_ID.EDITOR_DISABLE_DRAWING,
    ],
  },
  {
    id: SHORTCUT_GROUPS_ID.EDITOR,
    label: <FormattedMessage id='shortcuts.groups.editor.label' defaultMessage='Annotation Editor'/>,
    items: [
      EVENTS_ID.EDITOR_ERASER_DRAG,
      EVENTS_ID.EDITOR_ERASER_TOOL,
      EVENTS_ID.EDITOR_BRUSH_TOOL,
      EVENTS_ID.EDITOR_PAN_TOOL,
      EVENTS_ID.EDITOR_UNDO,
      EVENTS_ID.EDITOR_REDO,
    ],
  },
  {
    id: SHORTCUT_GROUPS_ID.HELP,
    label: <FormattedMessage id='shortcuts.groups.help.label' defaultMessage='Help'/>,
    items: [
      EVENTS_ID.SHORTCUTS_MODAL,
    ],
  },
]

export const KeyboardShortcutsModal: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)

  const title = <Title level={4}>
    <FormattedMessage id={'shortcuts.modal.title'} defaultMessage={'Shortcuts List'} />
  </Title>

  const onClose = React.useCallback((): void => {
    setIsVisible(false)
  }, [])

  const handleToggle = React.useCallback((): void => {
    setIsVisible(!isVisible)
  }, [isVisible])

  React.useEffect(() => {
    window.addEventListener(EVENTS_ID.SHORTCUTS_MODAL, handleToggle, {})
    return () => {
      window.removeEventListener(EVENTS_ID.SHORTCUTS_MODAL, handleToggle, {})
    }
  }, [handleToggle])

  useListenToKeyboardBindings()

  return <Modal
    width={900}
    title={title}
    open={isVisible}
    onCancel={onClose}
    footer={null}
  >
    <div className={styles.shortcutsList}>
      {shortcutGroups.map((group: GroupLabelMapping) => (
        <List
          key={group.id}
          dataSource={group.items}
          header={<Title level={5}>{group.label}</Title>}
          className={styles.section}
          renderItem={(eventId: EVENTS_ID) => {
            const shortcut = EVENTS_SHORTCUT[eventId]
            return (shortcut != null) && (
              <List.Item className={styles.spaced}>
                <Text >{shortcut.description}</Text>
                <Text keyboard className={styles.binding}>{shortcut.label}</Text>
              </List.Item>
            )
          }}
        />
      ))}
    </div>
  </Modal>
}

function isInputLike (element: HTMLElement): boolean {
  const tags = ['TEXTAREA', 'INPUT', 'BUTTON', 'SELECT']
  return tags.includes(element.tagName)
}

function isEditable (element: HTMLElement): boolean {
  const contentEditable = element.isContentEditable
  const designerMode = element.ownerDocument.designMode === 'on'
  return contentEditable || designerMode
}
function isValidForShortcut (element: HTMLElement): boolean {
  return !isInputLike(element) && !isEditable(element)
}

function keydownListener (event: KeyboardEvent): void {
  const eventId: EVENTS_ID | undefined = (Object.keys(EVENTS_SHORTCUT) as EVENTS_ID[]).find((eventId) => {
    const shortcut = EVENTS_SHORTCUT[eventId]
    if (
      shortcut === null ||
      ((event.target != null) &&
      event.target instanceof HTMLElement &&
      !isValidForShortcut(event.target))
    ) {
      return false
    }
    const altExistsAndMatch = shortcut?.usingAlt === undefined ? true : shortcut.usingAlt === event.altKey
    const shiftExistsAndMatch = shortcut?.usingShift === undefined ? true : shortcut.usingShift === event.shiftKey
    const ctrlExistsAndMatch = shortcut?.usingCtrl === undefined ? true : shortcut.usingCtrl === event.ctrlKey
    const metaExistsAndMatch = shortcut?.usingMeta === undefined ? true : shortcut.usingMeta === event.metaKey
    const keyMatch = shortcut.key.toLowerCase() === event.key.toLowerCase()
    return altExistsAndMatch && shiftExistsAndMatch && ctrlExistsAndMatch && metaExistsAndMatch && keyMatch
  })

  if (eventId !== undefined) {
    event.preventDefault()
    window.dispatchEvent(new CustomEvent(eventId))
  }
}

export const useListenToKeyboardBindings = (): void => {
  React.useEffect(() => {
    window.addEventListener('keydown', keydownListener, {})
    return () => {
      window.removeEventListener('keydown', keydownListener, {})
    }
  }, [])
}
