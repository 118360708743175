import { type LicenceInformation } from '@api/hooks'
import { type IntlShape } from 'react-intl'
import Icon from '@ant-design/icons'
import freeSvg from '@components/icons/free.licence.svg'
import premiumSvg from '@components/icons/premium.licence.svg'
import trialSvg from '@components/icons/trial.licence.svg'
import styles from './styles/licensing-tag-properties.module.scss'
import clsx from 'clsx'
import { MS_PER_DAY, LICENSING_WARNING_THRESHOLD } from '@app/constants'
interface LicensingTagProperties {
  icon: React.ReactNode
  className: string
  text: string
  expirationText: string
  expirationClassName: string
}
const freeIcon: React.ReactNode = <Icon component={freeSvg}/>
const trialIcon: React.ReactNode = <Icon component={trialSvg}/>
const premiumIcon: React.ReactNode = <Icon component={premiumSvg}/>
const getExpirationText = (isExpired: boolean, intl: IntlShape, expirationDate?: Date): string => {
  if (isExpired) {
    return intl.formatMessage(
      {
        id: 'licence.expired.since',
        defaultMessage: 'Expired since {expirationDate}',
      },
      {
        expirationDate: intl.formatDate(expirationDate, {
          day: 'numeric',
          year: 'numeric',
          month: 'numeric',
        },
        ),
      },
    )
  } else {
    return intl.formatMessage({
      id: 'licence.expiration.on',
      defaultMessage: 'Expires on {expirationDate}',
    },
    {
      expirationDate: intl.formatDate(expirationDate, {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
      },
      ),
    })
  }
}
export const isLicenceExpired = (expirationDate: Date): boolean => {
  return expirationDate < new Date()
}
export const isLicenceExpiringSoon = (expirationDate: Date, duration: number): boolean => {
  // getTime() returns ms value
  if (duration !== 0) {
    return Math.round((expirationDate.getTime() - new Date().getTime()) / MS_PER_DAY) / duration <= LICENSING_WARNING_THRESHOLD
  }
  return false
}

const premiumTagProperties = (licence: LicenceInformation, intl: IntlShape): LicensingTagProperties => {
  const isExpired = isLicenceExpired(licence.expirationDate)
  const isExpiringSoon = isLicenceExpiringSoon(licence.expirationDate, licence.duration)
  const expirationText = getExpirationText(isExpired, intl, licence?.expirationDate)
  return ({
    className: clsx({
      [styles.premium]: !isExpired && !isExpiringSoon,
      [styles.expiresSoon]: !isExpired && isExpiringSoon,
      [styles.expired]: isExpired,
    }),
    icon: premiumIcon,
    text: intl.formatMessage({
      id: 'licensing-tag.premium.title',
      defaultMessage: 'Premium',
    }),
    expirationText,
    expirationClassName: clsx({
      [styles.expirationPremium]: !isExpired && !isExpiringSoon,
      [styles.expirationSoon]: !isExpired && isExpiringSoon,
      [styles.expirationExpired]: isExpired,
    }),
  })
}
const freeTagProperties = (intl: IntlShape): LicensingTagProperties => ({
  className: styles.base,
  icon: freeIcon,
  text: intl.formatMessage({
    id: 'licensing-tag.free.title',
    defaultMessage: 'Free',
  }),
  expirationClassName: styles.expirationBase,
  expirationText: intl.formatMessage({
    id: 'licensing-tag.free.expiration-text',
    defaultMessage: 'No Licence',
  }),
})
const trialTagProperties = (licence: LicenceInformation, intl: IntlShape): LicensingTagProperties => {
  const isExpired = isLicenceExpired(licence.expirationDate)
  const isExpiringSoon = isLicenceExpiringSoon(licence.expirationDate, licence.duration)
  const expirationText = getExpirationText(isExpired, intl, licence?.expirationDate)
  return ({
    className: clsx({
      [styles.base]: !isExpired && !isExpiringSoon,
      [styles.expiresSoon]: !isExpired && isExpiringSoon,
      [styles.expired]: isExpired,
    }),
    icon: trialIcon,
    text: intl.formatMessage({
      id: 'licensing-tag.trial.title',
      defaultMessage: 'Trial',
    }),
    expirationText,
    expirationClassName: clsx({
      [styles.expirationBase]: !isExpired && !isExpiringSoon,
      [styles.expirationSoon]: !isExpired && isExpiringSoon,
      [styles.expirationExpired]: isExpired,
    }),
  })
}
export const getLicenceTagProperties = (intl: IntlShape, licence?: LicenceInformation): LicensingTagProperties => {
  return licence !== undefined
    ? licence.isPremium
      ? premiumTagProperties(licence, intl)
      : licence.isTrial
        ? trialTagProperties(licence, intl)
        : freeTagProperties(intl)
    : freeTagProperties(intl)
}
