import styles from './styles/clemex-mosaic-canvas-viewer.module.css'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ClemexMosaicCanvas, ClemexMosaicCanvasMode, ClemexMosaicCanvasListenersType, type ClassAnnotationProperties, FEATURE_TYPE, type ShapeStyle, DEFAULT_SHAPE_STYLE, FeatureType, type EllipseGeometryProperties, type CircleGeometryProperties } from '@clemex/mosaic-canvas'
import { useMosaicImage, useMosaicMasks } from '@app/hooks/clemex-mosaic-canvas-hook'
import { useBrushSize, useGlobalAnnotationOpacity, useGlobalPredictionOpacity, useGlobalPredictionVisibility, useHiddenAnnotationClassIndexes, useHiddenPredictionClassIndexes, useIsDirectMeasureAreaToolFreehand, useIsDirectMeasurePerimeterToolFreehand, useSelectedAnnotationClassColorIndex, useSelectedImageId, useSelectedTool } from '@app/hooks/editor'
import { EVENTS_ID, MAX_CLASS_COUNT, Tool } from '@app/constants'
import { useImageClassAnnotations, useProjectSettingsDirectMeasure, useSelectedImage, useUserSettingsCanvas } from '@app/api/hooks'
import GeoJSON from 'ol/format/GeoJSON'
import { Feature } from 'ol'
import { Polygon, type Geometry, LineString, Point } from 'ol/geom'
import { type DirectMeasure, ProjectDatasetContext, type ClassAnnotationGeoJSON, DirectMeasureType, type GeometryTypeEnum, type MetadataAnnotation, type ArrowGeometryProperties, MetadataAnnotationType } from '@app/api/openapi'
import { type FeatureCollection, type Polygon as GeoJSONPolygon, type Point as GeoJSONPoint, type LineString as GeoJSONLineString, type Feature as GeoJSONFeature } from 'geojson'
import { useDebouncedCallback } from 'use-debounce'
import { useStageStore } from '@app/stores/stage'
import { useEditorShortcutHook } from '@app/hooks/editor-shortcut'
import { type ChangePatch } from '@clemex/mosaic-canvas/dist/interactions/common'
import { useDirectMeasure } from '@app/api/direct-measure'
import { groupBy } from 'lodash'
import { useMetadataAnnotation } from '@app/api/metadata-annotation'
import { useEditorStore } from '@app/stores/editor'

const TOOL_MAPPING: { [key in Tool]: ClemexMosaicCanvasMode } = {
  [Tool.SELECTION]: ClemexMosaicCanvasMode.SELECT,
  [Tool.BRUSH]: ClemexMosaicCanvasMode.BRUSH,
  [Tool.ERASER]: ClemexMosaicCanvasMode.ERASER,
  [Tool.PAN]: ClemexMosaicCanvasMode.PAN,
  [Tool.DIRECT_MEASURE_DISTANCE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_DISTANCE,
  [Tool.DIRECT_MEASURE_ANGLE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ANGLE,
  [Tool.DIRECT_MEASURE_ELLIPSE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ELLIPSE,
  [Tool.DIRECT_MEASURE_AREA]: ClemexMosaicCanvasMode.DIRECT_MEASURE_AREA,
  [Tool.DIRECT_MEASURE_ARC]: ClemexMosaicCanvasMode.DIRECT_MEASURE_ARC,
  [Tool.DIRECT_MEASURE_PERIMETER]: ClemexMosaicCanvasMode.DIRECT_MEASURE_PERIMETER,
  [Tool.DIRECT_MEASURE_RECTANGLE]: ClemexMosaicCanvasMode.DIRECT_MEASURE_RECTANGLE,
  [Tool.METADATA_ANNOTATION_ARROW]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_ARROW,
  [Tool.METADATA_ANNOTATION_LINE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_LINE,
  [Tool.METADATA_ANNOTATION_POLYGON]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_POLYGON,
  [Tool.METADATA_ANNOTATION_RECTANGLE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_RECTANGLE,
  [Tool.METADATA_ANNOTATION_ELLIPSE]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_ELLIPSE,
  [Tool.METADATA_ANNOTATION_TEXT]: ClemexMosaicCanvasMode.METADATA_ANNOTATION_TEXT,
}
const CANVAS_ID = 'ol-canvas'
const CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR = 1.2

const swapPointCoordinates = (coordinates: number[]): number[] => {
  // Swap the y coordinates because OpenLayers use the bottom left corner as origin and Clemex Studio use the top left corner as origin.
  return coordinates.map((x, index) => (index === 1) ? -x : x)
}
const swapLineStringCoordinates = (coordinates: number[][]): number[][] => {
  return coordinates.map(swapPointCoordinates)
}
const swapPolygonCoordinates = (coordinates: number[][][]): number[][][] => {
  return coordinates.map(swapLineStringCoordinates)
}

const geojsonParser = new GeoJSON()
const classAnnotationGeoJSONToFeature = (classAnnotation: ClassAnnotationGeoJSON): Feature<Polygon> => {
  return new Feature({
    geometry: new Polygon(
      swapPolygonCoordinates(classAnnotation.geometry.coordinates as number[][][]),
    ),
    imageId: classAnnotation.imageId,
    projectId: classAnnotation.projectId,
    colorIndex: classAnnotation.colorIndex,
  })
}
const classAnnotationGeoJSONToFeatures = (classAnnotations: ClassAnnotationGeoJSON[]): Array<Feature<Polygon>> => {
  const features = classAnnotations.map((annotation) => classAnnotationGeoJSONToFeature(annotation))
  features.forEach((feature) => {
    feature.setProperties({
      [FEATURE_TYPE]: FeatureType.CLASS_ANNOTATION,
    })
  })
  return features
}
const classAnnotationFeaturesToGeoJSON = (features: Array<Feature<Polygon>>): ClassAnnotationGeoJSON[] => {
  const changedAnnotationsFeature = geojsonParser.writeFeaturesObject(features) as unknown as FeatureCollection<GeoJSONPolygon, StudioClassAnnotationProperties>
  const changedAnnotations = changedAnnotationsFeature.features.map((feature): ClassAnnotationGeoJSON => {
    return {
      projectId: feature.properties.projectId,
      imageId: feature.properties.imageId,
      colorIndex: feature.properties.colorIndex,
      geometry: {
        type: 'Polygon',
        coordinates: swapPolygonCoordinates(feature.geometry.coordinates),
      },
    }
  })
  return changedAnnotations
}

const directMeasureFeatureToGeoJSON = (feature: Feature<Geometry>): Omit<DirectMeasure, 'projectId' | 'imageId'> => {
  const geoJSONFeature = geojsonParser.writeFeatureObject(feature) as unknown as GeoJSONFeature<GeoJSONLineString | GeoJSONPolygon | GeoJSONPoint, StudioAnnotationFeatureProperties>
  let coordinates = geoJSONFeature.geometry.coordinates
  const type = geoJSONFeature.geometry.type
  switch (type) {
    case 'Point':
      coordinates = swapPointCoordinates(coordinates as number[])
      break
    case 'LineString':
      coordinates = swapLineStringCoordinates(coordinates as number[][])
      break
    case 'Polygon':
      coordinates = swapPolygonCoordinates(coordinates as number[][][])
      break
    default:
      throw new Error(`Unknown type: ${type as unknown as string}`)
  }
  return {
    type: geoJSONFeature.properties[FEATURE_TYPE] as DirectMeasureType,
    directMeasureId: geoJSONFeature.properties.id,
    geometry: {
      type: type as GeometryTypeEnum,
      coordinates,
    },
    geometryProperties: feature.getGeometry()?.getProperties() as CircleGeometryProperties & EllipseGeometryProperties,
  }
}

const annotationFeatureToGeoJSON = (feature: Feature<Geometry>): Omit<MetadataAnnotation, 'projectId' | 'imageId'> => {
  const geoJSONFeature = geojsonParser.writeFeatureObject(feature) as unknown as GeoJSONFeature<GeoJSONLineString | GeoJSONPolygon | GeoJSONPoint, StudioDirectMeasureFeatureProperties>
  let coordinates = geoJSONFeature.geometry.coordinates
  const type = geoJSONFeature.geometry.type
  switch (type) {
    case 'Point':
      coordinates = swapPointCoordinates(coordinates as number[])
      break
    case 'LineString':
      coordinates = swapLineStringCoordinates(coordinates as number[][])
      break
    case 'Polygon':
      coordinates = swapPolygonCoordinates(coordinates as number[][][])
      break
    default:
      throw new Error(`Unknown type: ${type as unknown as string}`)
  }
  return {
    type: geoJSONFeature.properties[FEATURE_TYPE] as MetadataAnnotationType,
    metadataAnnotationId: geoJSONFeature.properties.id,
    geometry: {
      type: type as GeometryTypeEnum,
      coordinates,
    },
    geometryProperties: feature.getGeometry()?.getProperties() as CircleGeometryProperties & EllipseGeometryProperties & ArrowGeometryProperties,
  }
}

const directMeasureGeoJSONToFeature = (annotation: DirectMeasure): Feature<LineString | Polygon | Point> => {
  const properties = {
    [FEATURE_TYPE]: annotation.type,
    id: annotation.directMeasureId,
  }
  let geometry: LineString | Polygon | Point
  switch (annotation.geometry.type) {
    case 'Point':
      geometry = new Point(swapPointCoordinates(annotation.geometry.coordinates as number[]))
      break
    case 'LineString':
      geometry = new LineString(swapLineStringCoordinates(annotation.geometry.coordinates as number[][]))
      break
    case 'Polygon':
      geometry = new Polygon(swapPolygonCoordinates(annotation.geometry.coordinates as number[][][]))
      break
    default:
      throw new Error(`Unknown type: ${annotation.geometry.type as unknown as string}`)
  }
  if (annotation.geometryProperties != null) {
    geometry.setProperties(annotation.geometryProperties)
  }
  return new Feature({
    geometry,
    ...properties,
  })
}

const annotationGeoJSONToFeature = (annotation: MetadataAnnotation): Feature<LineString | Polygon | Point> => {
  const properties = {
    [FEATURE_TYPE]: annotation.type,
    id: annotation.metadataAnnotationId,
  }
  let geometry: LineString | Polygon | Point
  switch (annotation.geometry.type) {
    case 'Point':
      geometry = new Point(swapPointCoordinates(annotation.geometry.coordinates as number[]))
      break
    case 'LineString':
      geometry = new LineString(swapLineStringCoordinates(annotation.geometry.coordinates as number[][]))
      break
    case 'Polygon':
      geometry = new Polygon(swapPolygonCoordinates(annotation.geometry.coordinates as number[][][]))
      break
    default:
      throw new Error(`Unknown type: ${annotation.geometry.type as unknown as string}`)
  }
  if (annotation.geometryProperties !== undefined) {
    geometry.setProperties(annotation.geometryProperties)
  }
  return new Feature({
    geometry,
    ...properties,
  })
}

interface StudioExtraClassAnnotationProperties extends Record<string, unknown> {
  projectId: string
  imageId: string
}
type StudioClassAnnotationProperties = ClassAnnotationProperties<StudioExtraClassAnnotationProperties>
interface StudioDirectMeasureFeatureProperties<T extends DirectMeasureType = DirectMeasureType> {
  [FEATURE_TYPE]: T
  id: string
}
interface StudioAnnotationFeatureProperties<T extends MetadataAnnotationType = MetadataAnnotationType> {
  [FEATURE_TYPE]: T
  id: string
}

type AnnotationHistory = Record<string, { index: number, stack: ClassAnnotationGeoJSON[][] } | null>

interface ClemexMosaicCanvasViewerProps {
  projectId: string
  context: ProjectDatasetContext
}
export const ClemexMosaicCanvasViewer: React.FC<ClemexMosaicCanvasViewerProps> = (props) => {
  const { projectId, context } = props
  const [selectedImageId] = useSelectedImageId()
  const selectedImage = useSelectedImage(projectId)
  const [clemexMosaicCanvas, setClemexMosaicCanvas] = useState<ClemexMosaicCanvas<StudioExtraClassAnnotationProperties> | null>(null)
  const cmcDivRef = useRef<HTMLDivElement>(null)
  const { data: mosaicImage } = useMosaicImage(projectId)
  const { data: mosaicMasks } = useMosaicMasks(projectId)
  const { data: userSettingsCanvas } = useUserSettingsCanvas()
  const setZoom = useStageStore((state) => state.setZoom)
  const setResolution = useStageStore((state) => state.setResolution)
  const [selectedTool] = useSelectedTool()
  const [selectedAnnotationClassColorIndex] = useSelectedAnnotationClassColorIndex()
  const [brushSize] = useBrushSize()
  const { data: projectImageAnnotations, isLoading: isLoadingProjectImageAnnotations, isValidating: isValidatingProjectImageAnnotations, saveClassAnnotations: saveImageAnnotations } = useImageClassAnnotations(projectId, selectedImageId)
  const loadedDirectMeasureRef = useRef<string | undefined>(undefined)
  const loadedAnnotationRef = useRef<string | undefined>(undefined)
  const directMeasure = useDirectMeasure(projectId, selectedImageId)
  const annotation = useMetadataAnnotation(projectId, selectedImageId)
  const { setSelectedItems, isMetadataAnnotationLineToolFreehand, isMetadataAnnotationPolygonToolFreehand } = useEditorStore((state) => {
    return {
      setSelectedItems: state.setSelectedItems,
      isMetadataAnnotationLineToolFreehand: state.isMetadataAnnotationLineToolFreehand,
      isMetadataAnnotationPolygonToolFreehand: state.isMetadataAnnotationPolygonToolFreehand,
    }
  })
  const [globalAnnotationOpacity] = useGlobalAnnotationOpacity()
  const [globalPredictionOpacity] = useGlobalPredictionOpacity()
  const [globalPredictionVisibility] = useGlobalPredictionVisibility()
  const [hiddenAnnotationClassIndexes, setHiddenAnnotationClassIndexes] = useHiddenAnnotationClassIndexes()
  const [hiddenPredictionClassIndexes] = useHiddenPredictionClassIndexes()
  const [isDirectMeasureAreaToolFreehand] = useIsDirectMeasureAreaToolFreehand()
  const [isDirectMeasurePerimeterFreehand] = useIsDirectMeasurePerimeterToolFreehand()
  const directMeasureAndMetadataAnnotationVisibility = useEditorStore((state) => state.directMeasureAndMetadataAnnotationVisibility)

  const { data: projectSettingsDirectMeasureData } = useProjectSettingsDirectMeasure(projectId)

  const shapeStyle: ShapeStyle = useMemo(() => {
    if (projectSettingsDirectMeasureData === undefined) {
      return DEFAULT_SHAPE_STYLE
    }

    const style: ShapeStyle = {
      ...DEFAULT_SHAPE_STYLE,
      mainColor: projectSettingsDirectMeasureData.mainColor,
      measuringLineColor: projectSettingsDirectMeasureData.measuringLineColor,
      measuringPointColor: projectSettingsDirectMeasureData.measuringPointColor,
      measurementTextFontSizePx: projectSettingsDirectMeasureData.fontSize,
      measurementTextFontFamily: projectSettingsDirectMeasureData.fontFamily,
      measurementTextFontWeight: projectSettingsDirectMeasureData.fontWeight,
      measurementTextFontFillColor: projectSettingsDirectMeasureData.fontColor,
      measurementTextFontStrokeColor: projectSettingsDirectMeasureData.fontOutlineColor,
      textPositionToShape: projectSettingsDirectMeasureData.directMeasureTextPosition,
      angleUnit: projectSettingsDirectMeasureData.angleUnit,
    }
    return style
  }, [projectSettingsDirectMeasureData])

  const annotationsHistory = useRef<AnnotationHistory>({})

  const hasUserChangedAnnotationsRef = useRef(false)

  // When cmcDivRef is mounted, create a new ClemexMosaicCanvas and update its state.
  useEffect(() => {
    if (cmcDivRef.current === null) {
      return
    }
    const cmc = new ClemexMosaicCanvas<StudioExtraClassAnnotationProperties>({
      target: CANVAS_ID,
    }, undefined, userSettingsCanvas?.zoomFactor ?? CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR)

    // In development, we want to expose the ClemexMosaicCanvas instance to the window.
    if (import.meta.env.DEV) {
      // Typescript ignore next line
      (window as unknown as { cmc: ClemexMosaicCanvas<StudioExtraClassAnnotationProperties> }).cmc = cmc
    }

    setClemexMosaicCanvas(cmc)
    cmc.setMode(ClemexMosaicCanvasMode.DIRECT_MEASURE_DISTANCE)
    cmc.setEraseOtherClassesOnOverlap(true)

    const preventTabToSwitchFocus = (event: KeyboardEvent): boolean => {
      if (event.key === 'Tab') {
        event.preventDefault()
      }
      return false
    }
    cmcDivRef.current.addEventListener('keydown', preventTabToSwitchFocus, {})

    return () => {
      cmc.dispose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmcDivRef])

  const saveAnnotations = useCallback((annotationFeatures: Array<Feature<Polygon>>, _projectId: string, _imageId: string) => {
    const annotationsGeoJSON = classAnnotationFeaturesToGeoJSON(annotationFeatures)
    if (!hasUserChangedAnnotationsRef.current) {
      return
    }
    void saveImageAnnotations({ classAnnotationsGeoJSON: annotationsGeoJSON, projectId: _projectId, imageId: _imageId })
    hasUserChangedAnnotationsRef.current = false
  }, [saveImageAnnotations])

  // Debounce saveAnnotations
  // We want to debounce saveAnnotations to avoid saving the annotations too often.
  // As many events can trigger saveAnnotations, it can quickly become a performance bottleneck.
  const saveAnnotationsDebounce = useDebouncedCallback(saveAnnotations, 300)

  // Bind EDITOR_FORCE_SAVE_ANNOTATIONS
  // When the event is triggered, flush the debounced saveAnnotations.
  useEffect(() => {
    const onForceSaveAnnotations = (): void => {
      if (clemexMosaicCanvas === null || selectedImageId === undefined) {
        return
      }
      saveAnnotationsDebounce.flush()
    }
    window.addEventListener(EVENTS_ID.EDITOR_FORCE_SAVE_ANNOTATIONS, onForceSaveAnnotations, {})
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_FORCE_SAVE_ANNOTATIONS, onForceSaveAnnotations, {})
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce])

  // Update the annotations when the annotations are loaded.
  // And remove them when the annotations the context is undefined or validation.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    // Before the annotations context changes, we want to save the annotations in debounced saveAnnotations.
    // Otherwise, the annotations for this context will be lost.
    saveAnnotationsDebounce.flush()
    if (projectImageAnnotations === undefined || isLoadingProjectImageAnnotations || selectedImageId === undefined || context === ProjectDatasetContext.Validation) {
      clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures([]))
    } else {
      if (!isValidatingProjectImageAnnotations) {
        clemexMosaicCanvas.setAnnotationProperties({ projectId, imageId: selectedImageId })
        clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(projectImageAnnotations))
        // If history is empty, initialize it.
        if (annotationsHistory.current[selectedImageId] === undefined || annotationsHistory.current[selectedImageId] === null) {
          annotationsHistory.current[selectedImageId] = { index: 0, stack: [projectImageAnnotations] }
        }
      }
    }
  }, [clemexMosaicCanvas, context, isLoadingProjectImageAnnotations, isValidatingProjectImageAnnotations, projectId, projectImageAnnotations, saveAnnotationsDebounce, selectedImageId])

  // When the image changes, we reset direct measures.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setDirectMeasureDistance([])
    clemexMosaicCanvas.setDirectMeasureAngle([])
    clemexMosaicCanvas.setDirectMeasureArea([])
    clemexMosaicCanvas.setPerimeterMeasurements([])
    clemexMosaicCanvas.setDirectMeasureArc([])
    clemexMosaicCanvas.setDirectMeasureRectangle([])
  }, [clemexMosaicCanvas, selectedImageId])

  // When annotations are changed, save them and set hasUserChangedAnnotations to true.
  // This is used to save the annotations when the user is changing image in case the saving is debounced.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const onAnnotationChange = (annotations: ChangePatch<Feature<Polygon>>): void => {
      if (selectedImageId === undefined) {
        return
      }
      hasUserChangedAnnotationsRef.current = true
      const newAnnotations = annotations.add?.map((addPatch) => addPatch.data)
      if (newAnnotations !== undefined) {
        // Note: We need to forward mutateAnnotations as it is bound to its cache key context (projectId, imageId).
        //       We do not want that the mutation is applied to the wrong image.
        saveAnnotationsDebounce(newAnnotations, projectId, selectedImageId)
      }
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, onAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, saveAnnotationsDebounce, saveAnnotations, projectId, selectedImageId])

  // When the annotation changes, update the annotation history.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const onAnnotationChange = (annotations: ChangePatch<Feature<Polygon>>): void => {
      const newAnnotations = annotations.add?.map((addPatch) => addPatch.data)
      if (newAnnotations === undefined) {
        return
      }
      if (selectedImageId === undefined) {
        return
      }

      // Assert annotations imageId is the same as the current imageId.
      if (newAnnotations.length > 0) {
        const annotationPropertiesImageId = (newAnnotations[0].getProperties() as StudioExtraClassAnnotationProperties).imageId
        if (annotationPropertiesImageId !== selectedImageId) {
          console.error(`Annotations imageId (${annotationPropertiesImageId}) is not the same as the current imageId (${selectedImageId})`)
        }
      }

      if (annotationsHistory.current[selectedImageId] === undefined) {
        annotationsHistory.current[selectedImageId] = { index: 0, stack: [[]] }
      }
      const annotationHistory = annotationsHistory.current[selectedImageId]
      if (annotationHistory === null) {
        annotationsHistory.current[selectedImageId] = { index: 0, stack: [classAnnotationFeaturesToGeoJSON(newAnnotations)] }
      } else {
        if (annotationHistory.index < annotationHistory.stack.length - 1) {
          annotationHistory.stack = annotationHistory.stack.slice(0, annotationHistory.index + 1)
        }
        annotationHistory.stack = [...annotationHistory.stack, classAnnotationFeaturesToGeoJSON(newAnnotations)]
        annotationHistory.index = annotationHistory.stack.length - 1
      }
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.CLASS_ANNOTATION_CHANGED, onAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, selectedImageId])

  // When the direct measure are loaded.
  // Load them inside the ClemexMosaicCanvas.
  useEffect(() => {
    if (clemexMosaicCanvas === null || directMeasure.data === undefined) {
      return
    }
    const loadId = `${projectId}-${selectedImageId}`
    if (loadedDirectMeasureRef.current !== loadId) {
      const typeToSetter = {
        [DirectMeasureType.Distance]: clemexMosaicCanvas.setDirectMeasureDistance as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Angle]: clemexMosaicCanvas.setDirectMeasureAngle as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Area]: clemexMosaicCanvas.setDirectMeasureArea as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Perimeter]: clemexMosaicCanvas.setPerimeterMeasurements as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Arc]: clemexMosaicCanvas.setDirectMeasureArc as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Rectangle]: clemexMosaicCanvas.setDirectMeasureRectangle as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Ellipse]: clemexMosaicCanvas.setDirectMeasureEllipse as (annotations: Array<Feature<Geometry>>) => void,
        [DirectMeasureType.Circle]: clemexMosaicCanvas.setDirectMeasureCircle as (annotations: Array<Feature<Geometry>>) => void,
      } as const
      const directMeasureGroupByType = groupBy(directMeasure.data, (annotation) => annotation.type)
      Object.entries(directMeasureGroupByType).forEach(([type, annotations]) => {
        if (typeToSetter[type as DirectMeasureType] === undefined) {
          console.error(`Unknown direct measure type: ${type}`)
        } else {
          typeToSetter[type as DirectMeasureType](annotations.map((annotation) => directMeasureGeoJSONToFeature(annotation)))
        }
      })
      loadedDirectMeasureRef.current = loadId
    }
  }, [clemexMosaicCanvas, directMeasure.data, projectId, selectedImageId])

  // When the annotation are loaded.
  // Load them inside the ClemexMosaicCanvas.
  useEffect(() => {
    if (clemexMosaicCanvas === null || annotation.data === undefined) {
      return
    }
    const loadId = `${projectId}-${selectedImageId}`
    if (loadedAnnotationRef.current !== loadId) {
      const typeToSetter = {
        [MetadataAnnotationType.Arrow]: clemexMosaicCanvas.setMetadataAnnotationArrow as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Polygon]: clemexMosaicCanvas.setMetadataAnnotationPolygon as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Line]: clemexMosaicCanvas.setMetadataAnnotationLine as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Rectangle]: clemexMosaicCanvas.setMetadataAnnotationRectangle as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Ellipse]: clemexMosaicCanvas.setMetadataAnnotationEllipse as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Circle]: clemexMosaicCanvas.setMetadataAnnotationCircle as (annotation: Array<Feature<Geometry>>) => void,
        [MetadataAnnotationType.Text]: clemexMosaicCanvas.setMetadataAnnotationText as (annotation: Array<Feature<Geometry>>) => void,
      } as const
      const annotationGroupByType = groupBy(annotation.data, (annotation) => annotation.type)
      Object.entries(annotationGroupByType).forEach(([type, annotations]) => {
        if (typeToSetter[type as MetadataAnnotationType] === undefined) {
          console.error(`Unknown metadata annotation type: ${type}`)
        } else {
          typeToSetter[type as MetadataAnnotationType](annotations.map((annotation) => annotationGeoJSONToFeature(annotation)))
        }
      })
      loadedAnnotationRef.current = loadId
    }
  }, [annotation.data, clemexMosaicCanvas, projectId, selectedImageId])

  const debouncedSaveDirectMeasurePatches = useDebouncedCallback(directMeasure.savePatches, 300)
  // When the direct measure change, save them.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const onDirectMeasureChange = (directMeasureChangePatch: ChangePatch<Feature<Geometry>>): void => {
      if (selectedImageId === undefined) {
        return
      }
      hasUserChangedAnnotationsRef.current = true
      directMeasure.patch({
        add: directMeasureChangePatch.add?.map((addPatch): DirectMeasure => {
          return { ...directMeasureFeatureToGeoJSON(addPatch.data), projectId, imageId: selectedImageId }
        }),
        remove: directMeasureChangePatch.remove?.map((removePatch): DirectMeasure => {
          return { ...directMeasureFeatureToGeoJSON(removePatch.data), projectId, imageId: selectedImageId }
        }),
        update: directMeasureChangePatch.update?.map((updatePatch): DirectMeasure => {
          return { ...directMeasureFeatureToGeoJSON(updatePatch.data), projectId, imageId: selectedImageId }
        }),
      })
      void debouncedSaveDirectMeasurePatches()
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.DIRECT_MEASURE_CHANGED, onDirectMeasureChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, projectId, selectedImageId, directMeasure, debouncedSaveDirectMeasurePatches])

  const debouncedSaveAnnotationPatches = useDebouncedCallback(annotation.savePatches, 300)
  // When the direct measure change, save them.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const onMetadataAnnotationChange = (annotationChangePatch: ChangePatch<Feature<Geometry>>): void => {
      if (selectedImageId === undefined) {
        return
      }
      hasUserChangedAnnotationsRef.current = true
      annotation.patch({
        add: annotationChangePatch.add?.map((addPatch): MetadataAnnotation => {
          return { ...annotationFeatureToGeoJSON(addPatch.data), projectId, imageId: selectedImageId }
        }),
        remove: annotationChangePatch.remove?.map((removePatch): MetadataAnnotation => {
          return { ...annotationFeatureToGeoJSON(removePatch.data), projectId, imageId: selectedImageId }
        }),
        update: annotationChangePatch.update?.map((updatePatch): MetadataAnnotation => {
          return { ...annotationFeatureToGeoJSON(updatePatch.data), projectId, imageId: selectedImageId }
        }),
      })
      void debouncedSaveAnnotationPatches()
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.METADATA_ANNOTATION_CHANGED, onMetadataAnnotationChange)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, projectId, selectedImageId, directMeasure, debouncedSaveDirectMeasurePatches, debouncedSaveAnnotationPatches, annotation])

  // When the selection change, update the editor state.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    const onSelect = (): void => {
      setSelectedItems(clemexMosaicCanvas.getSelection())
    }
    const deleteListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.SELECTION_CHANGED, onSelect)
    return () => {
      deleteListenerCB()
    }
  }, [clemexMosaicCanvas, setSelectedItems])

  // Subscribe to EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES
  // When the event is triggered, update the properties of the selected arrows.
  useEffect(() => {
    const onUpdateArrowProperties = (event: Event): void => {
      const customEvent = event as CustomEvent<ArrowGeometryProperties>
      if (clemexMosaicCanvas === null) {
        return
      }
      const selection = clemexMosaicCanvas.getSelection()
      if (selection.length !== 1 || selection[0].getProperties()[FEATURE_TYPE] !== FeatureType.METADATA_ANNOTATION_ARROW) {
        console.error('EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES event was triggered but no arrow is selected.')
        return
      }
      const annotationId = selection[0].getProperties().id as string
      const arrowProperties = customEvent.detail
      clemexMosaicCanvas.setMetadataAnnotationArrowProperties(annotationId, arrowProperties)
    }
    window.addEventListener(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, onUpdateArrowProperties)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_UPDATE_SELECTION_ARROW_PROPERTIES, onUpdateArrowProperties)
    }
  }, [clemexMosaicCanvas])

  // When the selectedImageId change, force saving the direct measure for the previous image.
  useEffect(() => {
    if (selectedImageId === undefined) {
      return
    }
    return () => {
      debouncedSaveDirectMeasurePatches.flush()
    }
  }, [selectedImageId, debouncedSaveDirectMeasurePatches])

  // Bind bi-derectional zoom behavior
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    const zoomToFit = (): void => {
      clemexMosaicCanvas.resetView()
    }
    const zoomIn = (): void => {
      clemexMosaicCanvas.zoomIn()
    }
    const zoomOut = (): void => {
      clemexMosaicCanvas.zoomOut()
    }
    const deleteListernerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.ZOOM_CHANGED, ({ zoom, resolution }) => {
      setZoom(zoom)
      setResolution(resolution)
    })
    window.addEventListener(EVENTS_ID.ZOOM_TO_FIT, zoomToFit)
    window.addEventListener(EVENTS_ID.ZOOM_IN, zoomIn)
    window.addEventListener(EVENTS_ID.ZOOM_OUT, zoomOut)
    return () => {
      deleteListernerCB()
      window.removeEventListener(EVENTS_ID.ZOOM_TO_FIT, zoomToFit)
      window.removeEventListener(EVENTS_ID.ZOOM_IN, zoomIn)
      window.removeEventListener(EVENTS_ID.ZOOM_OUT, zoomOut)
    }
  }, [clemexMosaicCanvas, setResolution, setZoom])

  // When the annotating or erasing interaction start, change the visibility of the annotations to visible
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    const onInteractionStart = (): void => {
      setHiddenAnnotationClassIndexes([])
    }
    const deleteAnnotatingStartListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.START_ANNOTATING, onInteractionStart)
    const deleteErasingStartListenerCB = clemexMosaicCanvas.addListener(ClemexMosaicCanvasListenersType.START_ERASING, onInteractionStart)
    return () => {
      deleteAnnotatingStartListenerCB()
      deleteErasingStartListenerCB()
    }
  }, [clemexMosaicCanvas, setHiddenAnnotationClassIndexes])

  // When mosaicImage is loaded or changed, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (clemexMosaicCanvas === null || mosaicImage === undefined) {
      return
    }
    clemexMosaicCanvas.resetSources()
    clemexMosaicCanvas.setImage(mosaicImage)
    clemexMosaicCanvas.resetView()
  }, [clemexMosaicCanvas, mosaicImage])

  // When mosaicMasks are loaded or changed, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    if (mosaicMasks === undefined) {
      clemexMosaicCanvas.removeAllMasks()
    } else {
      clemexMosaicCanvas.setMasks(mosaicMasks)
      mosaicMasks?.forEach((mask) => {
        const isColorIndexVisible = !hiddenPredictionClassIndexes.includes(mask.maskIndex)
        clemexMosaicCanvas.setMaskLayerVisibility(mask.maskIndex, isColorIndexVisible)
        clemexMosaicCanvas.setMaskLayerGroupOpacity(globalPredictionVisibility ? globalPredictionOpacity : 0)
      })
    }
  // XXX: Do not include hiddenClassIndexes in the dependencies
  //      Setting the masks is a slow operation, and we don't want to do it every time opacity or visibility of mask classes changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clemexMosaicCanvas, context, mosaicMasks])

  useEffect(() => {
    if (clemexMosaicCanvas === null || selectedTool === undefined) {
      return
    }
    clemexMosaicCanvas.setMode(TOOL_MAPPING[selectedTool])
  }, [clemexMosaicCanvas, selectedTool])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setSelectionModes({
      classAnnotations: false,
      directMeasurementAnnotations: true,
      metadataAnnotations: true,
    })
  }, [clemexMosaicCanvas])

  useEffect(() => {
    if (clemexMosaicCanvas === null || selectedAnnotationClassColorIndex === undefined) {
      return
    }
    clemexMosaicCanvas.setColorIndex(selectedAnnotationClassColorIndex)
  }, [clemexMosaicCanvas, selectedAnnotationClassColorIndex])

  useEffect(() => {
    if (clemexMosaicCanvas === null || brushSize === undefined) {
      return
    }
    clemexMosaicCanvas.setDrawingBrushSize(brushSize)
  }, [clemexMosaicCanvas, brushSize])

  // When globalClassOpacity change, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setAnnotationsLayerOpacity(globalAnnotationOpacity)
  }, [clemexMosaicCanvas, globalAnnotationOpacity])

  // XXX: Debounce as OpenLayers is not able to quickly change layer opacity.
  const debouncedUpdateMaskLayerGroupOpacityAndVisibility = useDebouncedCallback((_clemexMosaicCanvas: ClemexMosaicCanvas<StudioExtraClassAnnotationProperties>, _globalPredictionVisibility: boolean, _globalPredictionOpacity: number) => {
    _clemexMosaicCanvas.setMaskLayerGroupOpacity(_globalPredictionVisibility ? _globalPredictionOpacity : 0)
    _clemexMosaicCanvas.renderSync()
  }, 500, { leading: true, trailing: true, maxWait: 300 })

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    debouncedUpdateMaskLayerGroupOpacityAndVisibility(clemexMosaicCanvas, globalPredictionVisibility, globalPredictionOpacity)
  }, [clemexMosaicCanvas, globalPredictionVisibility, globalPredictionOpacity, debouncedUpdateMaskLayerGroupOpacityAndVisibility])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    if (context === ProjectDatasetContext.Training) {
      for (let colorIndex = 0; colorIndex < MAX_CLASS_COUNT; colorIndex++) {
        const isColorIndexVisible = !hiddenAnnotationClassIndexes.includes(colorIndex)
        clemexMosaicCanvas.setAnnotationsColorIndexVisibility(colorIndex, isColorIndexVisible)
      }
    }
    mosaicMasks?.forEach((mask) => {
      const isColorIndexVisible = !hiddenPredictionClassIndexes.includes(mask.maskIndex)
      clemexMosaicCanvas.setMaskLayerVisibility(mask.maskIndex, isColorIndexVisible)
    })
    clemexMosaicCanvas.render()
  }, [context, clemexMosaicCanvas, hiddenAnnotationClassIndexes, hiddenPredictionClassIndexes, mosaicMasks])

  // When the directMeasureAndMetadataAnnotationVisibility change, update the ClemexMosaicCanvas state.
  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setDirectMeasureAndMetadataAnnotationLayerVisibility(directMeasureAndMetadataAnnotationVisibility)
  }, [clemexMosaicCanvas, directMeasureAndMetadataAnnotationVisibility])

  // Remove context menu from the canvas, to allow right click pan.
  useEffect(() => {
    cmcDivRef.current?.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })
  }, [])

  const undo = useCallback(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const annotationHistory = (selectedImageId !== undefined && annotationsHistory.current[selectedImageId] !== undefined)
      ? annotationsHistory.current[selectedImageId]
      : undefined
    if (annotationHistory === undefined || annotationHistory === null) {
      return
    }
    if (annotationHistory.index > 0) {
      annotationHistory.index -= 1
    }
    const annotations = annotationHistory.stack[annotationHistory.index]
    clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(annotations))
    hasUserChangedAnnotationsRef.current = true
    if (selectedImageId !== undefined) {
      saveAnnotationsDebounce(classAnnotationGeoJSONToFeatures(annotations), projectId, selectedImageId)
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce, projectId])

  const redo = useCallback(() => {
    if (clemexMosaicCanvas === null) {
      return
    }

    const annotationHistory = (selectedImageId !== undefined && annotationsHistory.current[selectedImageId] !== undefined)
      ? annotationsHistory.current[selectedImageId]
      : undefined
    if (annotationHistory === undefined || annotationHistory === null) {
      return
    }
    if (annotationHistory.index < annotationHistory.stack.length - 1) {
      annotationHistory.index += 1
    }
    const annotations = annotationHistory.stack[annotationHistory.index]
    clemexMosaicCanvas.setClassAnnotations(classAnnotationGeoJSONToFeatures(annotations))
    hasUserChangedAnnotationsRef.current = true
    if (selectedImageId !== undefined) {
      saveAnnotationsDebounce(classAnnotationGeoJSONToFeatures(annotations), projectId, selectedImageId)
    }
  }, [clemexMosaicCanvas, selectedImageId, saveAnnotationsDebounce, projectId])

  // Bind Undo/Redo behavior
  useEditorShortcutHook({ undo, redo })

  // Bind EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET
  useEffect(() => {
    const onResetHistory = (): void => {
      annotationsHistory.current = { }
      if (selectedImageId !== undefined) {
        annotationsHistory.current[selectedImageId] = null
      }
    }
    window.addEventListener(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET, onResetHistory)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_ANNOTATIONS_UNDO_REDO_HISTORY_RESET, onResetHistory)
    }
  }, [clemexMosaicCanvas, selectedImageId])

  // Bind EDITOR_TOOL_SELECTION_DELETE
  useEffect(() => {
    const onDeleteSelection = (): void => {
      clemexMosaicCanvas?.deleteSelection()
    }
    window.addEventListener(EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE, onDeleteSelection)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_TOOL_SELECTION_DELETE, onDeleteSelection)
    }
  }, [clemexMosaicCanvas, selectedImageId])

  useEffect(() => {
    if (clemexMosaicCanvas !== null) {
      clemexMosaicCanvas.setPixelSize(selectedImage.data?.pixelSizeUm ?? undefined)
    }
  }, [clemexMosaicCanvas, selectedImage.data?.pixelSizeUm])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setDirectMeasureDistanceStyle(shapeStyle)
    clemexMosaicCanvas.setAngleStyle(shapeStyle)
    clemexMosaicCanvas.setPerimeterMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setAreaMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setArcMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setBoundingBoxMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setEllipseMeasurementStyle(shapeStyle)
    clemexMosaicCanvas.setCircleMeasurementStyle(shapeStyle)
  }, [clemexMosaicCanvas, shapeStyle])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setDirectMeasureAreaToolFreehand(isDirectMeasureAreaToolFreehand)
  }, [clemexMosaicCanvas, isDirectMeasureAreaToolFreehand])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setDirectMeasurePerimeterToolFreehand(isDirectMeasurePerimeterFreehand)
  }, [clemexMosaicCanvas, isDirectMeasurePerimeterFreehand])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setZoomFactor(userSettingsCanvas?.zoomFactor ?? CLEMEX_STUDIO_CANVAS_ZOOM_FACTOR)
  }, [clemexMosaicCanvas, userSettingsCanvas?.zoomFactor])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setMetadataDataPolygonToolFreehand(isMetadataAnnotationPolygonToolFreehand)
  }, [clemexMosaicCanvas, isMetadataAnnotationPolygonToolFreehand])

  useEffect(() => {
    if (clemexMosaicCanvas === null) {
      return
    }
    clemexMosaicCanvas.setMetadataDataLineToolFreehand(isMetadataAnnotationLineToolFreehand)
  }, [clemexMosaicCanvas, isMetadataAnnotationLineToolFreehand])

  return <div
    id={CANVAS_ID}
    ref={cmcDivRef}
    className={styles['ol-canvas']}
    tabIndex={0} // This is required for keyboard events to work.
  >
    <div id='ol-overlay-text-edit' style={{ visibility: 'hidden' }}>
      <textarea id="story" name="story" rows={5} cols={33}/>
    </div>
  </div>
}
