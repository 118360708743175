/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UndefinedGeometryProperties
 */
export interface UndefinedGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof UndefinedGeometryProperties
     */
    geometryType?: UndefinedGeometryPropertiesGeometryTypeEnum;
}


/**
 * @export
 */
export const UndefinedGeometryPropertiesGeometryTypeEnum = {
    Undefined: 'UNDEFINED'
} as const;
export type UndefinedGeometryPropertiesGeometryTypeEnum = typeof UndefinedGeometryPropertiesGeometryTypeEnum[keyof typeof UndefinedGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the UndefinedGeometryProperties interface.
 */
export function instanceOfUndefinedGeometryProperties(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UndefinedGeometryPropertiesFromJSON(json: any): UndefinedGeometryProperties {
    return UndefinedGeometryPropertiesFromJSONTyped(json, false);
}

export function UndefinedGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UndefinedGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': !exists(json, 'geometry_type') ? undefined : json['geometry_type'],
    };
}

export function UndefinedGeometryPropertiesToJSON(value?: UndefinedGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
    };
}

