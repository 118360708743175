import { useProjectImagePredictionMasksStatus, useProjectTrainingSnapshot, useSelectedImage } from '@app/api/hooks'
import { PredictionMaskStatus } from '@app/api/openapi'
import { ClemexMosaicImage, ClemexMosaicMask } from '@clemex/mosaic-canvas'
import { uniq } from 'lodash'
import { useMemo } from 'react'

class StudioClemexMosaicImage extends ClemexMosaicImage {
  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/tile/${z}/${x}/${y}`
  }
}
interface ImageMetadata {
  mosaicId: string
  x: number
  y: number
  width: number
  height: number
  tileSize?: number
  rotation?: number
}
type StudioClemexMosaicMaskProps = ImageMetadata & { baseURL: string, projectTrainingSnapshotId: string, maskIndex: number }
class StudioClemexMosaicMask extends ClemexMosaicMask {
  private readonly projectTrainingSnapshotId: string
  constructor ({ projectTrainingSnapshotId, ...options }: StudioClemexMosaicMaskProps) {
    super(options)
    this.projectTrainingSnapshotId = projectTrainingSnapshotId
  }

  public override getUrl (z: number, x: number, y: number): string {
    return `${this.baseURL}/api/images/${this.mosaicId}/prediction/${this.projectTrainingSnapshotId}/mask/${this.maskIndex}/tile/${z}/${x}/${y}`
  }
}

export interface MosaicConfiguration {
  mosaicImage: StudioClemexMosaicImage
  mosaicMasks: StudioClemexMosaicMask[]
}
interface useMosaicImageType {
  data: StudioClemexMosaicImage | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicImage = (projectId: string | undefined): useMosaicImageType => {
  const { data: selectedImage, ...rest } = useSelectedImage(projectId)

  // Memoize ClemexMosaicImageData instance per imageId
  const mosaicImage = useMemo((): StudioClemexMosaicImage | undefined => {
    return (selectedImage?.id === undefined)
      ? undefined
      : new StudioClemexMosaicImage({
        mosaicId: selectedImage.id,
        height: selectedImage.height,
        width: selectedImage.width,
        x: 0,
        y: 0,
        baseURL: window.location.origin,
      })
  }, [selectedImage?.id, selectedImage?.height, selectedImage?.width])

  return {
    ...rest,
    data: mosaicImage,
  }
}

interface useMosaicMasksType {
  data: StudioClemexMosaicMask[] | undefined
  isLoading: boolean
  isValidating: boolean
  error: unknown
}
export const useMosaicMasks = (projectId: string | undefined): useMosaicMasksType => {
  const { data: projectImageInfo, isLoading, isValidating, error } = useSelectedImage(projectId)
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)
  const { data: projectImagePredictionMasksStatus, isValidating: isValidatingProjectImagePredictionMasksStatus } = useProjectImagePredictionMasksStatus(projectId, projectImageInfo?.id, projectTrainingSnapshot?.projectTrainingSnapshotId)

  const mosaicMasks = useMemo((): StudioClemexMosaicMask[] | undefined => {
    if (projectImagePredictionMasksStatus?.status === PredictionMaskStatus.NotReady || isValidatingProjectImagePredictionMasksStatus) {
      return undefined
    }
    if (projectImageInfo?.id === undefined || projectTrainingSnapshot === undefined) {
      return undefined
    }
    const projectTrainingSnapshotId = projectTrainingSnapshot.projectTrainingSnapshotId
    const trainingSnapshotColorIndexes = uniq(projectTrainingSnapshot.classAnnotations.map((classAnnotation) => classAnnotation.colorIndex))
    const masks = trainingSnapshotColorIndexes.map((trainingSnapshotColorIndex) => {
      return new StudioClemexMosaicMask({
        mosaicId: projectImageInfo.id,
        height: projectImageInfo.height,
        width: projectImageInfo.width,
        x: 0,
        y: 0,
        maskIndex: trainingSnapshotColorIndex,
        baseURL: window.location.origin,
        projectTrainingSnapshotId,
      })
    })
    return masks
  }, [
    isValidatingProjectImagePredictionMasksStatus,
    projectImageInfo?.id,
    projectImageInfo?.width,
    projectImageInfo?.height,
    projectImagePredictionMasksStatus,
    projectTrainingSnapshot,
  ])

  return {
    data: mosaicMasks,
    isLoading,
    isValidating,
    error,
  }
}
