import * as React from 'react'
import { EVENTS_ID } from '@app/constants'
import { useLicence } from '@app/api/hooks'

export const useEditorShortcutHook = ({ undo, redo }: { undo: () => void, redo: () => void }): void => {
  React.useEffect(() => {
    const undoEventHandler = (): void => {
      undo()
    }
    const redoEventHandler = (): void => {
      redo()
    }

    window.addEventListener(EVENTS_ID.EDITOR_UNDO, undoEventHandler)
    window.addEventListener(EVENTS_ID.EDITOR_REDO, redoEventHandler)
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_UNDO, undoEventHandler)
      window.removeEventListener(EVENTS_ID.EDITOR_REDO, redoEventHandler)
    }
  }, [undo, redo])
}

export const useBindExportProjectShortcut = (projectId?: string): void => {
  const { data: licence, isLoading: isLicenceLoading } = useLicence()

  React.useEffect(() => {
    if (projectId === undefined) {
      return
    }
    if (isLicenceLoading) {
      return
    }
    const exportProjectEventHandler = (): void => {
      if (!(licence?.enableExportProject ?? false)) {
        window.dispatchEvent(new CustomEvent(EVENTS_ID.PROJECT_EXPORT_SETTINGS))
        return
      }
      // Download project
      const link = document.createElement('a')
      link.href = `/api/projects/${projectId}/export`
      link.download = 'true'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    window.addEventListener(EVENTS_ID.EXPORT_PROJECT, exportProjectEventHandler)
    return () => {
      window.removeEventListener(EVENTS_ID.EXPORT_PROJECT, exportProjectEventHandler)
    }
  }, [isLicenceLoading, licence?.enableExportProject, projectId])
}
