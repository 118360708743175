import type * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { useIntl } from 'react-intl'
import styles from './styles/create-password.module.scss'
import { CreatePasswordForm, type CreatePasswordFormResult } from '@components/authentication/create-password-form'
import { useBaseNotificationStore } from '@app/hooks/notifications'

interface CreatePasswordPageProps {
  createPasswordToken: string
}
export const CreatePasswordPage: React.FC<CreatePasswordPageProps> = ({ createPasswordToken }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useBaseNotificationStore()

  const handlePasswordResetSubmit = async (formData: CreatePasswordFormResult): Promise<void> => {
    try {
      await RFAPI.createOrResetPassword(formData.token, formData.password)
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'login.notification.passwordReset.success.title',
          defaultMessage: 'Password Reset is a Success!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.passwordReset.success.body',
          defaultMessage: 'Please login to continue.',
        }),
      })
      navigate(WebUIRoutes.authentication().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'login.notification.createPassword.error.title',
          defaultMessage: 'Password creation failed!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.createPassword.error.body',
          defaultMessage: 'Invalid token. Please try again soon.',
        }),
      })
      Sentry.captureException(error)
    }
  }

  return <RFPage background backgroundOnTopFooter>
    <AuthenticationHeader>
      <WelcomeTitle />
    </AuthenticationHeader>
    <RFContent className={styles.content}>
      <div className={styles.createPasswordFormsContainer}>
        <CreatePasswordForm onSubmit={handlePasswordResetSubmit} createPasswordToken={createPasswordToken}/>
      </div>
    </RFContent>
  </RFPage>
}
