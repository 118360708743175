/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectMeasureType } from './DirectMeasureType';
import {
    DirectMeasureTypeFromJSON,
    DirectMeasureTypeFromJSONTyped,
    DirectMeasureTypeToJSON,
} from './DirectMeasureType';
import type { Geometry } from './Geometry';
import {
    GeometryFromJSON,
    GeometryFromJSONTyped,
    GeometryToJSON,
} from './Geometry';
import type { GeometryProperties } from './GeometryProperties';
import {
    GeometryPropertiesFromJSON,
    GeometryPropertiesFromJSONTyped,
    GeometryPropertiesToJSON,
} from './GeometryProperties';

/**
 * 
 * @export
 * @interface DirectMeasure
 */
export interface DirectMeasure {
    /**
     * 
     * @type {Date}
     * @memberof DirectMeasure
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DirectMeasure
     */
    modificationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasure
     */
    directMeasureId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasure
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof DirectMeasure
     */
    imageId: string;
    /**
     * 
     * @type {DirectMeasureType}
     * @memberof DirectMeasure
     */
    type: DirectMeasureType;
    /**
     * 
     * @type {Geometry}
     * @memberof DirectMeasure
     */
    geometry: Geometry;
    /**
     * 
     * @type {GeometryProperties}
     * @memberof DirectMeasure
     */
    geometryProperties?: GeometryProperties;
}

/**
 * Check if a given object implements the DirectMeasure interface.
 */
export function instanceOfDirectMeasure(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;

    return isInstance;
}

export function DirectMeasureFromJSON(json: any): DirectMeasure {
    return DirectMeasureFromJSONTyped(json, false);
}

export function DirectMeasureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectMeasure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDate': !exists(json, 'creation_date') ? undefined : (new Date(json['creation_date'])),
        'modificationDate': !exists(json, 'modification_date') ? undefined : (new Date(json['modification_date'])),
        'directMeasureId': !exists(json, 'direct_measure_id') ? undefined : json['direct_measure_id'],
        'projectId': json['project_id'],
        'imageId': json['image_id'],
        'type': DirectMeasureTypeFromJSON(json['type']),
        'geometry': GeometryFromJSON(json['geometry']),
        'geometryProperties': !exists(json, 'geometry_properties') ? undefined : GeometryPropertiesFromJSON(json['geometry_properties']),
    };
}

export function DirectMeasureToJSON(value?: DirectMeasure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creation_date': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modification_date': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'direct_measure_id': value.directMeasureId,
        'project_id': value.projectId,
        'image_id': value.imageId,
        'type': DirectMeasureTypeToJSON(value.type),
        'geometry': GeometryToJSON(value.geometry),
        'geometry_properties': GeometryPropertiesToJSON(value.geometryProperties),
    };
}

