import { create } from 'zustand'

// TODO: synchronize with clemex-mosaic-canvas value
const MAX_RESOLUTION = 1 / 64
const ZOOM_FACTOR = 2

export interface StageStore {
  zoom: number | undefined
  resolution: number | undefined
  canZoomIn: () => boolean
  canZoomOut: () => boolean
  canZoomReset: () => boolean
  setZoom: (zoom: number | undefined) => void
  setResolution: (resolution: number | undefined) => void
}
export const useStageStore = create<StageStore>((set, get) => {
  return {
    zoom: undefined,
    resolution: undefined,
    canZoomIn: () => {
      const resolution = get().resolution
      return resolution !== undefined && (resolution / ZOOM_FACTOR) >= MAX_RESOLUTION
    },
    canZoomOut: () => {
      const zoom = get().zoom
      // We can zoom out when zoom is 1 but not when zoom is lower than 1
      // It allows 1 zoom out step from 100% zoom
      return zoom !== undefined && zoom >= 1
    },
    canZoomReset: () => {
      const zoom = get().zoom
      return zoom !== undefined && zoom !== 1
    },
    setZoom: (zoom: number | undefined) => {
      set({ zoom })
    },
    setResolution: (resolution: number | undefined) => {
      set({ resolution })
    },
  }
})
