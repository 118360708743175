/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlgorithmStatusResponse
 */
export interface AlgorithmStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AlgorithmStatusResponse
     */
    isReady: boolean;
}

/**
 * Check if a given object implements the AlgorithmStatusResponse interface.
 */
export function instanceOfAlgorithmStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isReady" in value;

    return isInstance;
}

export function AlgorithmStatusResponseFromJSON(json: any): AlgorithmStatusResponse {
    return AlgorithmStatusResponseFromJSONTyped(json, false);
}

export function AlgorithmStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlgorithmStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isReady': json['is_ready'],
    };
}

export function AlgorithmStatusResponseToJSON(value?: AlgorithmStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_ready': value.isReady,
    };
}

