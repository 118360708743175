/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ArrowGeometryProperties } from './ArrowGeometryProperties';
import {
    instanceOfArrowGeometryProperties,
    ArrowGeometryPropertiesFromJSON,
    ArrowGeometryPropertiesFromJSONTyped,
    ArrowGeometryPropertiesToJSON,
} from './ArrowGeometryProperties';
import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { TextGeometryProperties } from './TextGeometryProperties';
import {
    instanceOfTextGeometryProperties,
    TextGeometryPropertiesFromJSON,
    TextGeometryPropertiesFromJSONTyped,
    TextGeometryPropertiesToJSON,
} from './TextGeometryProperties';
import type { UndefinedGeometryProperties } from './UndefinedGeometryProperties';
import {
    instanceOfUndefinedGeometryProperties,
    UndefinedGeometryPropertiesFromJSON,
    UndefinedGeometryPropertiesFromJSONTyped,
    UndefinedGeometryPropertiesToJSON,
} from './UndefinedGeometryProperties';

/**
 * @type GeometryProperties1
 * 
 * @export
 */
export type GeometryProperties1 = { geometryType: 'ARROW' } & ArrowGeometryProperties | { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'TEXT' } & TextGeometryProperties | { geometryType: 'UNDEFINED' } & UndefinedGeometryProperties;

export function GeometryProperties1FromJSON(json: any): GeometryProperties1 {
    return GeometryProperties1FromJSONTyped(json, false);
}

export function GeometryProperties1FromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'ARROW':
            return {...ArrowGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ARROW'};
        case 'CIRCLE':
            return {...CircleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'CIRCLE'};
        case 'ELLIPSE':
            return {...EllipseGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ELLIPSE'};
        case 'TEXT':
            return {...TextGeometryPropertiesFromJSONTyped(json, true), geometryType: 'TEXT'};
        case 'UNDEFINED':
            return {...UndefinedGeometryPropertiesFromJSONTyped(json, true), geometryType: 'UNDEFINED'};
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryProperties1ToJSON(value?: GeometryProperties1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['geometryType']) {
        case 'ARROW':
            return ArrowGeometryPropertiesToJSON(value);
        case 'CIRCLE':
            return CircleGeometryPropertiesToJSON(value);
        case 'ELLIPSE':
            return EllipseGeometryPropertiesToJSON(value);
        case 'TEXT':
            return TextGeometryPropertiesToJSON(value);
        case 'UNDEFINED':
            return UndefinedGeometryPropertiesToJSON(value);
        default:
            throw new Error(`No variant of GeometryProperties1 exists with 'geometryType=${value['geometryType']}'`);
    }

}

