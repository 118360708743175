import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal } from 'antd'
import styles from './styles/server-disconnected-modal.module.scss'
import { EVENTS_ID } from '@app/constants'

export const ServerDisconnectedModal: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [reconnectionHasFailed, setReconnectionHasFailed] = React.useState(false)
  const [licenceHasFailed, setLicenceHasFailed] = React.useState(false)
  const [rfAlgorithmHasFailed, setRfAlgorithmHasFailed] = React.useState(false)
  const [dlAlgorithmHasFailed, setDlAlgorithmHasFailed] = React.useState(false)
  const intl = useIntl()

  const mailToStringForContactingSupportOnDesktopError = (service: string): string => {
    const mailSubject = intl.formatMessage({
      id: 'disconnected.modal.mail.template.subject',
      defaultMessage: 'Clemex Studio local {service} connection failed',
    }, { service })
    const mailBody = intl.formatMessage({
      id: 'disconnected.modal..mail.template.body',
      defaultMessage: 'Could you help me with that ?',
    })
    return `mailto:support@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
  }

  const getMessageServiceError = (serviceName: string): JSX.Element => {
    return <>
      <FormattedMessage id="service-disconnected.modal.content.reconnection.failed" defaultMessage={'There was an unexpected issue and therefore Clemex Studio cannot be used.'} />
      <br/>
      <FormattedMessage id="service-disconnected.modal.content.licence.action" defaultMessage={'Please reopen the application to retry.'} />
      <br/>
      <FormattedMessage id="service-disconnected.modal.content.contact" defaultMessage={'If the issue persists please contact Clemex support ('} />
      <a href={mailToStringForContactingSupportOnDesktopError(serviceName)} >support@clemex.com</a>
      <FormattedMessage id="service-disconnected.modal.content.separator" defaultMessage={') with:'} />
      <br/>
      <FormattedMessage id="service-disconnected.modal.content.lost" defaultMessage={'- The technical message is the local {serviceName} connection was lost'} values={{ serviceName }}/>
      <br/>
      <FormattedMessage id="service-disconnected.modal.content.logs" defaultMessage={'- The logs from {logs_path}'} values={{ logs_path: 'C:\\ProgramData\\Clemex\\Studio\\logs' }} />
    </>
  }

  const getMessage = (): JSX.Element => {
    if (licenceHasFailed) {
      return <>
        <FormattedMessage id="desktop-disconnected.modal.content.licence.failed" defaultMessage={'There was an unexpected issue and therefore Clemex Studio cannot be used.'} />
        <br/>
        <FormattedMessage id="desktop-disconnected.modal.content.licence.action" defaultMessage={'Please reopen the application to retry.'} />
      </>
    }
    if (rfAlgorithmHasFailed) {
      return getMessageServiceError(intl.formatMessage({
        id: 'service-disconnected.service.name.algorithm',
        defaultMessage: 'algorithm',
      }))
    }
    if (dlAlgorithmHasFailed) {
      return getMessageServiceError(intl.formatMessage({
        id: 'service-disconnected.service.name.deep-learning',
        defaultMessage: 'deep-learning',
      }))
    }
    if (reconnectionHasFailed) {
      return getMessageServiceError(intl.formatMessage({
        id: 'service-disconnected.service.name.server',
        defaultMessage: 'server',
      }))
    }
    return <FormattedMessage id="server-disconnected.modal.content.reconnecting" defaultMessage={'The local connection was lost: Reconnecting...'} />
  }

  React.useEffect(() => {
    const handleDisconnection = (): void => {
      setIsOpen(true)
    }
    const handleReconnection = (): void => {
      setIsOpen(false)
      setReconnectionHasFailed(false)
    }
    const handleReconnectionFailed = (): void => {
      setReconnectionHasFailed(true)
    }
    const handleLicenceFailed = (): void => {
      setIsOpen(true)
      setLicenceHasFailed(true)
    }
    const handleRfAlgorithmReconnectionFailed = (): void => {
      setIsOpen(true)
      setRfAlgorithmHasFailed(true)
    }
    const handleDlAlgorithmReconnectionFailed = (): void => {
      setIsOpen(true)
      setDlAlgorithmHasFailed(true)
    }

    window.addEventListener(EVENTS_ID.SERVER_DISCONNECTED, handleDisconnection, {})
    window.addEventListener(EVENTS_ID.SERVER_RECONNECTED, handleReconnection, {})
    window.addEventListener(EVENTS_ID.SERVER_RECONNECTION_FAILED, handleReconnectionFailed, {})
    window.addEventListener(EVENTS_ID.SERVER_LICENCE_FAILED, handleLicenceFailed, {})
    window.addEventListener(EVENTS_ID.RF_ALGORITHM_RECONNECTION_FAILED, handleRfAlgorithmReconnectionFailed, {})
    window.addEventListener(EVENTS_ID.DL_ALGORITHM_RECONNECTION_FAILED, handleDlAlgorithmReconnectionFailed, {})
    return () => {
      window.removeEventListener(EVENTS_ID.SERVER_DISCONNECTED, handleDisconnection, {})
      window.removeEventListener(EVENTS_ID.SERVER_RECONNECTED, handleReconnection, {})
      window.removeEventListener(EVENTS_ID.SERVER_RECONNECTION_FAILED, handleReconnectionFailed, {})
      window.removeEventListener(EVENTS_ID.SERVER_LICENCE_FAILED, handleLicenceFailed, {})
      window.removeEventListener(EVENTS_ID.RF_ALGORITHM_RECONNECTION_FAILED, handleRfAlgorithmReconnectionFailed, {})
      window.removeEventListener(EVENTS_ID.DL_ALGORITHM_RECONNECTION_FAILED, handleDlAlgorithmReconnectionFailed, {})
    }
  }, [])

  return <Modal
    width={700}
    open={isOpen}
    closable={false}
    centered={true}
    footer={null}
  >
    <div className={styles.message} >
      {getMessage()}
    </div>
  </Modal>
}
