import type * as React from 'react'
import { RFPage, RFHeader, RFContent } from '@components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { LicensingTagWithExpiration } from '@components/licence/licensing-tag'
import { Space, Typography, Button, Modal } from 'antd'
import styles from './styles/licence-page.module.scss'
import { getLicenceTagProperties, isLicenceExpired, isLicenceExpiringSoon } from '@components/licence/licensing-tag-properties'
import { useLicence } from '@app/api/hooks'
import { MenuContext } from '@components/layout/navigation'
import { KeyboardShortcutsModal } from '@components/modals/keyboard-shortcuts-modal'
import { getLicenceModalContent, getLicenceModalTitle } from '@components/modals/licence-explanation'
import clsx from 'clsx'
const { Title } = Typography
export const LicencePage: React.FC = () => {
  const intl = useIntl()
  const { data: licence, isLoading: isLicenceLoading } = useLicence()
  const [licenceModal, licenceModalContext] = Modal.useModal()

  const licenceProps = getLicenceTagProperties(intl, licence)
  const licenceExpiresSoon = licence === undefined ? false : isLicenceExpiringSoon(licence.expirationDate, licence.duration)
  const licenceIsExpired = licence === undefined ? false : isLicenceExpired(licence.expirationDate)
  const showLicenceExplanationModal = async (): Promise<void> => {
    await licenceModal.info({
      title: getLicenceModalTitle({ intl }),
      content: getLicenceModalContent({ intl }),
    })
  }

  return <RFPage background
    title={
      intl.formatMessage({
        id: 'page.title.licence',
        defaultMessage: 'Licence',
      })}
  >
    <RFHeader
      showProfileMenu={false}
      breadcrumbItem={intl.formatMessage({
        id: 'breadcrumb.page.licence.title',
        defaultMessage: 'Licence',
      })
      }
      navigationContext={MenuContext.LICENCE}
    />
    <RFContent isLoading={isLicenceLoading} className={clsx(styles.licencePage, styles.content)}>
      <KeyboardShortcutsModal/>
      { licenceModalContext }
      <div className={styles.root}>
        <>
          <Space className={styles.planContainer} direction='vertical'>
            <Title level={4} className={styles.licenceTitle}>
              <FormattedMessage id={'page.plan.title'} defaultMessage={'Plan'}/>
            </Title>
            <LicensingTagWithExpiration className={clsx(licenceProps.className, styles.licensingPlan, { [styles.hidden]: isLicenceLoading })} tagIcon={licenceProps.icon} tagText={licenceProps.text} expirationText={licenceProps.expirationText} expirationClassName={licenceProps.expirationClassName}/>
            <Button
              className={clsx(styles.button,
                { [styles.hidden]: !isLicenceLoading && licence !== undefined && licence.isPremium && !licenceIsExpired && !licenceExpiresSoon })}
              onClick={showLicenceExplanationModal}
              type="primary"
            >{
                !isLicenceLoading && (licence === undefined || (!licenceExpiresSoon && !licenceIsExpired))
                  ? <FormattedMessage id='button.upgrade.plan.label' defaultMessage='Upgrade plan'/>
                  : <FormattedMessage id='button.renew.plan.label' defaultMessage='Renew plan'/>
              }
            </Button>
          </Space>
        </>
      </div>
    </RFContent>
  </RFPage>
}
