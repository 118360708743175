import * as RFAPI from '@api/api'
import * as Sentry from '@sentry/react'
import { AlgorithmId, type ErrorMessage } from '@app/api/openapi'
import { ErrorMessageCodes } from '@api/openapi/models/ErrorMessageCodes'
import { EVENTS_ID } from '@app/constants'
import React, { useEffect } from 'react'
import {
  ProjectNotificationType,
  useProjectsNotification,
} from '@app/hooks/notifications'
import { PredictionEventIds, useIsTraining } from '@app/hooks/editor'
import {
  useAlgorithmStatus,
  useFeatureFlags,
  useLicence,
  useProjectActions,
  useProjectSettings,
  useProjectTrainingState,
} from '@app/api/hooks'
import { Flex } from 'antd'
import styles from '../styles/training-section.module.scss'
import { TrainButton } from '@app/pages/editor-page/training/train-button'
import { TrainingElapsedTime } from '@app/pages/editor-page/training/training-elapsed-time'
import { TrainingOptions } from '@app/pages/editor-page/training/training-options'

interface TrainingSectionProps {
  projectId: string
}

export const TrainingSection: React.FC<TrainingSectionProps> = ({
  projectId,
}) => {
  const { triggerTrain } = useProjectActions(projectId)
  const { data: projectTrainingState } = useProjectTrainingState(projectId)
  const { notify } = useProjectsNotification()
  const {
    data: isAlgorithmReady,
    mutate: mutateAlgorithmReady,
    error,
    isLoading: algorithmIsLoading,
  } = useAlgorithmStatus(projectId)
  const [isTraining, setIsTraining] = useIsTraining()
  const { data: licence } = useLicence()
  const { data: projectSettings } = useProjectSettings(projectId)
  const isTrainAvailablePerLicence = projectSettings?.algorithmId === AlgorithmId.QuickClemexnet ? licence !== undefined && licence.algoQuickClemexnetV1 : true
  const { data: flags, isLoading: isFlagsLoading } = useFeatureFlags()
  const [, setIsPredicting] = React.useState<boolean>(false)
  const [isTrainingFailed, setIsTrainingFailed] = React.useState<boolean>(false)

  React.useEffect(() => {
    const onPredictingStarted = (): void => {
      setIsPredicting(true)
    }
    const onPredictingEnded = (): void => {
      setIsPredicting(false)
    }

    window.addEventListener(PredictionEventIds.PREDICTION_STARTING, onPredictingStarted, {})
    window.addEventListener(PredictionEventIds.PREDICTION_FAIL, onPredictingEnded, {})
    window.addEventListener(PredictionEventIds.PREDICTION_SUCCESS, onPredictingEnded, {})
    return () => {
      window.removeEventListener(PredictionEventIds.PREDICTION_STARTING, onPredictingStarted, {})
      window.removeEventListener(PredictionEventIds.PREDICTION_FAIL, onPredictingEnded, {})
      window.removeEventListener(PredictionEventIds.PREDICTION_SUCCESS, onPredictingEnded, {})
    }
  }, [])

  useEffect(() => {
    if (error !== undefined) {
      Sentry.captureException(error)
      notify(projectId, ProjectNotificationType.AlgorithmConnectionFailed)
    }
  }, [error, notify, projectId])

  const onTrain = React.useCallback(async (): Promise<void> => {
    setIsTrainingFailed(false)
    setIsTraining(true)

    try {
      await triggerTrain()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TRAINING_COMPLETED))
      setIsTrainingFailed(false)
    } catch (err: unknown) {
      const errJson: ErrorMessage = await RFAPI.errorResponseToErrorMessage(
        (err as { response: Response }).response,
      )
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_TRAINING_FAILED))
      setIsTrainingFailed(true)
      switch (errJson.code) {
        case ErrorMessageCodes.NotAvailable:
          await mutateAlgorithmReady()
          notify(
            projectId,
            ProjectNotificationType.AlgorithmConnectionLostDuringTraining,
          )
          break
        case ErrorMessageCodes.ForbiddenError:
          await mutateAlgorithmReady()
          notify(
            projectId,
            ProjectNotificationType.TrainingForbidden,
          )
          break
        default:
          notify(projectId, ProjectNotificationType.TrainingUnknown)
          break
      }
      Sentry.captureException(err)
      console.error(err)
    } finally {
      setIsTraining(false)
    }
  }, [mutateAlgorithmReady, notify, projectId, setIsTraining, triggerTrain])

  useEffect(() => {
    window.addEventListener(EVENTS_ID.EDITOR_TRAIN, onTrain, {})
    return () => {
      window.removeEventListener(EVENTS_ID.EDITOR_TRAIN, onTrain, {})
    }
  }, [onTrain])

  return <Flex vertical className={styles.trainingSection}>
    <TrainingElapsedTime projectId={projectId} isTraining={isTraining} isTrainingFailed={isTrainingFailed} />
    {(!isFlagsLoading && flags?.enableTrainingSectionExperimentation === true) && <TrainingOptions projectId={projectId} />}
    <TrainButton
      onClick={onTrain}
      isLoading={algorithmIsLoading}
      isDisabled={isTraining || !(projectTrainingState?.canBeTrain ?? false)}
      isDash={!(isAlgorithmReady ?? false)}
      isTraining={isTraining}
      isTrainAvailablePerLicence={isTrainAvailablePerLicence}
      shouldBeTrain={projectTrainingState?.shouldBeTrain ?? false}
    />
  </Flex>
}
