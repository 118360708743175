import type * as React from 'react'
import { Steps } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/workflow-steps.module.scss'

const StepAnnotate = <FormattedMessage id="header.rf.workflow.steps.step.annotate" description="Annotation step title" defaultMessage={'Annotate'} />
const StepValidate = <FormattedMessage id="header.rf.workflow.steps.step.validate" description="Validation step title" defaultMessage={'Validate'} />

interface WorkflowStepsProps {
  current: number
  onChange: (stepIndex: 0 | 1) => void
}
export const WorkflowSteps: React.FC<WorkflowStepsProps> = ({ current, onChange }) => {
  return (
    <Steps
      onChange={(index) => { onChange(index as 0 | 1) }}
      current={current}
      size="small"
      labelPlacement="vertical"
      className={styles.steps}
      progressDot
      items={[
        {
          title: <div className={styles.stepText}>{StepAnnotate}</div>,
        },
        {
          title: <div id='validate-page-access' className={styles.stepText}>{StepValidate}</div>,
        },
      ]}
    />
  )
}
