import type * as React from 'react'
import { Button, Tooltip } from 'antd'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import styles from './styles/download-button.module.scss'

const useMailToString = (userName: string): string => {
  const intl = useIntl()
  return 'mailto:info@clemex.com?subject=' + intl.formatMessage({
    id: 'download_plugin.mail.template.subject',
    defaultMessage: 'Request {site} access to the plugin download feature for {user}',
  },
  {
    user: userName,
    site: 'clemex.ai',
  },
  )
}

const mailToStringForContactingSupportIncaseOfPluginBuildTrouble = (userName: string, intl: IntlShape): string => {
  const mailSubject = intl.formatMessage({
    id: 'download_plugin.error.mail.template.subject',
    defaultMessage: 'I need some help [User name {userName}]',
  }, { userName })
  const mailBody = intl.formatMessage({
    id: 'download_plugin.error.mail.template.body',
    defaultMessage: 'Could you please help me troubleshoot my issue?',
  })
  return `mailto:info@clemex.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`
}

interface DownloadButtonProps {
  onDownload: () => void
  isDisabled: boolean
  isBuilding: boolean
  isNotAvailable: boolean
  algorithmIsNotAvailable: boolean
  failedToBuild: boolean
  userName: string
}

export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const { onDownload, isDisabled, userName, isBuilding, isNotAvailable, algorithmIsNotAvailable, failedToBuild } = props

  const intl = useIntl()

  const emailHRef = useMailToString(userName)

  if (isDisabled) {
    return <Tooltip
      title={
        <>
          <FormattedMessage id='download_plugin.notification.download.info' defaultMessage='Please contact clemex sales to unlock this feature'/>
          <br/>
          <a href={emailHRef}>info@clemex.com</a>
          <br/>
        1 450 651 6573
          <br/>
        1 888 651 6573
        </>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
        >
          <div className={styles.buttonText}>
            <FormattedMessage id="download_plugin.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (isNotAvailable) {
    return <Tooltip
      title={
        <FormattedMessage id='download_plugin.tooltip.isNotAvailable.message' defaultMessage='Please train your project before downloading the plugin.'/>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
        >
          <div className={styles.buttonText}>
            &nbsp;<FormattedMessage id="download_plugin.isNotAvailable.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (failedToBuild) {
    return <Tooltip
      title={
        <div>
          <FormattedMessage id='download_plugin.tooltip.failedToBuild.message' defaultMessage='Whoops, we could not build your plugin!'/>
          <br />
          <br />
          <FormattedMessage id='download_plugin.tooltip.failedToBuild.description1' defaultMessage='You can try to train again by going to the annotate page and clicking on the "Train" button.'/>
          <br />
          <br />
          <FormattedMessage id='download_plugin.tooltip.failedToBuild.description2' defaultMessage='If you keep having troubles, we advise you to contact us:'/>
          <br />
          <br />
          <a href={mailToStringForContactingSupportIncaseOfPluginBuildTrouble(userName, intl)}>info@clemex.com</a>
        </div>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="dashed"
          size='large'
          icon={'😿'}
          block
          disabled={true}
        >
          <div className={styles.buttonText}>
            &nbsp;<FormattedMessage id="download_plugin.isNotAvailable.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (algorithmIsNotAvailable) {
    return <Tooltip
      title={
        <>
          <FormattedMessage id='download_plugin.tooltip.algorithmIsNotAvailable.description1' defaultMessage='{algorithmName} is not available as a plugin yet.' values={{ algorithmName: 'Quick Clemexnet' }}/>
          <br />
          <FormattedMessage id='download_plugin.tooltip.algorithmIsNotAvailable.description2' defaultMessage='Please contact us if you have any questions:'/>
          <br />
          <br />
          <a href={mailToStringForContactingSupportIncaseOfPluginBuildTrouble(userName, intl)}>info@clemex.com</a>
        </>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
          loading={false}
        >
          <div className={styles.buttonText}>
              &nbsp;<FormattedMessage id="download_plugin.isNotAvailable.title" defaultMessage={'Download Plugin'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else if (isBuilding) {
    return <Tooltip
      title={
        <FormattedMessage id='download_plugin.tooltip.loading.message' defaultMessage='Your plugin is building, it should be ready soon!'/>
      }
    >
      <div className={styles.downloadButton} id='download-button'>
        <Button
          type="default"
          size='large'
          block
          disabled={true}
          loading={true}
        >
          <div className={styles.buttonText}>
            &nbsp;<FormattedMessage id="download_plugin.loading.title" defaultMessage={'Building plugin...'} />
          </div>
        </Button>
      </div>
    </Tooltip>
  } else {
    return <div className={styles.downloadButton} id='download-button'><Button
      type="primary"
      size='large'
      block
      onClick={onDownload}
      disabled={false}
    >
      <div className={styles.buttonText}>
        <FormattedMessage id="download_plugin.title" defaultMessage={'Download Plugin'} />
      </div>
    </Button></div>
  }
}
