/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlgorithmId } from './AlgorithmId';
import {
    AlgorithmIdFromJSON,
    AlgorithmIdFromJSONTyped,
    AlgorithmIdToJSON,
} from './AlgorithmId';
import type { DeepLearningTrainingParametersValueObject } from './DeepLearningTrainingParametersValueObject';
import {
    DeepLearningTrainingParametersValueObjectFromJSON,
    DeepLearningTrainingParametersValueObjectFromJSONTyped,
    DeepLearningTrainingParametersValueObjectToJSON,
} from './DeepLearningTrainingParametersValueObject';
import type { DirectMeasureSettingsValueObject } from './DirectMeasureSettingsValueObject';
import {
    DirectMeasureSettingsValueObjectFromJSON,
    DirectMeasureSettingsValueObjectFromJSONTyped,
    DirectMeasureSettingsValueObjectToJSON,
} from './DirectMeasureSettingsValueObject';
import type { ExportOptionsValueObject } from './ExportOptionsValueObject';
import {
    ExportOptionsValueObjectFromJSON,
    ExportOptionsValueObjectFromJSONTyped,
    ExportOptionsValueObjectToJSON,
} from './ExportOptionsValueObject';
import type { RfAlgorithmTrainingParametersValueObject } from './RfAlgorithmTrainingParametersValueObject';
import {
    RfAlgorithmTrainingParametersValueObjectFromJSON,
    RfAlgorithmTrainingParametersValueObjectFromJSONTyped,
    RfAlgorithmTrainingParametersValueObjectToJSON,
} from './RfAlgorithmTrainingParametersValueObject';

/**
 * Project settings.
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettings
     */
    projectId: string;
    /**
     * 
     * @type {AlgorithmId}
     * @memberof ProjectSettings
     */
    algorithmId: AlgorithmId;
    /**
     * 
     * @type {RfAlgorithmTrainingParametersValueObject}
     * @memberof ProjectSettings
     */
    rfAlgorithmTrainingParameters?: RfAlgorithmTrainingParametersValueObject;
    /**
     * 
     * @type {DirectMeasureSettingsValueObject}
     * @memberof ProjectSettings
     */
    directMeasureSettings?: DirectMeasureSettingsValueObject;
    /**
     * 
     * @type {DeepLearningTrainingParametersValueObject}
     * @memberof ProjectSettings
     */
    deepLearningTrainingParameters?: DeepLearningTrainingParametersValueObject;
    /**
     * 
     * @type {ExportOptionsValueObject}
     * @memberof ProjectSettings
     */
    exportOptions?: ExportOptionsValueObject;
}

/**
 * Check if a given object implements the ProjectSettings interface.
 */
export function instanceOfProjectSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "algorithmId" in value;

    return isInstance;
}

export function ProjectSettingsFromJSON(json: any): ProjectSettings {
    return ProjectSettingsFromJSONTyped(json, false);
}

export function ProjectSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'algorithmId': AlgorithmIdFromJSON(json['algorithm_id']),
        'rfAlgorithmTrainingParameters': !exists(json, 'rf_algorithm_training_parameters') ? undefined : RfAlgorithmTrainingParametersValueObjectFromJSON(json['rf_algorithm_training_parameters']),
        'directMeasureSettings': !exists(json, 'direct_measure_settings') ? undefined : DirectMeasureSettingsValueObjectFromJSON(json['direct_measure_settings']),
        'deepLearningTrainingParameters': !exists(json, 'deep_learning_training_parameters') ? undefined : DeepLearningTrainingParametersValueObjectFromJSON(json['deep_learning_training_parameters']),
        'exportOptions': !exists(json, 'export_options') ? undefined : ExportOptionsValueObjectFromJSON(json['export_options']),
    };
}

export function ProjectSettingsToJSON(value?: ProjectSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'algorithm_id': AlgorithmIdToJSON(value.algorithmId),
        'rf_algorithm_training_parameters': RfAlgorithmTrainingParametersValueObjectToJSON(value.rfAlgorithmTrainingParameters),
        'direct_measure_settings': DirectMeasureSettingsValueObjectToJSON(value.directMeasureSettings),
        'deep_learning_training_parameters': DeepLearningTrainingParametersValueObjectToJSON(value.deepLearningTrainingParameters),
        'export_options': ExportOptionsValueObjectToJSON(value.exportOptions),
    };
}

