/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PluginStatus = {
    IsAvailable: 'IS_AVAILABLE',
    IsNotAvailable: 'IS_NOT_AVAILABLE',
    AlgorithmIsNotAvailable: 'ALGORITHM_IS_NOT_AVAILABLE',
    IsNotAllowed: 'IS_NOT_ALLOWED',
    IsBuilding: 'IS_BUILDING',
    Failed: 'FAILED'
} as const;
export type PluginStatus = typeof PluginStatus[keyof typeof PluginStatus];


export function PluginStatusFromJSON(json: any): PluginStatus {
    return PluginStatusFromJSONTyped(json, false);
}

export function PluginStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PluginStatus {
    return json as PluginStatus;
}

export function PluginStatusToJSON(value?: PluginStatus | null): any {
    return value as any;
}

