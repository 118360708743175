import * as React from 'react'
import * as RFAPI from '@api/api'
import styles from './styles/profile-page.module.scss'
import { RFPage, RFHeader, RFContent } from '@components/layout'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, Typography, Form, Input, Button, Space, Row, notification, Divider } from 'antd'
import { KeyboardShortcutsModal } from '@components/modals/keyboard-shortcuts-modal'
import {
  UserOutlined,
} from '@ant-design/icons'
import Phone from '@material-design-icons/svg/round/phone.svg'
import Work from '@material-design-icons/svg/round/work.svg'
import Domain from '@material-design-icons/svg/round/domain.svg'
import LockKey from '@material-design-icons/svg/round/key.svg'
import clsx from 'clsx'
import { useFeatureFlags, useLicence, useProfile, userProfileFetcher } from '@app/api/hooks'
import * as Sentry from '@sentry/browser'
import { preload } from 'swr'
import { NotAuthorized } from '@app/pages/not-authorized'
import { ErrorPage } from '@app/pages/error-page'
import { LicensingTagWithExpiration } from '@components/licence/licensing-tag'
import { getLicenceTagProperties, isLicenceExpired, isLicenceExpiringSoon } from '@components/licence/licensing-tag-properties'
import { MenuContext } from '@components/layout/navigation'
import { getLicenceModalContent, getLicenceModalTitle } from '@components/modals/licence-explanation'
import { CACHE_KEYS } from '@app/api/cache-keys'
import { GlobalSettingsModal } from '@components/modals/global-settings/global-settings-modal'

const { Title } = Typography

export interface ProfileFormResult {
  firstName: string
  lastName: string
  jobTitle?: string
  organization?: string
  phoneNumber?: string
  hasPassword: boolean
}
export interface ProfilePasswordFormResult {
  oldPassword: string
  newPassword: string
  hasPassword: boolean
}
export const ProfilePage: React.FC = () => {
  const [form] = Form.useForm()
  const [formPassword] = Form.useForm()

  const intl = useIntl()
  const [isFormUnchanged, setIsFormUnchanged] = React.useState(true)

  const { data: userProfile, mutate: mutateProfile, error: useProfileError, isValidating: userProfileLoading } = useProfile()
  const { data: featureFlags } = useFeatureFlags()
  const { data: licence, isLoading: isLicenceLoading } = useLicence()
  const [profileUpdateNotification, profileUpdateContextHolder] = notification.useNotification()
  const [licenceModal, licenceModalContext] = Modal.useModal()

  React.useEffect(() => {
    void preload(CACHE_KEYS.USER_PROFILE, userProfileFetcher)
  }, [])

  if (useProfileError !== undefined) {
    if (useProfileError.response?.status === 403) {
      return <NotAuthorized/>
    } else {
      return <ErrorPage/>
    }
  }

  const onSubmit = async (formData: ProfileFormResult): Promise<void> => {
    if (userProfile === undefined) {
      return
    }
    try {
      await RFAPI.updateProfile(formData)
      const updatedData = {
        ...userProfile,
        ...formData,
      }
      void mutateProfile(updatedData)
      profileUpdateNotification.success({
        message: intl.formatMessage({
          id: 'profile.notification.success.title',
          defaultMessage: 'Success!',
        }),
        description: intl.formatMessage({
          id: 'profile.notification.success.body',
          defaultMessage: 'Profile updated successfully',
        }),
      })
    } catch (error) {
      profileUpdateNotification.error({
        message: intl.formatMessage({
          id: 'profile.notification.error.title',
          defaultMessage: 'Error',
        }),
        description: intl.formatMessage({
          id: 'profile.notification.error.body',
          defaultMessage: 'Something went wrong during profile update, please retry. If it persists please contact Clemex support (support@clemex.com).',
        }),
      })
      Sentry.captureException(error)
    }
  }
  const onSubmitPassword = async (formData: ProfilePasswordFormResult): Promise<void> => {
    // Ensure the password is defined to avoid any submit out of frontend
    if (formData.oldPassword !== undefined && formData.oldPassword !== '' && formData.newPassword !== undefined && formData.newPassword !== '') {
      try {
        await RFAPI.updatePassword(formData.oldPassword, formData.newPassword)
        void mutateProfile()
        profileUpdateNotification.success({
          message: intl.formatMessage({
            id: 'profile.password.notification.success.title',
            defaultMessage: 'Success!',
          }),
          description: intl.formatMessage({
            id: 'profile.password.notification.success.body',
            defaultMessage: 'Password updated successfully',
          }),
        })
      } catch (error) {
        profileUpdateNotification.error({
          message: intl.formatMessage({
            id: 'profile.password.notification.error.title',
            defaultMessage: 'Error',
          }),
          description: intl.formatMessage({
            id: 'profile.password.notification.error.body',
            defaultMessage: 'Something went wrong during password update, please retry. If it persists please contact Clemex support (support@clemex.com).',
          }),
        })
        Sentry.captureException(error)
      } finally {
        formPassword.resetFields()
      }
    }
  }
  const licenceProps = getLicenceTagProperties(intl, licence)
  const licenceExpiresSoon = licence === undefined ? false : isLicenceExpiringSoon(licence.expirationDate, licence.duration)
  const licenceIsExpired = licence === undefined ? false : isLicenceExpired(licence.expirationDate)
  const showLicenceExplanationModal = async (): Promise<void> => {
    await licenceModal.info({
      title: getLicenceModalTitle({ intl }),
      content: getLicenceModalContent({ intl }),
    })
  }
  return <RFPage background
    title={intl.formatMessage({
      id: 'page.title.profile',
      defaultMessage: 'My profile',
    })}
  >
    <RFHeader
      showProfileMenu={!(featureFlags?.enableLocalUser ?? false)}
      breadcrumbItem={
        intl.formatMessage({
          id: 'breadcrumb.page.profile.title',
          defaultMessage: 'Profile',
        })
      }
      navigationContext={MenuContext.PROFILE}
    >
    </RFHeader>
    <RFContent isLoading={userProfileLoading} className={clsx(styles.profileContent, { [styles.narrowProfileContent]: userProfile?.hasPassword !== true })}>
      <KeyboardShortcutsModal/>
      <GlobalSettingsModal />
      { licenceModalContext }
      <div className={styles.root}>
        <Space className={styles.planContainer} direction='vertical'>
          <Title level={4} className={clsx(styles.licenceTitle, styles.profileTitle)}>
            <FormattedMessage id={'page.plan.title'} defaultMessage={'Plan'}/>
          </Title>
          <LicensingTagWithExpiration className={clsx(licenceProps.className, { [styles.hidden]: isLicenceLoading })} tagIcon={licenceProps.icon} tagText={licenceProps.text} expirationText={licenceProps.expirationText} expirationClassName={licenceProps.expirationClassName}/>
          <Button
            className={clsx(styles.button,
              { [styles.hidden]: !isLicenceLoading && licence !== undefined && licence.isPremium && !licenceIsExpired && !licenceExpiresSoon })}
            type="primary"
            htmlType="submit"
            onClick={showLicenceExplanationModal}
          >{
              !isLicenceLoading && (licence === undefined || (!licenceExpiresSoon && !licenceIsExpired))
                ? <FormattedMessage id='button.upgrade.plan.label' defaultMessage='Upgrade plan'/>
                : <FormattedMessage id='button.renew.plan.label' defaultMessage='Renew plan'/>
            }
          </Button>
        </Space>
        <div className={styles.forms}>

          {profileUpdateContextHolder}
          <Form labelCol={{ span: 1 }} className={styles.form} form={form} onFinish={onSubmit} initialValues={userProfile} onChange={() => { setIsFormUnchanged(false) }} layout='vertical'>
            <Space className={styles.profileTitle} direction='vertical'>
              <Title level={4}>
                <FormattedMessage id={'page.profile.title'} defaultMessage={'Profile'} />
              </Title>
            </Space>
            <Form.Item
              name={'firstName'}
              label={<FormattedMessage id={'page.profile.form.firstName.label'} defaultMessage={'First name'} />}
              required={true}
              rules={[
                {
                  required: true,
                  type: 'string',
                  min: 2,
                  message: <FormattedMessage id="page.profile.form.error.required.firstName" defaultMessage={'Please input your first name.'} />,
                }]}
            >
              <Input
                prefix={<UserOutlined className={styles.inputIcon}/>}
                placeholder={intl.formatMessage({
                  id: 'page.profile.form.firstName.placeholder',
                  defaultMessage: 'Zacharias',
                })}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={intl.formatMessage({
                id: 'page.profile.form.lastName.label',
                defaultMessage: 'Last name',
              })}
              required={true}
              rules={[
                {
                  required: true,
                  type: 'string',
                  min: 2,
                  message: <FormattedMessage id="page.profile.form.error.required.lastName" defaultMessage={'Please input your last name.'} />,
                }]}
            >
              <Input prefix={<UserOutlined className={styles.inputIcon}/>} placeholder={intl.formatMessage({
                id: 'page.profile.form.lastName.placeholder',
                defaultMessage: 'Janssen',
              })} />
            </Form.Item>
            <Form.Item
              name="organization"
              label={intl.formatMessage({
                id: 'page.profile.form.organization.label',
                defaultMessage: 'Organization',
              })}

            >
              <Input prefix={<Domain className={styles.inputIcon}/>} />
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label={intl.formatMessage({
                id: 'page.profile.form.jobTitle.label',
                defaultMessage: 'Job title',
              })}
            >
              <Input prefix={<Work className={styles.inputIcon}/>} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={intl.formatMessage({
                id: 'page.profile.form.phoneNumber.label',
                defaultMessage: 'Phone number',
              })}
            >
              <Input prefix={<Phone className={styles.inputIcon}/>}/>
            </Form.Item>
            <Form.Item>
              <Row className={styles.flexEnd}>
                <Button key={'reset'} className={styles.button} disabled={isFormUnchanged} onClick={(): void => { form.resetFields(); setIsFormUnchanged(true) }}>
                  <FormattedMessage id="page.profile.form.activateAccount.reset" defaultMessage={'Reset'} />
                </Button>
                <Button type="primary" className={styles.button} htmlType="submit">
                  <FormattedMessage id="page.profile.form.activateAccount.submit" defaultMessage={'Save'} />
                </Button>
              </Row>
            </Form.Item>
          </Form>
          {userProfile?.hasPassword === true &&
         <Divider className={styles.divider} type='vertical'/> }
          {userProfile?.hasPassword === true &&
         <Form labelCol={{ span: 6 }} className={styles.form} form={formPassword} onFinish={onSubmitPassword} layout='vertical'>
           <Space className={styles.profileTitle}>
             <Title level={4}>
               <FormattedMessage id={'page.profile.password.title'} defaultMessage={'Password'} />
             </Title>
           </Space>
           <Form.Item
             name="oldPassword"
             rules={[
               {
                 required: true,
                 min: 8,
                 message: <FormattedMessage id="page.profile.form.error.required.currentPassword" defaultMessage={'Please input your password.'} />,
               }]}
             label={intl.formatMessage({
               id: 'page.profile.form.currentPassword.label',
               defaultMessage: 'Current password',
             })}
           >
             <Input.Password
               prefix={<LockKey className={styles.inputIcon}/>}
             />
           </Form.Item>
           <Form.Item
             name="newPassword"
             tooltip={intl.formatMessage({
               id: 'page.profile.form.tooltip.password',
               defaultMessage: 'Password must contain at least 8 characters.',
             })}
             rules={[
               {
                 required: true,
                 min: 8,
                 message: <FormattedMessage id="page.profile.form.error.required.password" defaultMessage={'Please input a valid password.'} />,
               },

               ({ getFieldValue }) => ({
                 async validator (_, value: string | undefined | null) {
                   if (value === null || value === undefined || getFieldValue('oldPassword') !== undefined || getFieldValue('confirmPassword') !== undefined || getFieldValue('oldPassword') === null) {
                     if (getFieldValue('oldPassword') !== value || getFieldValue('oldPassword') === null) {
                       await Promise.resolve()
                       return
                     }
                     return await Promise.reject(new Error(
                       intl.formatMessage({
                         id: 'page.profile.form.error.oldPassword.NewPassword.Same',
                         defaultMessage: 'The current and new passwords are the same!',
                       }),
                     ))
                   }
                   return await Promise.reject(new Error(
                     intl.formatMessage({
                       id: 'page.profile.form.error.oldPassword.NotDefined',
                       defaultMessage: 'The current password is empty!',
                     }),
                   ))
                 },
               }),
             ]}
             label={intl.formatMessage({
               id: 'page.profile.form.password.label',
               defaultMessage: 'Password',
             })}
           >
             <Input.Password
               prefix={<LockKey className={styles.inputIcon}/>}
             />
           </Form.Item>

           <Form.Item
             name="confirmPassword"
             dependencies={['password']}
             hasFeedback
             tooltip={intl.formatMessage({
               id: 'page.profile.form.tooltip.confirmPassword',
               defaultMessage: 'Same as password.',
             })}
             rules={[
               {
                 required: true,
                 message: <FormattedMessage id="page.profile.form.item.confirmPassword.rule.required.message" defaultMessage="Please confirm your password!" />,
               },
               ({ getFieldValue }) => ({
                 async validator (_, value: string | undefined | null) {
                   if (value === null || value === undefined || getFieldValue('newPassword') === value) {
                     await Promise.resolve()
                     return
                   }
                   return await Promise.reject(new Error(
                     intl.formatMessage({
                       id: 'page.profile.form.error.twoPasswords.notIdentical',
                       defaultMessage: 'The two passwords do not match!',
                     }),
                   ))
                 },
               }),

             ]}
             label={intl.formatMessage({
               id: 'page.profile.form.confirmPassword.label',
               defaultMessage: 'Confirm Password',
             })}

           >
             <Input.Password
               prefix={<LockKey className={styles.inputIcon} />}
             />
           </Form.Item>
           <Form.Item>
             <Row className={styles.flexEnd}>
               <Button type="primary" className={styles.button} htmlType="submit">
                 <FormattedMessage id="page.profile.form.changePassword.submit" defaultMessage={'Change password'} />
               </Button>
             </Row>
           </Form.Item>
         </Form>
          }
        </div>
        <Form.Item className={clsx(styles.link, styles.labelTerms)} >
          <FormattedMessage
            id="page.profile.form.link.term-use.label"
            defaultMessage="{termAndConditions} and {privacyPolicy}"
            values={{
              termAndConditions: <a href={'/api/assets/pdf_tou'} target='_blank' rel="noreferrer">
                <FormattedMessage
                  id="page.profile.form.link.term-use.url"
                  defaultMessage={'Terms and Conditions'}
                />
              </a>,
              privacyPolicy: <a href={'https://clemex.com/privacy-policy/'} target='_blank' rel="noreferrer">
                <FormattedMessage
                  id="page.profile.form.link.privacy-policy.url"
                  defaultMessage={'Privacy Policy'}
                />
              </a>,
            }}
          />
        </Form.Item>
      </div>
    </RFContent>
  </RFPage >
}
