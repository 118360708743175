import { ProjectDatasetContext } from '@app/api/openapi'
import { DEFAULT_ANNOTATION_OPACITY, DEFAULT_BRUSH_SIZE, DEFAULT_PREDICTION_OPACITY, Tool } from '@app/constants'
import { type SelectionModes } from '@clemex/mosaic-canvas'
import { type Feature } from 'ol'
import { type Geometry } from 'ol/geom'
import { create } from 'zustand'

interface PrivateEditorStoreState {
  _globalAnnotationOpacity: number
  _globalPredictionOpacity: number
}

export interface EditorStoreState {
  selectedImageId: string | undefined
  selectedTool: Tool
  disabledTools: Tool[]
  selectionModes: SelectionModes
  selectedAnnotationClassColorIndex: number
  brushSize: number
  hiddenAnnotationClassIndexes: number[]
  hiddenPredictionClassIndexes: number[]
  getGlobalAnnotationOpacity: () => number
  globalAnnotationVisibility: boolean
  globalAnnotationFullOpacity: boolean
  getGlobalPredictionOpacity: () => number
  globalPredictionVisibility: boolean
  globalPredictionFullOpacity: boolean
  directMeasureAndMetadataAnnotationVisibility: boolean
  isTraining: boolean
  isDirectMeasureAreaToolFreehand: boolean
  isDirectMeasurePerimeterToolFreehand: boolean
  isMetadataAnnotationPolygonToolFreehand: boolean
  isMetadataAnnotationLineToolFreehand: boolean
  selectedItems: Array<Feature<Geometry>>
}

export interface EditorStoreAction {
  setSelectedImage: (newImageId: string | undefined) => void
  setSelectedTool: (newTool: Tool) => void
  setSelectedAnnotationClassColorIndex: (newSelectedClassColorIndex: number) => void
  setBrushSize: (newBrushSize: number) => void
  setHiddenAnnotationClassIndexes: (newHiddenClassIndexes: number[]) => void
  setHiddenPredictionClassIndexes: (newHiddenPredictionClassIndexes: number[]) => void
  setGlobalAnnotationOpacity: (newOpacity: number) => void
  setGlobalAnnotationVisibility: (newVisibility: boolean) => void
  setGlobalAnnotationFullOpacity: (newFullOpacity: boolean) => void
  setGlobalPredictionOpacity: (newOpacity: number) => void
  setGlobalPredictionVisibility: (newVisibility: boolean) => void
  setGlobalPredictionFullOpacity: (newFullOpacity: boolean) => void
  setDirectMeasureAndMetadataAnnotationVisibility: (newVisibility: boolean) => void
  setIsTraining: (isTraining: boolean) => void
  setIsDirectMeasureAreaToolFreehand: (isFreehand: boolean) => void
  setIsDirectMeasurePerimeterToolFreehand: (isFreehand: boolean) => void
  setIsMetadataAnnotationPolygonToolFreehand: (isFreehand: boolean) => void
  setIsMetadataAnnotationLineToolFreehand: (isFreehand: boolean) => void
  resetFromContext: (context: ProjectDatasetContext) => void
  setSelectedItems: (selectedItems: Array<Feature<Geometry>>) => void
}

export const useEditorStore = create<PrivateEditorStoreState & EditorStoreState & EditorStoreAction>((set, get) => ({
  selectedImageId: undefined,
  selectedTool: Tool.BRUSH,
  disabledTools: [],
  selectionModes: {
    classAnnotations: false,
    directMeasurementAnnotations: true,
    metadataAnnotations: true,
  },
  selectedAnnotationClassColorIndex: 0,
  brushSize: DEFAULT_BRUSH_SIZE,
  hiddenAnnotationClassIndexes: [],
  hiddenPredictionClassIndexes: [],
  _globalAnnotationOpacity: DEFAULT_ANNOTATION_OPACITY,
  getGlobalAnnotationOpacity: () => {
    return get().globalAnnotationVisibility
      ? (get().globalAnnotationFullOpacity
          ? 1
          : get()._globalAnnotationOpacity)
      : 0
  },
  globalAnnotationVisibility: true,
  globalAnnotationFullOpacity: false,
  _globalPredictionOpacity: DEFAULT_PREDICTION_OPACITY,
  getGlobalPredictionOpacity: () => {
    return get().globalPredictionVisibility
      ? (get().globalPredictionFullOpacity
          ? 1
          : get()._globalPredictionOpacity)
      : 0
  },
  globalPredictionVisibility: true,
  globalPredictionFullOpacity: false,
  directMeasureAndMetadataAnnotationVisibility: true,
  isTraining: false,
  isDirectMeasureAreaToolFreehand: false,
  isDirectMeasurePerimeterToolFreehand: false,
  isMetadataAnnotationPolygonToolFreehand: false,
  isMetadataAnnotationLineToolFreehand: false,
  selectedItems: [],
  setSelectedImage: (newImageId: string | undefined) => {
    set(() => ({ selectedImageId: newImageId }))
  },
  setSelectedTool: (newTool: Tool) => {
    set(() => ({ selectedTool: newTool }))
  },
  setSelectedAnnotationClassColorIndex: (newSelectedClassColorIndex: number) => {
    set(() => ({
      selectedAnnotationClassColorIndex: newSelectedClassColorIndex,
    }))
  },
  setBrushSize: (newBrushSize: number) => {
    set(() => ({ brushSize: newBrushSize }))
  },
  setHiddenAnnotationClassIndexes: (newHiddenClassIndexes: number[]) => {
    set(() => ({ hiddenAnnotationClassIndexes: newHiddenClassIndexes }))
  },
  setHiddenPredictionClassIndexes: (newHiddenPredictionClassIndexes: number[]) => {
    set(() => ({ hiddenPredictionClassIndexes: newHiddenPredictionClassIndexes }))
  },
  setGlobalAnnotationOpacity: (newOpacity: number) => {
    set(() => ({ _globalAnnotationOpacity: newOpacity, globalAnnotationVisibility: true, globalAnnotationFullOpacity: false }))
  },
  setGlobalAnnotationVisibility: (newVisibility: boolean) => {
    set(() => ({ globalAnnotationVisibility: newVisibility, globalAnnotationFullOpacity: false }))
  },
  setGlobalAnnotationFullOpacity: (newFullOpacity: boolean) => {
    set(() => ({ globalAnnotationFullOpacity: newFullOpacity, globalAnnotationVisibility: true }))
  },
  setGlobalPredictionOpacity: (newOpacity: number) => {
    set(() => ({ _globalPredictionOpacity: newOpacity, globalPredictionVisibility: true, globalPredictionFullOpacity: false }))
  },
  setGlobalPredictionVisibility: (newVisibility: boolean) => {
    set(() => ({ globalPredictionVisibility: newVisibility, globalPredictionFullOpacity: false }))
  },
  setGlobalPredictionFullOpacity: (newFullOpacity: boolean) => {
    set(() => ({ globalPredictionFullOpacity: newFullOpacity, globalPredictionVisibility: true }))
  },
  setDirectMeasureAndMetadataAnnotationVisibility: (newVisibility: boolean) => {
    set(() => ({ directMeasureAndMetadataAnnotationVisibility: newVisibility }))
  },
  setIsTraining: (isTraining: boolean) => {
    set(() => ({ isTraining }))
  },
  setIsDirectMeasureAreaToolFreehand: (isFreehand: boolean) => {
    set(() => ({ isDirectMeasureAreaToolFreehand: isFreehand }))
  },
  setIsDirectMeasurePerimeterToolFreehand: (isFreehand: boolean) => {
    set(() => ({ isDirectMeasurePerimeterToolFreehand: isFreehand }))
  },
  setIsMetadataAnnotationPolygonToolFreehand: (isFreehand: boolean) => {
    set(() => ({ isMetadataAnnotationPolygonToolFreehand: isFreehand }))
  },
  setIsMetadataAnnotationLineToolFreehand: (isFreehand: boolean) => {
    set(() => ({ isMetadataAnnotationLineToolFreehand: isFreehand }))
  },
  resetFromContext: (context: ProjectDatasetContext) => {
    set(() => ({
      hiddenAnnotationClassIndexes: [],
      hiddenPredictionClassIndexes: [],
      globalAnnotationVisibility: true,
      globalAnnotationFullOpacity: false,
      globalPredictionVisibility: true,
      _globalPredictionOpacity: DEFAULT_PREDICTION_OPACITY,
      selectedTool: context === ProjectDatasetContext.Training ? Tool.BRUSH : Tool.PAN,
      disabledTools: context === ProjectDatasetContext.Training ? [] : [Tool.BRUSH, Tool.ERASER],
    }))
  },
  setSelectedItems: (selectedItems: Array<Feature<Geometry>>) => {
    set(() => ({ selectedItems }))
  },
}))
