import * as RFAPI from './api/api'
import * as React from 'react'
import { useParams, useLocation, useMatch, Route as ReactRouterRoute, useNavigate } from 'react-router'
import { type RouteProps as ReactRouterDomRouteProps } from 'react-router-dom'
import { EditorPage } from '@app/pages/editor-page/editor-page'
import { ProjectsListPage } from './pages/projects-list'
import { NotFound } from './pages/not-found'
import { LoginPage } from '@app/pages/authentication/login'
import { CreatePasswordPage } from '@app/pages/authentication/create-password'
import { RequestResetPasswordPage } from '@app/pages/authentication/request-reset-password'
import { ResetPasswordPage } from '@app/pages/authentication/reset-password'
import { MaintenancePage } from '@app/pages/maintenance'
import { RegisterPage } from '@app/pages/authentication/register'
import { ActivateAccountPage } from '@app/pages/authentication/activate-account'
import { LicencePage } from '@app/pages/licence'
import { Routes } from 'react-router-dom'
import { uniq } from 'lodash'
import * as Sentry from '@sentry/react'
import { ProfilePage } from '@app/pages/profile-page'
import { PageName } from '@app/api/openapi'
import { useIntl } from 'react-intl'
import { useSWRConfig } from 'swr'
import { useBaseNotificationStore } from '@app/hooks/notifications'

const EditorPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const { projectSlug } = useParams<{ projectSlug: string, currentWorkflow: string }>()
  const workflowStep = projectSlug === undefined || window.location.href.includes(`/${projectSlug}/annotate`) ? 0 : 1
  return <EditorPage projectSlug={projectSlug ?? ''} currentWorkflowStep={workflowStep} />
}

const NotFoundRoute: React.FC<ReactRouterDomRouteProps> = () => {
  return <NotFound />
}
const MaintenancePageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const redirectionUrl = searchParams.get('redirectionUrl')
  return <MaintenancePage
    previousUrl={redirectionUrl ?? ''} />
}
const RegisterPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  return <RegisterPage />
}
const ActivateAccountPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const { activateAccountToken = '' } = useParams<{ activateAccountToken: string }>()
  return <ActivateAccountPage token={activateAccountToken} />
}
const LoginPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  return <LoginPage />
}
const LogoutPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()
  const { addNotification } = useBaseNotificationStore()

  React.useEffect(() => {
    void (async () => {
      try {
        await RFAPI.logout()
      } catch (error) {
        /* Logout errors are not useful */
      } finally {
        // This flush the local storage cache
        // see https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
        void mutate(
          () => true,
          undefined,
          { revalidate: false },
        )

        addNotification({
          type: 'success',
          message: intl.formatMessage({
            id: 'login.notification.logout.title',
            defaultMessage: 'Successful Logout',
          }),
          description: intl.formatMessage({
            id: 'login.notification.logout.body',
            defaultMessage: 'Good bye. See you soon.',
          }),
        })

        navigate(WebUIRoutes.authentication().path)
      }
    })()
  }, [intl, navigate, mutate, addNotification])
  return <LoginPage />
}
const CreatePasswordPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const { createPasswordToken = '' } = useParams<{ createPasswordToken: string }>()
  return <CreatePasswordPage createPasswordToken={createPasswordToken} />
}

const RequestResetPasswordPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  return <RequestResetPasswordPage />
}

const ResetPasswordPageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  const { resetPasswordToken = '' } = useParams<{ resetPasswordToken: string }>()
  return <ResetPasswordPage resetPasswordToken={resetPasswordToken} />
}
const LicencePageRoute: React.FC<ReactRouterDomRouteProps> = () => {
  return <LicencePage/>
}

const encodeQueryParams = (data: Record<string, string>): string => {
  return Object.keys(data).map(function (key) {
    return [key, data[key]].map(encodeURIComponent).join('=')
  }).join('&')
}

interface ClemexStudioWebUIRoute {
  path: string
  name?: PageName
  search?: string
}

export const WebUIRoutes = {
  basePath: (): ClemexStudioWebUIRoute => ({ path: '/' }),
  authentication: (): ClemexStudioWebUIRoute => ({ path: '/', name: PageName.Home }),
  register: (): ClemexStudioWebUIRoute => ({ path: '/register', name: PageName.Register }),
  activateAccount: (token = ':activateAccountToken'): ClemexStudioWebUIRoute => ({ path: `/activate-account/${token}`, name: PageName.ActivateAccount }),
  createPassword: (token = ':createPasswordToken'): ClemexStudioWebUIRoute => ({ path: `/create-password/${token}`, name: PageName.CreatePassword }),
  requestResetPassword: (): ClemexStudioWebUIRoute => ({ path: '/request-reset-password', name: PageName.RequestResetPassword }),
  resetPassword: (token = ':resetPasswordToken'): ClemexStudioWebUIRoute => ({ path: `/reset-password/${token}`, name: PageName.ResetPassword }),
  projectList: (searchParams: { sharedProjectToken?: string } = {}): ClemexStudioWebUIRoute => ({ path: '/projects', name: PageName.Projects, search: encodeQueryParams(searchParams) }),
  annotateProject: (projectSlug = ':projectSlug', searchParams: { image?: string } = {}): ClemexStudioWebUIRoute => ({ path: `/projects/${projectSlug}/annotate`, name: PageName.ProjectsProjectIdAnnotate, search: encodeQueryParams(searchParams) }),
  validateProject: (projectSlug = ':projectSlug', searchParams: { image?: string } = {}): ClemexStudioWebUIRoute => ({ path: `/projects/${projectSlug}/validate`, name: PageName.ProjectsProjectIdValidate, search: encodeQueryParams(searchParams) }),
  newProject: (): ClemexStudioWebUIRoute => ({ path: '/projects/new', name: PageName.ProjectsNew }),
  logout: (): ClemexStudioWebUIRoute => ({ path: '/logout', name: PageName.Logout }),
  profile: (): ClemexStudioWebUIRoute => ({ path: '/profile', name: PageName.Profile }),
  maintenance: (): ClemexStudioWebUIRoute => ({ path: '/503', name: PageName._503Maintenance }),
  licence: (): ClemexStudioWebUIRoute => ({ path: '/licence', name: PageName.Licence }),
}

export const StudioRouter: React.FC = () => {
  const location = useLocation()

  const matchedRoutesName = uniq(Object.values(WebUIRoutes)
    .map((routeCb) => routeCb())
    .map((route) => {
      // The number off unique WebUIRoutes never change
      // The number of call to `useMath` hook will remain the same at each render
      // Thus we can ssafely disable the eslint rule
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const match = useMatch(route.path)
      return match !== null ? route.name : undefined
    })
    .filter((maybeRoute): maybeRoute is PageName => maybeRoute !== undefined))

  React.useEffect(() => {
    matchedRoutesName.forEach((routeName) => {
      void RFAPI.ingestPageAccess(routeName)
    })

    if (matchedRoutesName.length === 0) {
      const message = `No route matched location ${location.pathname}`
      console.warn(message)
    }
    if (matchedRoutesName.length > 1) {
      const message = `More than one route matched location ${location.pathname}: ${JSON.stringify(matchedRoutesName)}`
      console.error(message)
      Sentry.captureMessage(message)
    }
  }, [location.pathname, matchedRoutesName])

  const editorPage = <EditorPageRoute/>

  return <Routes>
    <ReactRouterRoute path={WebUIRoutes.authentication().path} element={<LoginPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.register().path} element={<RegisterPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.activateAccount().path} element={<ActivateAccountPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.createPassword().path} element={<CreatePasswordPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.requestResetPassword().path} element={<RequestResetPasswordPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.resetPassword().path} element={<ResetPasswordPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.logout().path} element={<LogoutPageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.profile().path} element={<ProfilePage />} />
    <ReactRouterRoute path={WebUIRoutes.projectList().path} element={<ProjectsListPage />} />
    <ReactRouterRoute path={WebUIRoutes.annotateProject().path} element={editorPage} />
    <ReactRouterRoute path={WebUIRoutes.validateProject().path} element={editorPage} />
    <ReactRouterRoute path={WebUIRoutes.maintenance().path} element={<MaintenancePageRoute />} />
    <ReactRouterRoute path={WebUIRoutes.licence().path} element={<LicencePageRoute />}/>
    <ReactRouterRoute path="*" element={<NotFoundRoute />} />
  </Routes>
}
