/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EllipseGeometryProperties
 */
export interface EllipseGeometryProperties {
    /**
     * 
     * @type {string}
     * @memberof EllipseGeometryProperties
     */
    geometryType: EllipseGeometryPropertiesGeometryTypeEnum;
    /**
     * 
     * @type {Array}
     * @memberof EllipseGeometryProperties
     */
    center: Array;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    radiusX: number;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    radiusY: number;
    /**
     * 
     * @type {number}
     * @memberof EllipseGeometryProperties
     */
    angle: number;
}


/**
 * @export
 */
export const EllipseGeometryPropertiesGeometryTypeEnum = {
    Ellipse: 'ELLIPSE'
} as const;
export type EllipseGeometryPropertiesGeometryTypeEnum = typeof EllipseGeometryPropertiesGeometryTypeEnum[keyof typeof EllipseGeometryPropertiesGeometryTypeEnum];


/**
 * Check if a given object implements the EllipseGeometryProperties interface.
 */
export function instanceOfEllipseGeometryProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "geometryType" in value;
    isInstance = isInstance && "center" in value;
    isInstance = isInstance && "radiusX" in value;
    isInstance = isInstance && "radiusY" in value;
    isInstance = isInstance && "angle" in value;

    return isInstance;
}

export function EllipseGeometryPropertiesFromJSON(json: any): EllipseGeometryProperties {
    return EllipseGeometryPropertiesFromJSONTyped(json, false);
}

export function EllipseGeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EllipseGeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometryType': json['geometry_type'],
        'center': json['center'],
        'radiusX': json['radius_x'],
        'radiusY': json['radius_y'],
        'angle': json['angle'],
    };
}

export function EllipseGeometryPropertiesToJSON(value?: EllipseGeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry_type': value.geometryType,
        'center': value.center,
        'radius_x': value.radiusX,
        'radius_y': value.radiusY,
        'angle': value.angle,
    };
}

