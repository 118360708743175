import { message } from 'antd'
import * as React from 'react'
import { type ArgsProps } from 'antd/lib/message'
import { FormattedMessage } from 'react-intl'

interface BaseMessageProps {
  isOpen: boolean
}
interface MessageProps extends ArgsProps {
}
export const BaseMessage: React.FC<BaseMessageProps & MessageProps> = ({ isOpen, ...messageProps }) => {
  const [api, messageContextHolder] = message.useMessage()

  React.useEffect(() => {
    if (isOpen) {
      const messageDestroyer = api.open({
        ...messageProps,
        onClose: () => {
          if (messageProps.onClose !== undefined) {
            messageProps.onClose()
          }
        },
      })
      return () => {
        messageDestroyer()
      }
    }
    return () => { /* noop */ }
  // XXX: cannot use messageProps as dependency because it will trigger an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, api])
  return messageContextHolder
}

interface MessageWaitingAlgorithmAvailableProps {
  isAlgorithmReady: boolean
  isPreparingAlgorithm: boolean
}
export const MessageWaitingAlgorithmAvailable: React.FC<MessageWaitingAlgorithmAvailableProps> = ({ isPreparingAlgorithm, isAlgorithmReady }) => {
  return <BaseMessage
    type='loading'
    content={<FormattedMessage id='message.waiting-algorithm-available.content' defaultMessage='Connecting to Clemex Studio algorithm engine...' />}
    duration={0}
    isOpen={isPreparingAlgorithm && !isAlgorithmReady}
  />
}

interface MessageAlgorithmConnectedProps {
  isAlgorithmReady: boolean
  isPreparingAlgorithm: boolean
}
export const MessageAlgorithmConnected: React.FC<MessageAlgorithmConnectedProps> = ({ isAlgorithmReady, isPreparingAlgorithm }) => {
  const [wasPreparingAlgorithm, setWasPreparingAlorithm] = React.useState(false)
  React.useEffect(() => {
    // This use effect monitor the `wasPreparingAlgorithm` state to ensure the value is true
    // only during the preparation but also for the 5 next seconds the preparation of the algorithm is done
    // This trick is that during this 5 seconds, if the value of `isAlgorithmReady` become true
    // Then we know the connection was succesful.
    if (isPreparingAlgorithm && !isAlgorithmReady) {
      setWasPreparingAlorithm(true)
      return () => {
        setTimeout(() => {
          setWasPreparingAlorithm(false)
        }, 5000)
      }
    }
    return () => { /* noop */ }
  }, [isPreparingAlgorithm, isAlgorithmReady])
  return <BaseMessage
    type='success'
    content={<FormattedMessage id='message.algorithm-connected.content' defaultMessage='Successfully connected to Clemex Studio algorithm engine.' />}
    duration={1}
    isOpen={wasPreparingAlgorithm && isAlgorithmReady}
  />
}
