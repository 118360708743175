import { create } from 'zustand'

export interface PredictionStoreState {
  currentlyPredictingImageId: string | undefined
  maxPredictionRetryCount: number
  predictionRetryCount: Map<string, number> // Zustand requires a Map to be used as a store value
  getPredictionRetryCount: (imageId: string, projectTrainingSnapshotId: string) => number
  isPredictionAllowed: (imageId: string, projectTrainingSnapshotId: string) => boolean
}
export interface PredictionStoreAction {
  incrementPredictionFailCount: (imageId: string, projectTrainingSnapshotId: string) => void
  setCurrentPredictingImageId: (imageId: string | undefined) => void
}

const makePredictionKey = (imageId: string, projectTrainingSnapshotId: string): string => `${imageId}-${projectTrainingSnapshotId}`

export const usePredictionStore = create<PredictionStoreState & PredictionStoreAction>((set, get) => {
  return {
    currentlyPredictingImageId: undefined,
    maxPredictionRetryCount: 3,
    predictionRetryCount: new Map<string, number>(),
    getPredictionRetryCount: (imageId: string, projectTrainingSnapshotId: string): number => {
      const predictionKey = makePredictionKey(imageId, projectTrainingSnapshotId)
      return get().predictionRetryCount.get(predictionKey) ?? 0
    },
    isPredictionAllowed: (imageId: string, projectTrainingSnapshotId: string): boolean => {
      const predictionKey = makePredictionKey(imageId, projectTrainingSnapshotId)
      const predictionRetryCount = get().predictionRetryCount.get(predictionKey) ?? 0
      return predictionRetryCount < get().maxPredictionRetryCount
    },
    incrementPredictionFailCount: (imageId: string, projectTrainingSnapshotId: string) => {
      set((state) => {
        const predictionKey = makePredictionKey(imageId, projectTrainingSnapshotId)
        const predictionRetryCount = state.predictionRetryCount.get(predictionKey) ?? 0
        state.predictionRetryCount.set(predictionKey, predictionRetryCount + 1)
        // See: https://docs.pmnd.rs/zustand/guides/maps-and-sets-usage
        return {
          predictionRetryCount: new Map(state.predictionRetryCount),
        }
      })
    },
    setCurrentPredictingImageId: (imageId: string | undefined) => {
      set(() => ({
        currentlyPredictingImageId: imageId,
      }))
    },
  }
})
