import { Typography, Flex, Button, Slider } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './styles/editor-settings.module.scss'
import { useUserSettingsCanvas } from '@app/api/hooks'
import { useCallback, useEffect, useState } from 'react'

const { Title } = Typography

export const EditorSettings: React.FC = () => {
  const { data: userSettingsCanvas, isLoading, updateUserSettingsCanvas, resetUserSettingsCanvas } = useUserSettingsCanvas()
  const [localZoomFactor, setLocalZoomFactor] = useState<number>(1.2)
  const onChangeZoomFactor = useCallback((zoomFactor: number | null): void => {
    if (zoomFactor === null) {
      return
    }
    void updateUserSettingsCanvas({ zoomFactor })
  }, [updateUserSettingsCanvas])

  useEffect(() => {
    if (userSettingsCanvas?.zoomFactor === undefined) {
      return
    }
    setLocalZoomFactor(userSettingsCanvas.zoomFactor)
  }, [userSettingsCanvas])

  if (isLoading || userSettingsCanvas === undefined) {
    return <FormattedMessage id="global-settings.modal.tab.editor.loading" defaultMessage={'Loading...'} />
  }

  return <Flex vertical flex={1} className={styles.root}>
    <Flex vertical flex={1}>
      <Title level={4} className={styles.settingsSectionTitle}>
        <FormattedMessage id="global-settings.modal.section.editor.section.canvas" defaultMessage={'Canvas'} />
      </Title>
      <Flex vertical gap={16} flex={1} className={styles.settingsSectionContent}>
        <Flex justify='space-between' align='center'>
          <Flex gap={16} vertical className={styles.zoomFactorSliderContainer}>
            <Title level={5} className={styles.settingsItemTitle}>
              <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor" defaultMessage={'Zoom Speed'} />
            </Title>
            <Flex gap={16} align='center'>
              <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor.slider.min" defaultMessage={'Slow'} />
              <div className={styles.zoomFactorSlider}>
                <Slider
                  min={1.01}
                  max={1.5}
                  step={0.01}
                  value={localZoomFactor}
                  onChange={setLocalZoomFactor}
                  onAfterChange={onChangeZoomFactor}
                />
              </div>
              <FormattedMessage id="global-settings.modal.section.editor.canvas.zoom-factor.slider.max" defaultMessage={'Fast'} />
            </Flex>
          </Flex>
        </Flex>
        <Flex justify='flex-end' align='center' className={styles.actionsContainer}>
          <Button type='primary' onClick={resetUserSettingsCanvas}>
            <FormattedMessage id="global-settings.modal.section.editor.canvas.reset" defaultMessage={'Reset to default canvas settings'} />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
}
