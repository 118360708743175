import type * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { useIntl } from 'react-intl'
import styles from './styles/reset-password.module.scss'
import { ResetPasswordForm, type ResetPasswordFormResult } from '@components/authentication/reset-password-form'
import { useBaseNotificationStore } from '@app/hooks/notifications'

interface ResetPasswordPageProps {
  resetPasswordToken: string
}
export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ resetPasswordToken }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useBaseNotificationStore()

  const handlePasswordResetSubmit = async (formData: ResetPasswordFormResult): Promise<void> => {
    try {
      const response = await RFAPI.createOrResetPassword(formData.token, formData.password)
      if (response.message !== 'Password was reset.') {
        throw new Error(intl.formatMessage({
          id: 'login.notification.passwordReset.invalid',
          defaultMessage: 'Invalid request',
        }))
      }
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'login.notification.passwordReset.success.title',
          defaultMessage: 'Password Reset is a Success!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.passwordReset.success.body',
          defaultMessage: 'Please login to continue.',
        }),
      })
      navigate(WebUIRoutes.authentication().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'login.notification.resetPassword.error.title',
          defaultMessage: 'Reset Password Failed!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.resetPassword.error.body',
          defaultMessage: 'Invalid email token. Please request reset password again. If it persists please contact Clemex support (support@clemex.com).',
        }),
      })
      Sentry.captureException(error)
    }
  }

  return <RFPage background backgroundOnTopFooter>
    <AuthenticationHeader >
      <WelcomeTitle />
    </AuthenticationHeader >
    <RFContent className={styles.resetPassword}>
      <div className={styles.formsContainer}>
        <ResetPasswordForm
          onSubmit={handlePasswordResetSubmit}
          resetPasswordToken={resetPasswordToken}
        />
      </div>
    </RFContent>
  </RFPage>
}
