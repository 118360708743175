import * as React from 'react'
import styles from './styles/annotation-parameters.module.scss'
import { Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import { SliderOpacity } from '@components/common/slider-opacity'
import { DEFAULT_ANNOTATION_OPACITY } from '@app/constants'
import { useGlobalAnnotationFullOpacity, useGlobalAnnotationOpacity, useGlobalAnnotationVisibility } from '@app/hooks/editor'

const { Title } = Typography

interface AnnotationParametersProps {
  globalAnnotationOpacity: number
  onChangeGlobalAnnotationOpacity: (opacity: number) => void
  onToggleMinVisibility: () => void
  onToggleMaxVisibility: () => void
}
export const AnnotationParameters: React.FC<AnnotationParametersProps> = (props) => {
  const { globalAnnotationOpacity, onChangeGlobalAnnotationOpacity, onToggleMinVisibility, onToggleMaxVisibility } = props
  return (
    <div className={styles.annotationParameters}>
      <Title level={5} className={styles.title}>
        <FormattedMessage id="class-editor.opacity.slider.label" defaultMessage={'Annotation opacity'} />
      </Title>
      <div className={styles.content}>
        <SliderOpacity
          min={0}
          max={100}
          value={globalAnnotationOpacity * 100}
          onChange={(value) => { onChangeGlobalAnnotationOpacity(value / 100) }}
          onClickMin={onToggleMinVisibility}
          onClickMax={onToggleMaxVisibility}
        />
      </div>
    </div>
  )
}

export const AnnotationParametersGlobalOpacity: React.FC = () => {
  const [globalAnnotationOpacity, setGlobalAnnotationOpacity] = useGlobalAnnotationOpacity()
  const [globalAnnotationVisibility, setGlobalAnnotationVisibility] = useGlobalAnnotationVisibility()
  const [globalAnnotationFullOpacity, setGlobalAnnotationFullOpacity] = useGlobalAnnotationFullOpacity()

  React.useEffect(() => {
    setGlobalAnnotationOpacity(DEFAULT_ANNOTATION_OPACITY)
  }, [setGlobalAnnotationOpacity])

  return <AnnotationParameters
    globalAnnotationOpacity={globalAnnotationOpacity}
    onChangeGlobalAnnotationOpacity={setGlobalAnnotationOpacity}
    onToggleMinVisibility={() => { setGlobalAnnotationVisibility(!globalAnnotationVisibility) }}
    onToggleMaxVisibility={() => { setGlobalAnnotationFullOpacity(!globalAnnotationFullOpacity) }}
  />
}
