/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandleGeneratePluginBundleRequest
 */
export interface HandleGeneratePluginBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof HandleGeneratePluginBundleRequest
     */
    piBundleId: string;
}

/**
 * Check if a given object implements the HandleGeneratePluginBundleRequest interface.
 */
export function instanceOfHandleGeneratePluginBundleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "piBundleId" in value;

    return isInstance;
}

export function HandleGeneratePluginBundleRequestFromJSON(json: any): HandleGeneratePluginBundleRequest {
    return HandleGeneratePluginBundleRequestFromJSONTyped(json, false);
}

export function HandleGeneratePluginBundleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandleGeneratePluginBundleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'piBundleId': json['pi_bundle_id'],
    };
}

export function HandleGeneratePluginBundleRequestToJSON(value?: HandleGeneratePluginBundleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pi_bundle_id': value.piBundleId,
    };
}

