import * as React from 'react'
import * as RFAPI from '@api/api'
import { UploadFirstImages } from '@components/upload-first-images'
import { type ProjectDatasetContext } from '@app/api/openapi'
import { useDemoImages, useProjectImages } from '@app/api/hooks'
import { EVENTS_ID } from '@app/constants'

interface UploadFirstImagesOfProjectProps {
  projectId?: string
  context?: ProjectDatasetContext
}

export const UploadFirstImagesOfProject: React.FC<UploadFirstImagesOfProjectProps> = ({ projectId, context }) => {
  const [isConfirmingUpload, setIsConfirmingUpload] = React.useState(false)
  const { data: images, mutate: mutateProjectImages } = useProjectImages(projectId, context)
  const { data: demoImagesApi, isLoading } = useDemoImages()

  const demoImages = React.useMemo(() => {
    if (demoImagesApi === undefined) {
      return []
    }
    return demoImagesApi.map((demoImage) => ({
      imageDemoId: demoImage.id,
      src: RFAPI.createImageThumbnailURL(demoImage.id, 284, 200),
    }))
  }, [demoImagesApi])

  const onConfirm = React.useCallback(async (newImageIds: string[], demoImageIds: string[]): Promise<void> => {
    if (projectId === undefined || context === undefined) {
      throw new Error('projectId or context is undefined')
    }
    setIsConfirmingUpload(true)
    try {
      await Promise.all(newImageIds.map(async (newImageId) => { await RFAPI.sendImageToDataset(projectId, newImageId, context) }))
      await Promise.all(demoImageIds.map(async (demoImageId) => { await RFAPI.postUseImageDemo(projectId, demoImageId, context) }))
      await mutateProjectImages()
      window.dispatchEvent(new CustomEvent(EVENTS_ID.EDITOR_UPLOADED_IMG))
    } finally {
      setIsConfirmingUpload(false)
    }
  }, [context, mutateProjectImages, projectId])

  if ((images !== undefined && images.length > 0) || projectId === undefined) {
    return null
  }

  return <UploadFirstImages
    demoImages={demoImages}
    uploadUrl={`/api/projects/${projectId}/image/upload`}
    onConfirm={onConfirm}
    isLoadingDemoImages={isLoading}
    isConfirmingUpload={isConfirmingUpload}
  />
}
