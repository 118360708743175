import type * as React from 'react'
import styles from './styles/training-options.module.scss'
import { Typography } from 'antd'
import { FormattedMessage, FormattedRelativeTime, IntlProvider } from 'react-intl'
import { useProjectTrainingSnapshot } from '@app/api/hooks'

const { Title } = Typography

interface TrainingElapsedTimeProps {
  projectId: string
  isTraining: boolean
  isTrainingFailed: boolean
}
export const TrainingElapsedTime: React.FC<TrainingElapsedTimeProps> = ({ projectId, isTraining, isTrainingFailed }) => {
  const { data: projectTrainingSnapshot } = useProjectTrainingSnapshot(projectId)

  const computeElapsedTimeInSeconds = (): number | null => {
    if (projectTrainingSnapshot === undefined) {
      return null
    }
    const currentDate = new Date()
    const diffInMilliseconds = currentDate.getTime() - new Date(projectTrainingSnapshot.creationDate).getTime()
    return Math.floor(diffInMilliseconds / 1000)
  }
  const elapsedTime = computeElapsedTimeInSeconds()

  return (
    <Title level={5} className={styles.title}>
      <FormattedMessage id="training-elapsed-time.last-training.label" defaultMessage={'Last Training: '} />&nbsp;
      {isTrainingFailed && <FormattedMessage id="training-elapsed-time.training-failed.value" defaultMessage={'Training Failed'} />}
      {isTraining && <FormattedMessage id="training-elapsed-time.training-in-progress.value" defaultMessage={'In progress'} />}
      {(!isTraining && !isTrainingFailed && elapsedTime === null) && <FormattedMessage id="training-elapsed-time.training-snapshot-unavailable.value" defaultMessage={'Not available'} />}
      {(!isTraining && !isTrainingFailed && elapsedTime !== null) && (
        <IntlProvider locale="en">
          <FormattedRelativeTime value={-elapsedTime} numeric="auto" updateIntervalInSeconds={30} />
        </IntlProvider>
      )}
    </Title>
  )
}
