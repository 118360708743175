import * as React from 'react'
import { useOnboardingTasks } from '@app/api/hooks'
import { Text, TutorialTour, type TutorialTourStepsProps } from '@components/tutorial/tutorial-tour'
import { OnboardingTaskType } from '@app/api/openapi'
import { FormattedMessage } from 'react-intl'
import { type ONBOARDING_TOUR_CONTEXT } from '@app/constants'

interface AnnotationEditorTourProps {
  onboardingTourContext: ONBOARDING_TOUR_CONTEXT
}

export const AnnotationEditorTour: React.FC<AnnotationEditorTourProps> = ({ onboardingTourContext }) => {
  const { data: onboardingTasks } = useOnboardingTasks(onboardingTourContext)

  const annotationSteps: TutorialTourStepsProps[] = React.useMemo(() => {
    if (onboardingTasks === undefined) {
      return []
    }

    return [
      {
        keyIsCompleted: 'annotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadAnnotatePageTutorialAnnotateAndTrain,
        step: {
          title: <FormattedMessage
            id={'tutorial-tour.annotation-page.annotate-and-train.title'}
            defaultMessage={'Welcome to Project Editor'}
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.annotate-and-train.content.1' defaultMessage='In this page, you tell Studio what you expect it to find on your images.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.annotate-and-train.content.2' defaultMessage='Using simple painting tools fill in the areas of the image draw over objects or structure you are looking for in an image.' />
            </Text>
          </>,
        },
      },
      {
        keyIsCompleted: 'annotationPageUserHasReadUploadTrainingImage',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadUploadTrainingImage,
        step: {

          title: <FormattedMessage
            id={'tutorial-tour.annotation-page.upload-image.title'}
            defaultMessage={'Upload your images'}
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.upload-image.content-1' defaultMessage='Upload a few images that are representative of your sample: As few as 1 to 10 images will give adequate results.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.upload-image.content-2' defaultMessage='To do this, drag and drop an image in the left section.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.upload-image.alternative.content' defaultMessage='Or, click on the + icon above' />
            </Text>
          </>,
          target: () => document.getElementById('upload-image-section') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'annotationPageUserHasReadClassSection',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadClassSection,
        step: {
          title: <FormattedMessage
            id={'tutorial-tour.annotation-page.classes.title'}
            defaultMessage={'Classes'}
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.classes.content-1' defaultMessage="Classes are the types of object you want to detect. Let's say you wanted Studio to detect cats and dogs in images. Then you would create two classes: a cat and a dog class. In metallurgy, with a delta-ferrite micrograph, you would create an Austenite and a Ferrite class." />
            </Text>
            <Text>
              <FormattedMessage id='tutorial-tour.annotation-page.classes.content-2' defaultMessage='Some features available:' />
            </Text>
            <ul>
              <li>
                <FormattedMessage
                  id='tutorial-tour.annotation-page.classes.actions-1'
                  defaultMessage='Click the + sign to add classes'
                />
              </li>
              <li>
                <FormattedMessage
                  id='tutorial-tour.annotation-page.classes.actions-2'
                  defaultMessage='Double click on the class name to edit its name'
                />
              </li>
              <li>
                <FormattedMessage
                  id='tutorial-tour.annotation-page.classes.actions-3'
                  defaultMessage='Click on the class name to activate it to use with tools'
                />
              </li>
              <li>
                <FormattedMessage
                  id='tutorial-tour.annotation-page.classes.actions-4'
                  defaultMessage='Show or Hide annotations classes'
                />
              </li>
              <li>
                <FormattedMessage
                  id='tutorial-tour.annotation-page.classes.actions-5'
                  defaultMessage='Show or Hide predictions'
                />
              </li>
            </ul>
          </>,
          target: () => document.getElementById('class-editor') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'annotationPageUserHasReadAddAnnotation',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadAddAnnotation,
        step: {
          title: <FormattedMessage
            id='tutorial.annotation-page.annotate-and-tools.title'
            defaultMessage='Tools'
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.annotate-and-tools.content-1' defaultMessage='Annotations tell Studio where you expect to find the objects or structure you are looking for in an image.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.annotate-and-tools.content-2' defaultMessage='To annotate, the brush stroke size and paint the object. Only a few strokes at a time are necessary.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.annotate-and-tools.content-3' defaultMessage='By letting the cursor over an icon will display tool tips.' />
            </Text>
          </>,
          target: () => document.getElementById('tools-editor') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'annotationPageUserHasReadTrain',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadTrain,
        step: {
          title: <FormattedMessage
            id='tutorial.annotation-page.train.title'
            defaultMessage='Train'
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.train.content-1' defaultMessage='Hit the Train button to proceed with the automatic prediction on the whole series of imported images. In typical use cases this process takes approximately 8 to 30 seconds.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.train.content-2' defaultMessage='Repeat annotations and train until you are satisfied with how Studio predicts what you are looking for.' />
            </Text>
          </>,
          target: () => document.getElementById('train-button') as unknown as HTMLElement,
        },
      },
      {
        keyIsCompleted: 'annotationPageUserHasReadAccessValidate',
        onboardingTaskCompletion: OnboardingTaskType.PublicAnnotationPageUserHasReadAccessValidate,
        step: {
          title: <FormattedMessage
            id='tutorial.annotation-page.next.title'
            defaultMessage='Validation page'
          />,
          description: <>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.next.content-1' defaultMessage='Validate that Studio understood well your segmentation or detection expectations.' />
            </Text>
            <Text>
              <FormattedMessage id='tutorial.annotation-page.next.content-2' defaultMessage='Click on the "Validate" button above to go to the validation page.' />
            </Text>
          </>,
          target: () => document.getElementById('validate-page-access') as unknown as HTMLElement,
        },
      },
    ]
  }, [onboardingTasks])

  return <TutorialTour
    steps={annotationSteps}
    onboardingTourContext={onboardingTourContext}
  />
}
