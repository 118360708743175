/* eslint-disable */
// @ts-nocheck
/**
 * clemex_studio_server_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CircleGeometryProperties } from './CircleGeometryProperties';
import {
    instanceOfCircleGeometryProperties,
    CircleGeometryPropertiesFromJSON,
    CircleGeometryPropertiesFromJSONTyped,
    CircleGeometryPropertiesToJSON,
} from './CircleGeometryProperties';
import type { EllipseGeometryProperties } from './EllipseGeometryProperties';
import {
    instanceOfEllipseGeometryProperties,
    EllipseGeometryPropertiesFromJSON,
    EllipseGeometryPropertiesFromJSONTyped,
    EllipseGeometryPropertiesToJSON,
} from './EllipseGeometryProperties';
import type { UndefinedGeometryProperties } from './UndefinedGeometryProperties';
import {
    instanceOfUndefinedGeometryProperties,
    UndefinedGeometryPropertiesFromJSON,
    UndefinedGeometryPropertiesFromJSONTyped,
    UndefinedGeometryPropertiesToJSON,
} from './UndefinedGeometryProperties';

/**
 * @type GeometryProperties
 * 
 * @export
 */
export type GeometryProperties = { geometryType: 'CIRCLE' } & CircleGeometryProperties | { geometryType: 'ELLIPSE' } & EllipseGeometryProperties | { geometryType: 'UNDEFINED' } & UndefinedGeometryProperties;

export function GeometryPropertiesFromJSON(json: any): GeometryProperties {
    return GeometryPropertiesFromJSONTyped(json, false);
}

export function GeometryPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeometryProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['geometry_type']) {
        case 'CIRCLE':
            return {...CircleGeometryPropertiesFromJSONTyped(json, true), geometryType: 'CIRCLE'};
        case 'ELLIPSE':
            return {...EllipseGeometryPropertiesFromJSONTyped(json, true), geometryType: 'ELLIPSE'};
        case 'UNDEFINED':
            return {...UndefinedGeometryPropertiesFromJSONTyped(json, true), geometryType: 'UNDEFINED'};
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${json['geometryType']}'`);
    }
}

export function GeometryPropertiesToJSON(value?: GeometryProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['geometryType']) {
        case 'CIRCLE':
            return CircleGeometryPropertiesToJSON(value);
        case 'ELLIPSE':
            return EllipseGeometryPropertiesToJSON(value);
        case 'UNDEFINED':
            return UndefinedGeometryPropertiesToJSON(value);
        default:
            throw new Error(`No variant of GeometryProperties exists with 'geometryType=${value['geometryType']}'`);
    }

}

