import type * as React from 'react'
import { AuthenticationHeader, RFContent, RFPage } from '@components/layout'
import * as RFAPI from '@api/api'
import { useNavigate } from 'react-router'
import { WebUIRoutes } from '@app/routes'
import * as Sentry from '@sentry/react'
import { WelcomeTitle } from '@components/authentication/welcome-title'
import { useIntl } from 'react-intl'
import styles from './styles/request-reset-password.module.scss'
import { RequestResetPasswordForm, type RequestResetPasswordFormResult } from '@components/authentication/request-reset-password-form'
import { useBaseNotificationStore } from '@app/hooks/notifications'

export const RequestResetPasswordPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useBaseNotificationStore()

  const handleRequestPasswordResetSubmit = async (formData: RequestResetPasswordFormResult): Promise<void> => {
    try {
      const response = await RFAPI.requestResetPassword(formData.email)
      if (response.message !== 'Reset password worked.') {
        throw new Error(intl.formatMessage({
          id: 'login.notification.requestResetPassword.invalid',
          defaultMessage: 'Invalid request reset password',
        }))
      }
      addNotification({
        type: 'success',
        message: intl.formatMessage({
          id: 'login.notification.requestResetPassword.success.title',
          defaultMessage: 'Request Password Reset is a Success!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.requestResetPassword.success.body',
          defaultMessage: 'Go check your Email ({email}) for the password reset link.',
        }, {
          email: formData.email,
        }),
      })
      // Navigate to base path as the reset password page needs the token
      navigate(WebUIRoutes.basePath().path)
    } catch (error) {
      addNotification({
        type: 'error',
        message: intl.formatMessage({
          id: 'login.notification.requestResetPassword.error.title',
          defaultMessage: 'Invalid password reset request!',
        }),
        description: intl.formatMessage({
          id: 'login.notification.requestResetPassword.error.body',
          defaultMessage: 'Please try again soon.',
        }),
      })
      Sentry.captureException(error)
    }
  }

  return <RFPage background backgroundOnTopFooter>
    <AuthenticationHeader>
      <WelcomeTitle />
    </AuthenticationHeader>
    <RFContent className={styles.resetPassword}>
      <div className={styles.formsContainer}>
        <RequestResetPasswordForm onSubmit={handleRequestPasswordResetSubmit} />
      </div>
    </RFContent>
  </RFPage>
}
